import { Container, Box } from "@material-ui/core";
import React from "react";
import { TopNav } from "../small components/top nav/TopNav";

const Widget = () => {
  return (
    <div>
      <Container maxWidth="sm">
        <Box pb="40px" width="100%" mt={10}>
          <TopNav swap={"widget"} />
        </Box>

        <Box
          sx={{
            width: "100%",
            borderRadius: "10px",
            // boxShadow: 'rgba(99, 99, 99, 0.4) 0px 1px 10px 0px !important',
            // backgroundColor: "#02052c",
            // border: '1px solid #37324d',
            padding: "15px 15px",
            height: "600px",
          }}
        >
          <iframe
            src="https://guardarian.com/calculator/v1?partner_api_token=3b5bfea5-a64f-4cc7-a413-83158520c7cc&default_fiat_currency=USD&default_crypto_currency=bnb"
            width="100%"
            height="100%"
            frameBorder="none"
          ></iframe>
        </Box>
      </Container>
    </div>
  );
};

export default Widget;
