import React, { useState, useEffect, useContext } from "react";
import { Route } from "react-router-dom";
import { FindPools } from "./eth/components/trade/FindPools";
import Footer from "./eth/components/Footer";
import Synthetic from "./eth/components/syntheticYeild/Synthetic";
import Protcol from "./eth/components/BubbleProtcol/Protcol";
import { Pool } from "./eth/components/trade/pool";
import { Swap } from "./eth/components/trade/swap";
import { AddLiquidity } from "./eth/components/trade/add liquidity";
import Bg from "./images/Bg.jpeg";
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
} from "./eth/components/trade/add liquidity/redirects";
import TransectionModal from "./eth/components/models/TransectionSubmittedModal";
import { RedirectOldRemoveLiquidityPathStructure } from "./eth/components/trade/RemoveLiquidity/redirects";
import Widget from "./eth/components/Widget/Widget";
import { RemoveLiquidity } from "./eth/components/trade/RemoveLiquidity/index";
import { Switch } from "react-router-dom";
import { AppContext } from "./eth/utils/Utils";

import NetworkChange from "./networkSwitch";
import { Box, Hidden } from "@material-ui/core";
import Admin from "./eth/components/BubbleProtcol/Admin";
import FarmingTab from "./eth/components/BubbleProtcol/Farming/FarmingTab";

function App({ open, setOpen, setopenConnect }) {
  const { chainId } = useContext(AppContext);
  const [changeNetwork, setChangeNetwork] = useState(false);
  useEffect(() => {
    const tag = document.getElementsByTagName("iframe");
    if (tag?.length > 0) {
      console.log(tag[0].remove());
    }
  }, []);
  useEffect(() => {
    if (chainId !== 1 && chainId !== null) {
      setChangeNetwork(true);
    }
  }, [chainId]);
  return (
    <Box>
      <Box
        component="img"
        src={Bg}
        alt=""
        className="pictureMove"
        sx={{
          width: { xs: "75vw", md: "60vw" },
          top: { xs: "15%", md: "7%" },
          left: { xs: "14%", md: "20%" },
          opacity: 0.7,
        }}
      />
      <TransectionModal
        openTransectionModa={open}
        setopenTransectionModa={setOpen}
      />
      <NetworkChange open={changeNetwork} setOpen={setChangeNetwork} />
      <Switch>
        <Route exact path="/">
          <Swap />
        </Route>
        {/* <Route exact path="/">
          <Synthetic />
        </Route> */}
        {/* <Route exact path="/synthetic">
          <Synthetic />
        </Route>
        <Route exact path="/farming">
          <FarmingTab />
        </Route>
        <Route exact path="/bubble protcol">
          <Protcol />
        </Route> */}
        {/* <Route exact path="/admin">
          <Admin />
        </Route> */}
        <Route exact path="/trade">
          <Swap />
        </Route>
        <Route exact path="/pool">
          <Pool setopenConnect={setopenConnect} />
        </Route>
        <Route
          exact
          path="/add"
          component={<AddLiquidity setopenConnect={setopenConnect} />}
        />
        <Route
          exact
          strict
          path="/remove/:currencyIdA/:currencyIdB"
          component={RemoveLiquidity}
        />
        <Route
          exact
          path="/add/:currencyIdA"
          component={RedirectOldAddLiquidityPathStructure}
        />
        <Route
          exact
          path="/add/:currencyIdA/:currencyIdB"
          component={RedirectDuplicateTokenIds}
        />
        <Route
          exact
          path="/remove/:tokens"
          component={RedirectOldRemoveLiquidityPathStructure}
        />
        <Route exact path="/widget" component={Widget} />
        <Route exact path="/findpools">
          <FindPools />
        </Route>
        {/* <Hidden smDown>
          <Footer />
        </Hidden> */}
      </Switch>
    </Box>
  );
}

export default App;
