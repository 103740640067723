import { currencyEquals } from "moonchan-sdk";
import React, { useEffect, useMemo, useState } from "react";
import TransectionModal from "../../models/TransectionSubmittedModal";
import TransactionErrorContent from "../../trade/add liquidity/TransactionErrorContent";
import SwapModalFooter from "./SwapModalFooter";
import SwapModalHeader from "./SwapModalHeader";

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param tradeA trade A
 * @param tradeB trade B
 */
function tradeMeaningfullyDiffers(tradeA, tradeB) {
  return (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
    !currencyEquals(
      tradeA.outputAmount.currency,
      tradeB.outputAmount.currency
    ) ||
    !tradeA.outputAmount.equalTo(tradeB.outputAmount)
  );
}

export default function ConfirmSwapModal({
  trade,
  originalTrade,
  onAcceptChanges,
  allowedSlippage,
  onConfirm,
  onDismiss,
  recipient,
  swapErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
}) {
  const showAcceptChanges = useMemo(
    () =>
      Boolean(
        trade && originalTrade && tradeMeaningfullyDiffers(trade, originalTrade)
      ),
    [originalTrade, trade]
  );
  const [modalHeader, setmodalHeader] = useState("");
  const [modalBottom, setmodalBottom] = useState("");
  const [errorContent, seterrorContent] = useState("");
  useEffect(() => {
    setmodalHeader(
      trade ? (
        <SwapModalHeader
          trade={trade}
          allowedSlippage={allowedSlippage}
          recipient={recipient}
          showAcceptChanges={showAcceptChanges}
          onAcceptChanges={onAcceptChanges}
          onDismiss={onDismiss}
        />
      ) : null
    );
  }, [allowedSlippage, onAcceptChanges, recipient, showAcceptChanges, trade]);

  useEffect(() => {
    setmodalBottom(
      trade ? (
        <SwapModalFooter
          onConfirm={onConfirm}
          trade={trade}
          disabledConfirm={showAcceptChanges}
          swapErrorMessage={swapErrorMessage}
          allowedSlippage={allowedSlippage}
        />
      ) : null
    );
    if (swapErrorMessage) {
      seterrorContent(
        <TransactionErrorContent
          onDismiss={onDismiss}
          message={swapErrorMessage}
        />
      );
    }
  }, [allowedSlippage, onConfirm, showAcceptChanges, swapErrorMessage, trade]);

  // text to show while loading
  const pendingText = `Swapping ${trade?.inputAmount?.toSignificant(6)} ${
    trade?.inputAmount?.currency?.symbol
  } for ${trade?.outputAmount?.toSignificant(6)} ${
    trade?.outputAmount?.currency?.symbol
  }`;

  return (
    <TransectionModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      modalHeader={modalHeader}
      modalBottom={modalBottom}
      pendingText={pendingText}
      swapErrorMessage={swapErrorMessage}
      errorContent={errorContent}
    />
  );
}
