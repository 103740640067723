import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Dialog,
  Divider,
  Slide,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";

import dialog from "../../../images/Dialog.png";
import ether from "../../../images/ether.png";
import usdt from "../../../images/usdt.png";
import tusd from "../../../images/tusd.png";
import dai from "../../../images/dai.png";
import dollar from "../../../images/dollar.png";
import bnb from "../../../images/bnb.png";
import lock from "../../../images/lock.png";
import bubIcon from "../../../images/bubIcon.png";
import CloseIcon from "@material-ui/icons/Close";
import { BsCalculatorFill } from "react-icons/bs";
import { AppContext } from "../../utils/Utils";
import {
  ToastNotify,
  useStakingContract,
  useTokenContract,
} from "../BubbleProtcol/Connectivity/hooks";
import Loading from "../BubbleProtcol/Connectivity/loading";
import { stakingAddress } from "../BubbleProtcol/Connectivity/Environment";
import { MaxUint256 } from "@ethersproject/constants";
import { formatUnits, parseUnits } from "@ethersproject/units";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = withStyles({
  paper: {
    background: "#F1F5FD",
    borderRadius: "16px",
    height: "435px",
    width: "392px",
  },
})(Dialog);

export default function ListCards() {
  const matches1 = useMediaQuery("(min-width:1250px)");
  const [openDialog, setOpenDialog] = useState(false);

  const [openDialog1, setOpenDialog1] = useState(false);
  const [stakingPoolArray, setstakingPoolArray] = useState([]);
  const [stakeDetails, setstakeDetails] = useState([]);
  const [tokenBalance, settokenBalance] = useState(0);
  const [depositAmount, setdepositAmount] = useState("");
  const [currentPoolIndex, setcurrentPoolIndex] = useState(0);
  const [loading, setloading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  let currentTime = moment().format("X");
  console.log(currentTime, "currentTime");
  const { account, signer } = useContext(AppContext);
  const stakingContract = useStakingContract(signer);
  const tokenContract = useTokenContract(signer);

  const initData = async () => {
    try {
      if (account) {
        const balance = await tokenContract.balanceOf(account);
        settokenBalance(formatUnits(balance.toString(), 9));
      }

      const ind = await stakingContract.currentPoolIndex();
      setcurrentPoolIndex(+ind);
      let poolArray = [];
      let userArray = [];
      let userStaked = 0;
      let userReward = 0;
      let userPoolIndex = 0;
      let userstakedEndTime = 0;
      let userIsWithdrawn = false;
      for (let index = 1; index <= +ind; index++) {
        if (account) {
          const { _stakeCount } = await stakingContract.getUserInfo(account);
          if (+_stakeCount > 0) {
            for (let i = 1; i <= +_stakeCount; i++) {
              const {
                _amount,
                _reward,
                _poolIndex,
                _startTime,
                _endTime,
                _isWithdrawn,
              } = await stakingContract.getUserStakeInfo(account, i.toString());
              console.log(
                +_amount,
                +_reward,
                +_poolIndex,
                +_startTime,
                +_endTime,
                +_isWithdrawn,
                "USER_DATA"
              );
              userStaked += +formatUnits(_amount.toString(), 9);
              userReward += +formatUnits(_reward.toString(), 9);
              userPoolIndex = _poolIndex;
              userstakedEndTime = _endTime;
              userIsWithdrawn = _isWithdrawn;
            }
          }
        }

        const {
          _rewardPercent,
          _startTime,
          _depositDuration,
          _withdrawDuration,
          _earlyWithdrwTax,
          _totalStaked,
          _totalWithdrawn,
        } = await stakingContract.getPoolInfo(index);
        let obj = {
          _rewardPercent: _rewardPercent,
          _startTime: +_startTime,
          _depositDuration: +_depositDuration,
          _withdrawDuration: +_withdrawDuration,
          _earlyWithdrwTax: +_earlyWithdrwTax,
          _totalStaked: +_totalStaked,
          _totalWithdrawn: +_totalWithdrawn,
          _currevtPool: +index,
          userStaked,
          userReward,
          userPoolIndex,
          userstakedEndTime,
          userIsWithdrawn,
        };
        poolArray.push(obj);
      }
      setstakingPoolArray([...poolArray]);
    } catch (error) {
      console.log(error, "Error__DATA");
    }
  };
  useEffect(() => {
    initData();
  }, [stakingContract, account]);

  const depositHandler = async (_currevtPool) => {
    if (account) {
      if (!depositAmount) {
        setAlertState({
          open: true,
          message: "Error! Please enter amount",
          severity: "error",
        });
      } else if (isNaN(+depositAmount) || +depositAmount < 0) {
        setAlertState({
          open: true,
          message: "Error! Please enter a valid amount",
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          const allowance = await tokenContract.allowance(
            account,
            stakingAddress
          );
          let allow = +formatUnits(allowance.toString(), 9);
          if (+allow < +depositAmount) {
            const approve = await tokenContract.approve(
              stakingAddress,
              MaxUint256.toString()
            );
            await approve.wait();
          }

          const tx = await stakingContract.stake(
            parseUnits(depositAmount.toString(), 9).toString(),
            _currevtPool.toString()
          );
          await tx.wait();
          setAlertState({
            open: true,
            message: `Staked Successfully`,
            severity: "success",
          });
          initData();
          setloading(false);
        } catch (error) {
          setloading(false);
          if (error?.data?.message) {
            setAlertState({
              open: true,
              message: error?.data?.message,
              severity: "error",
            });
          } else if (error?.reason) {
            setAlertState({
              open: true,
              message: error?.reason,
              severity: "error",
            });
          } else {
            setAlertState({
              open: true,
              message: error?.message,
              severity: "error",
            });
          }
        }
      }
    } else {
      setAlertState({
        open: true,
        message: "Error! Please connect your wallet.",
        severity: "error",
      });
    }
  };
  const withdrawHandler = async (index) => {
    try {
      setloading(true);
      const tx = await stakingContract.withdraw(index.toString());
      await tx.wait();
      setAlertState({
        open: true,
        message: `Withdrawn Successfully`,
        severity: "success",
      });
      initData();
      setloading(false);
    } catch (error) {
      setloading(false);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.data?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
    }
  };
  const unstakeHandler = async (index) => {
    try {
      setloading(true);
      const tx = await stakingContract.unstake(index.toString());
      await tx.wait();
      setAlertState({
        open: true,
        message: `Unstaked Successfully`,
        severity: "success",
      });
      initData();
      setloading(false);
    } catch (error) {
      setloading(false);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.data?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
    }
  };

  const ListData = [
    {
      cryptoIcon: ether,
      headCoin: "BUB/ETH",
    },
    {
      cryptoIcon: usdt,
      headCoin: "BUB/USDT",
    },
    {
      cryptoIcon: dollar,
      headCoin: "BUB/USDC",
    },
    {
      cryptoIcon: dai,
      headCoin: "BUB/DAI",
    },
    {
      cryptoIcon: bnb,
      headCoin: "BUB/BUSD",
    },
    {
      cryptoIcon: tusd,
      headCoin: "BUB/TUSD",
    },
  ];
  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <StyledDialog
        // fullWidth
        open={openDialog}
        TransitionComponent={Transition}
      >
        <Box
          style={{
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            style={{
              background: `url(${dialog})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontFamily: "sandro",
                fontWeight: "Bold",
                color: "#fff",
                textTransform: "uppercase",
              }}
            >
              ROI Calculator
            </Typography>
            <CloseIcon
              style={{
                cursor: "pointer",
                color: "#C3C9DA ",
              }}
              onClick={() => {
                setOpenDialog(!openDialog);
              }}
            />
          </Box>
          <Box mx={2} mt={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                style={{
                  color: "#677E96",
                  fontFamily: "Macpaw",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                Days timeframe
              </Typography>
              <Typography
                style={{
                  color: "#677E96",
                  fontFamily: "Macpaw",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                ROI
              </Typography>
              <Typography
                style={{
                  color: "#677E96",
                  fontFamily: "Macpaw",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                $BUB per $1000
              </Typography>
            </Box>
            <Divider
              style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }}
            />
            <Box display="flex" justifyContent="space-between" mt={1.5}>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                1
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                0.14%
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                46.78
              </Typography>
            </Box>
            <Divider
              style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }}
            />
            <Box display="flex" justifyContent="space-between" mt={1.5}>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                7
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                1.02%
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                328.89
              </Typography>
            </Box>
            <Divider
              style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }}
            />
            <Box display="flex" justifyContent="space-between" mt={1.5}>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                30
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                4.44%
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                1433.31
              </Typography>
            </Box>
            <Divider
              style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }}
            />
            <Box display="flex" justifyContent="space-between" mt={1.5}>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                365 (APY)
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                69.62%
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                22481.57
              </Typography>
            </Box>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "12px",
                fontWeight: "400",
                marginTop: "20px",
              }}
            >
              Calculated based on current rates. Compounding 1x daily. Rates are
              estimates provided for your convenience only, and by no means
              represent guaranteed returns.
            </Typography>
            <Box
              mt={2}
              style={{
                color: "#ff3e85",
                border: "2px solid #ff3e85",
                height: "40px",
                width: "100%",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "sandro",
                fontSize: "12px",
                fontWeight: "Bold",
              }}
            >
              Get BUB-ETH LP
            </Box>
          </Box>
        </Box>
      </StyledDialog>

      <Box pb={10}>
        <Box display="flex" pl={2} mb={1}>
          <Typography
            style={{
              fontFamily: "Macpaw",
              fontWeight: "400",
              fontSize: "12px",
              color: "#C3C9DA",
              marginRight: matches1 ? "300px" : "270px",
            }}
          >
            Yield
          </Typography>
          <Typography
            style={{
              fontFamily: "Macpaw",
              fontWeight: "400",
              fontSize: "12px",
              color: "#C3C9DA",
              marginRight: matches1 ? "100px" : "70px",
            }}
          >
            Earn
          </Typography>
          <Typography
            style={{
              fontFamily: "Macpaw",
              fontWeight: "400",
              fontSize: "12px",
              color: "#C3C9DA",
              marginRight: matches1 ? "100px" : "70px",
            }}
          >
            Leverage
          </Typography>
          <Typography
            style={{
              fontFamily: "Macpaw",
              fontWeight: "400",
              fontSize: "12px",
              color: "#C3C9DA",
              marginRight: matches1 ? "170px" : "130px",
            }}
          >
            sAPY
          </Typography>
          <Typography
            style={{
              fontFamily: "Macpaw",
              fontWeight: "400",
              fontSize: "12px",
              color: "#C3C9DA",
            }}
          >
            Enter Amount
          </Typography>
        </Box>
        {ListData.map(({ cryptoIcon, headCoin }, index) => {
          return (
            <Box
              mb={1}
              display="flex"
              style={{
                width: "100%",
                height: "104px",
                borderRadius: "16px",
                background:
                  "linear-gradient(155.94deg, rgba(44, 112, 213, 0.1) -34.45%, rgba(255, 255, 255, 0) 55.98%), rgba(255, 255, 255, 0.05)",
              }}
            >
              <Box
                px={2}
                pt={2}
                sx={{
                  background:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 2deg at 17.76% -92.91%, #FFFFFF 0deg, #A2B0C1 16.7deg, #F9FBFE 24.27deg, #939FAD 47.25deg, #C1D7DB 60.32deg, #C7E2DE 72deg, #D7F7EF 86.4deg, #DEFFF6 97.2deg, #EAFFF9 115.2deg, #F9FFFD 129.6deg, #FFFFFF 156.38deg, #B7C8DC 187.01deg, #E3ECFC 203.99deg, #FCFDFE 219.78deg, #F2F6FD 230.4deg, #EAF0FC 270deg, #FFFFFF 360deg)",
                  backdropFilter: "blur(13px)",
                  borderTopLeftRadius: "16px",
                  borderBottomLeftRadius: "16px",
                  height: "104px",
                  width: "305px",
                  marginRight: matches1 ? "40px" : "20px",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <Box position="relative">
                      <img
                        src={cryptoIcon}
                        style={{
                          position: "absolute",
                          left: "-22px",
                          top: "-20px",
                        }}
                        width="60px"
                        alt=""
                      />
                      <img src={bubIcon} width="56px" alt="" />
                    </Box>
                    <Box ml={1}>
                      <Typography
                        style={{
                          fontFamily: "sandro",
                          fontSize: "18px",
                          fontWeight: "Bold",
                          color: "#2F3841",
                        }}
                      >
                        {headCoin}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Macpaw",
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#3A5168",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={lock}
                          style={{ marginRight: "10px", marginTop: "-4px" }}
                          width="20px"
                          alt=""
                        />
                        3-12 Months
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className="calHover"
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                    sx={{
                      background: "#fff",
                      width: "40px",
                      height: "40px",
                      padding: "8px",
                      borderRadius: "8px",
                      boxShadow: "0px 7px 21px rgba(0, 0, 0, 0.07)",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#FF3E85",
                      },
                    }}
                  >
                    <BsCalculatorFill />
                  </Box>
                </Box>
              </Box>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "#fff",
                  marginRight: matches1 ? "55px" : "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                BUB + Fees
              </Typography>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "#fff",
                  marginRight: matches1 ? "110px" : "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                2X-5X
              </Typography>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "#fff",
                  marginRight: matches1 ? "110px" : "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                47.06%+2.31%
              </Typography>
              <Box display="flex" alignItems="center" mr={matches1 ? 7 : 4}>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "#fff",
                    marginRight: "10px",
                  }}
                >
                  0 LP
                </Typography>
                <Box
                  style={{
                    width: "101px",
                    height: "40px",
                    background: "rgba(255, 255, 255, 0.05)",
                    boxShadow: "0px 7px 21px rgba(0, 0, 0, 0.07",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "sandro",
                    fontSize: "12px",
                    fontWeight: "Regular",
                    color: "#fff",
                  }}
                >
                  Get LP
                </Box>
              </Box>
              <Box display="flex" alignItems="center" mr={matches1 ? 7 : 4}>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "#C3C9DA",
                    marginRight: "10px",
                  }}
                >
                  0
                </Typography>
                <Box
                  style={{
                    width: "118px",
                    height: "40px",
                    background: "rgba(0, 0, 0, 0.2)",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "sandro",
                    fontSize: "12px",
                    fontWeight: "Regular",
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  Harvest
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box
                  style={{
                    width: "109px",
                    height: "40px",
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
                    boxShadow: " 0px 7px 21px rgba(243, 95, 180, 0.4",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "sandro",
                    fontSize: "12px",
                    fontWeight: "Regular",
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  UNLOCK
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
