import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Tab,
  Typography,
  useMediaQuery,
  Tabs,
  Switch,
  InputBase,
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";

import bub from "../../../../images/bub.png";
import moment from "moment";
import { formatUnits } from "@ethersproject/units";
import UserStakedInfo from "./UserStakedList";
import FarmingGrid from "./FarmingGrid";
import { AppContext } from "../../../utils/Utils";
import { useFarmingContract } from "../Connectivity/hooks";

const AntTabs = withStyles({
  root: {
    height: "46px",
    width: "97px",
    border: "1px solid rgba(255, 255, 255, 0.05)",
    borderRadius: "16px",
  },
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);
const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: "none",
  borderRadius: "7px",
  minWidth: "46px",
  color: "rgba(255, 255, 255, 0.5)",
  "&.Mui-selected": {
    background: "rgba(255, 255, 255, 0.05)",
    color: "#fff",
    // paddingTop: "17px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const FarmTabs = withStyles({
  root: {
    // height: "48px",
    minWidth: "233px",
    padding: "4px",
    border: "1px solid rgba(255, 255, 255, 0.05)",
    borderRadius: "16px",
    background: "rgba(0, 0, 0, 0.11)",
  },
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);
const FarmTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: "none",
  minWidth: "fit-content",
  padding: "9px 24px 7px 24px",
  fontSize: "14px",
  fontFamily: "sandro",
  fontWeight: "Regular",
  borderRadius: "7px",
  color: "#fff",
  textTransform: "uppercase",
  "&.Mui-selected": {
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 2deg at 17.76% -92.91%, #FFFFFF 0deg, #A2B0C1 16.7deg, #F9FBFE 24.27deg, #939FAD 47.25deg, #C1D7DB 60.32deg, #C7E2DE 72deg, #D7F7EF 86.4deg, #DEFFF6 97.2deg, #EAFFF9 115.2deg, #F9FFFD 129.6deg, #FFFFFF 156.38deg, #B7C8DC 187.01deg, #E3ECFC 203.99deg, #FCFDFE 219.78deg, #F2F6FD 230.4deg, #EAF0FC 270deg, #FFFFFF 360deg)",
    color: "#223653",
    borderRadius: "12px",
  },
}));
function TabPanel1(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel1"
      hidden={value != index}
      id={`simple-tabpanel1-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[700],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "rgba(103, 126, 150, 0.17)",
        borderColor: "rgba(103, 126, 150, 0.17)",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: "1px solid rgba(103, 126, 150, 0.17)",
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    position: "relative",
    borderRadius: "5px",
    color: "#FFF",
    fontSize: "14px",
    fontFamily: "Macpaw",
    fontWeight: "400",
    backgroundColor: "transparent",
    padding: "10px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});
export default function FarmingTab() {
  const matches = useMediaQuery("(max-width:960px)");
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [userStakedArray, setuserStakedArray] = useState([]);
  const [checked, setChecked] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };
  const [value1, setValue1] = useState(0);
  const handleChange1 = (event1, newValue1) => {
    setValue1(newValue1);
  };
  let currentTime = moment().format("X");
  const { account, signer } = useContext(AppContext);
  const farmingContract = useFarmingContract(signer);

  const initData = async () => {
    try {
      const { _stakeCount } = await farmingContract.getUserInfo(account);
      console.log(+_stakeCount, "_stakeCount");
      let userStakedArray = [];
      for (let index = 0; index < +_stakeCount; index++) {
        const {
          _planIndex,
          _lpAmount,
          _tokenAmount,
          _startTime,
          _endTime,
          _reward,
          _isWithdrawn,
        } = await farmingContract.getUserStakeInfo(account, +index);

        let obj = {
          _tokenAmount: +formatUnits(_tokenAmount.toString(), 9),
          _currentPool: +_planIndex,
          userStakedLP: +formatUnits(_lpAmount.toString(), 18),
          userReward: +formatUnits(_reward.toString(), 9),
          userstakedStartTime: +_startTime,
          userstakedEndTime: +_endTime,
          userIsWithdrawn: _isWithdrawn,
        };
        userStakedArray.push(obj);
      }
      setuserStakedArray([...userStakedArray]);
    } catch (error) {
      console.log(error, "Error__DATA");
    }
  };
  useEffect(() => {
    if (account) {
      initData();
    }
  }, [farmingContract, account, checked]);
  console.log(checked, "checked");
  return (
    <>
      <Box display="flex" justifyContent="center" mt={2}>
        <Box position="relative">
          <img
            src={bub}
            style={{
              position: "absolute",
              right: "-40px",
              bottom: "-10px",
            }}
            width={matches ? "0px" : "76px"}
            alt=""
          />
          <Typography
            style={{
              textTransform: "uppercase",
              fontFamily: "Sandro",
              fontSize: matches ? "18px" : "56px",
              fontWeight: matches ? "Bold" : "Regular",
            }}
          >
            Farming LP
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="xl">
        <Box
          display="flex"
          flexDirection={matches ? "column" : "row"}
          justifyContent={matches ? "center" : "space-between"}
          mt={7}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            mt={matches ? 1 : 0}
          >
            <FarmTabs value={value1} onChange={handleChange1}>
              <FarmTab label="Pools" />
              <FarmTab label="User Staked" />
            </FarmTabs>
          </Box>
        </Box>

        <TabPanel value={value} index={0}>
          <Box mt={5} pb={5}>
            <TabPanel1 value={value1} index={0}>
              <FarmingGrid />
            </TabPanel1>
            <TabPanel1 value={value1} index={1}>
              <UserStakedInfo userStakedArray={userStakedArray} />
            </TabPanel1>
          </Box>
        </TabPanel>
      </Container>
    </>
  );
}
