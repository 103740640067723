import React from "react";
import { Contract } from "@ethersproject/contracts";
import stakingAbi from "./stakingAbi.json";
import tokenAbi from "./tokenAbi.json";
import farmingAbi from "./farmingAbi.json";
import pairAbi from "./pairAbi.json";
import {
  farmingAddress,
  pairLPAddress,
  stakingAddress,
  tokenAddress,
} from "./Environment";
import { ethers } from "ethers";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
let walletAddress = "0x8ba1f109551bD432803012645Ac136ddd64DBA72";
export const provider = new ethers.providers.JsonRpcProvider(
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
);
export const voidAccount = new ethers.VoidSigner(walletAddress, provider);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, voidAccount);
    }
  }, [address, ABI, signer]);
}

export function useStakingContract(signer) {
  return useContract(stakingAddress, stakingAbi, signer);
}
export function useFarmingContract(signer) {
  return useContract(farmingAddress, farmingAbi, signer);
}
export function useLPPairContract(signer) {
  return useContract(pairLPAddress, pairAbi, signer);
}
export function useTokenContract(signer) {
  return useContract(tokenAddress, tokenAbi, signer);
}

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={3000}
      key={"top" + "center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
        variant="filled"
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}
