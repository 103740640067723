import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, InputBase, Typography } from "@material-ui/core";
import { MaxUint256 } from "@ethersproject/constants";
import bubIcon from "../../../../images/bubIcon.png";
import ether from "../../../../images/ether.png";
import lock from "../../../../images/lock.png";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { styled } from "@material-ui/styles";
import { BsCalculatorFill } from "react-icons/bs";
import { AppContext } from "../../../utils/Utils";
import {
  ToastNotify,
  useFarmingContract,
  useLPPairContract,
} from "../Connectivity/hooks";
import { farmingAddress } from "../Connectivity/Environment";
import Loading from "../Connectivity/loading";
import RoiCalculatorModal from "../../syntheticYeild/RoiCalculatorModal";

const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    position: "relative",
    fontSize: "16px",
    fontWeight: "400",
    color: "#ffffff",
    textAlign: "left",
    marginRight: "-8px",
    fontFamily: "Macpaw",
    backgroundColor: "transparent",
    // border: "1px solid #C3C9DA",
    borderRadius: "16px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});
export default function FarmingGrid() {
  const [depositAmount, setdepositAmount] = useState("");
  const [cardIndexValue, setcardIndexValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [poolRewardPercentage, setpoolRewardPercentage] = useState(0);
  const [userEnterdAmount, setuserEnterdAmount] = useState(0);
  const [poolStakedArrey, setpoolStakedArrey] = useState([]);
  const [loading, setloading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const { account, signer } = useContext(AppContext);
  const farmingContract = useFarmingContract(signer);
  const pairLPContract = useLPPairContract(signer);

  const handleInput = async (e, index) => {
    try {
      setdepositAmount(e.target.value);
      setcardIndexValue(+index);
    } catch (error) {}
  };
  const init = async () => {
    try {
      let ListData = [];
      let lockTime = 0;
      let reward = 0;
      for (let index = 1; index < 5; index++) {
        const { totalStakers, totalStaked, totalDistributedReward } =
          await farmingContract.pools(index);
        lockTime = index * 3;
        reward = index + 1;
        let obj = {
          headCoin: "BUB/ETH",
          lockTime: `${lockTime} Months`,
          reward: `${reward}X`,
          totalStakers: +totalStakers,
          totalStaked: +formatUnits(totalStaked.toString(), 18),
          totalDistributedReward: +formatUnits(
            totalDistributedReward.toString(),
            9
          ),
        };
        ListData.push(obj);
      }
      console.log([...ListData], "...ListData");
      setpoolStakedArrey([...ListData]);
    } catch (error) {}
  };
  useEffect(() => {
    init();
  }, [farmingContract]);
  const depositHandler = async (poolIndex) => {
    console.log(poolIndex, "index");
    if (account) {
      if (!depositAmount) {
        setAlertState({
          open: true,
          message: "Error! Please enter amount",
          severity: "error",
        });
      } else if (isNaN(+depositAmount) || +depositAmount < 0) {
        setAlertState({
          open: true,
          message: "Error! Please enter a valid amount",
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          const allowance = await pairLPContract.allowance(
            account,
            farmingAddress
          );
          let allow = +formatUnits(allowance.toString(), 18);
          if (+allow < +depositAmount) {
            const approve = await pairLPContract.approve(
              farmingAddress,
              MaxUint256.toString()
            );
            await approve.wait();
          }
          // let poolInd = +poolIndex + 1;
          console.log(
            poolIndex,
            "poolInd",
            parseUnits(depositAmount.toString(), 18).toString()
          );
          const tx = await farmingContract.stake(
            parseUnits(depositAmount.toString(), 18).toString(),
            poolIndex
            // {
            //   gasLimit: "22000",
            // }
          );
          await tx.wait();
          setAlertState({
            open: true,
            message: `Staked Successfully`,
            severity: "success",
          });
          //   initData();
          setloading(false);
        } catch (error) {
          setloading(false);
          if (error?.data?.message) {
            setAlertState({
              open: true,
              message: error?.data?.message,
              severity: "error",
            });
          } else if (error?.reason) {
            setAlertState({
              open: true,
              message: error?.reason,
              severity: "error",
            });
          } else {
            setAlertState({
              open: true,
              message: error?.message,
              severity: "error",
            });
          }
        }
      }
    } else {
      setAlertState({
        open: true,
        message: "Error! Please connect your wallet.",
        severity: "error",
      });
    }
  };
  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <RoiCalculatorModal
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        userEnterdAmount={userEnterdAmount}
        poolRewardPercentage={poolRewardPercentage}
      />
      <Grid container spacing={2} justifyContent="center">
        {poolStakedArrey.length > 0
          ? poolStakedArrey.map(
              (
                {
                  headCoin,
                  lockTime,
                  reward,
                  totalStakers,
                  totalStaked,
                  totalDistributedReward,
                },
                index
              ) => {
                return (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Box
                      sx={{
                        background: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "16px",
                        height: "461px",
                      }}
                    >
                      <Box
                        px={2}
                        pt={2}
                        sx={{
                          background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 2deg at 17.76% -92.91%, #FFFFFF 0deg, #A2B0C1 16.7deg, #F9FBFE 24.27deg, #939FAD 47.25deg, #C1D7DB 60.32deg, #C7E2DE 72deg, #D7F7EF 86.4deg, #DEFFF6 97.2deg, #EAFFF9 115.2deg, #F9FFFD 129.6deg, #FFFFFF 156.38deg, #B7C8DC 187.01deg, #E3ECFC 203.99deg, #FCFDFE 219.78deg, #F2F6FD 230.4deg, #EAF0FC 270deg, #FFFFFF 360deg)",
                          backdropFilter: "blur(13px)",
                          borderTopLeftRadius: "16px",
                          borderTopRightRadius: "16px",
                          height: "153px",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" alignItems="center">
                            <Box position="relative">
                              <img
                                src={ether}
                                style={{
                                  position: "absolute",
                                  left: "-22px",
                                  top: "-20px",
                                }}
                                width="60px"
                                alt=""
                              />
                              <img src={bubIcon} width="56px" alt="" />
                            </Box>
                            <Box ml={1}>
                              <Typography
                                style={{
                                  fontFamily: "sandro",
                                  fontSize: "24px",
                                  fontWeight: "Bold",
                                  color: "#2F3841",
                                }}
                              >
                                {headCoin}
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "Macpaw",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                  color: "#3A5168",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={lock}
                                  style={{
                                    marginRight: "5px",
                                    marginTop: "-4px",
                                  }}
                                  width="20px"
                                  alt=""
                                />
                                {lockTime}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            className="calHover"
                            onClick={() => {
                              setOpenDialog(true);
                              setuserEnterdAmount(+depositAmount);
                              setpoolRewardPercentage(1);
                            }}
                            sx={{
                              background: "#ffffff",
                              width: "40px",
                              height: "40px",
                              padding: "8px",
                              borderRadius: "8px",
                              boxShadow: "0px 7px 21px rgba(0, 0, 0, 0.07)",
                              cursor: "pointer",
                              "&:hover": {
                                background: "#FF3E85",
                              },
                            }}
                          >
                            <BsCalculatorFill
                              style={{
                                color: "gray",
                                fontSize: "23px",
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box>
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "#677E96",
                              }}
                            >
                              Earn:
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "17px",
                                fontWeight: "600",
                                color: "#223653",
                              }}
                            >
                              BUB
                            </Typography>
                          </Box>

                          <Box>
                            {" "}
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "#677E96",
                              }}
                            >
                              Reward
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "17px",
                                fontWeight: "600",
                                color: "#223653",
                              }}
                            >
                              {reward}
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Box
                            p={1}
                            mt={5}
                            display="flex"
                            justifyContent="space-between"
                          >
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#C3C9DA",
                              }}
                            >
                              Stakers
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "sandro",
                                fontSize: "14px",
                                fontWeight: "Regular",
                                color: "#C3C9DA",
                              }}
                            >
                              {totalStakers}
                            </Typography>
                          </Box>
                          <Box
                            p={1}
                            display="flex"
                            justifyContent="space-between"
                          >
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#C3C9DA",
                              }}
                            >
                              Total Staked
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "sandro",
                                fontSize: "14px",
                                fontWeight: "Regular",
                                color: "#C3C9DA",
                              }}
                            >
                              {parseFloat(totalStaked).toFixed(3)} LP
                            </Typography>
                          </Box>
                          <Box
                            p={1}
                            display="flex"
                            justifyContent="space-between"
                          >
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#C3C9DA",
                              }}
                            >
                              Total BUB Distributed
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "sandro",
                                fontSize: "14px",
                                fontWeight: "Regular",
                                color: "#C3C9DA",
                              }}
                            >
                              {parseFloat(totalDistributedReward).toFixed(2)}{" "}
                              BUB
                            </Typography>
                          </Box>
                        </Box>

                        <Box p={1} mt={1} width="100%">
                          <Typography
                            style={{
                              fontFamily: "Macpaw",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#C3C9DA",
                              marginBottom: "5px",
                            }}
                          >
                            Enter Amount To Stake
                          </Typography>
                          <TextInput
                            placeholder="0"
                            type="number"
                            value={+cardIndexValue === index && depositAmount}
                            onChange={(e) => handleInput(e, index)}
                          />
                        </Box>
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Box
                            style={{
                              width: "100%",
                              height: "56px",
                              background:
                                "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
                              boxShadow: " 0px 7px 21px rgba(243, 95, 180, 0.4",
                              borderRadius: "16px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontFamily: "sandro",
                              fontSize: "14px",
                              fontWeight: "Regular",
                              color: "#fff",
                              textTransform: "uppercase",
                              cursor: "pointer",
                            }}
                            onClick={() => depositHandler(index)}
                          >
                            Farm
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              }
            )
          : null}
      </Grid>
    </>
  );
}
