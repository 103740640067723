import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  useMediaQuery,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core";

import CustomizedButton from "../small components/CustomButton/CustomButton";
import { AppContext } from "../../utils/Utils";

const StyledModal = withStyles((theme) => ({
  root: {
    "& .MuiDialog-root": {
      zIndex: "1301 !important",
    },
    "&.MuiDialog-container": {
      overflowY: "hidden !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      background: "tranparent !important",
      // height: "100%",
      boxShadow: "0px 0px 13px 0px  #686666",
      borderRadius: "30px !important",
    },
    "& .dialoge__content__section": {
      background: "#F1F5FD 0% 0% no-repeat padding-box",
      borderRadius: 18,
      // height: "100%",
      padding: "0px !important",
    },
  },
}))(Dialog);

function TransectionModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,

  modalBottom,
  modalHeader,
  swapErrorMessage,
  errorContent,
}) {
  const theme = useTheme();
  const { account, signer } = useContext(AppContext);
  const matches = useMediaQuery("(max-width:600px)");

  const handleClose = () => {
    onDismiss();
  };
  const PendingContent = (
    <>
      <Box
        paddingLeft="20px"
        paddingRight="20px"
        paddingTop="25px"
        display="flex"
        alignItems="center"
        style={{
          background: "#A4FF66",
        }}
        // bgcolor={"#ED2196"}
        justifyContent="space-between"
        pb="20px"
      >
        <Box
          color={theme.modalColors.textColor}
          fontSize={matches ? "17px" : "20px"}
          textAlign="left"
          fontWeight="Bold"
          fontFamily="Sandro"
        >
          Processing Transaction
        </Box>
        <Box>
          <i
            onClick={handleClose}
            style={{
              color: "#fff",
              textAlign: "right",
              fontSize: "23px",
              cursor: "pointer",
              paddingTop: "7px",
            }}
            className="fal fa-times"
          ></i>
        </Box>
      </Box>
      <Box mt={3} color={"#ED2196"}>
        <CircularProgress size="70px" color={"#ED2196"} />
      </Box>
      <Box
        mt={3}
        color={theme.palette.common.black}
        fontSize="15px"
        textAlign="center"
        fontWeight="Bold"
        fontFamily="Sandro"
      >
        {pendingText}
      </Box>
    </>
  );
  const CompletedContent = (
    <>
      <Box
        paddingLeft="20px"
        paddingRight="20px"
        paddingTop="25px"
        display="flex"
        alignItems="center"
        style={{
          background: "#A4FF66",
        }}
        justifyContent="space-between"
        pb="20px"
      >
        <Box
          color={theme.palette.secondary.contrastText}
          fontSize={matches ? "17px" : "20px"}
          textAlign="left"
          fontWeight="Bold"
          fontFamily="Sandro"
        >
          Waiting for confirmation
        </Box>
        <Box>
          <i
            onClick={handleClose}
            style={{
              color: theme.palette.secondary.contrastText,
              textAlign: "right",
              fontSize: "23px",
              cursor: "pointer",
              paddingTop: "7px",
            }}
            className="fal fa-times"
          ></i>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Box>
          <i
            style={{
              color: "#91669C",
              textAlign: "center",
              fontSize: "60px",
              fontWeight: "600",
            }}
            className="fal fa-arrow-circle-up"
          ></i>
        </Box>
      </Box>
      <Box my={3}>
        <a
          href={`https://arbiscan.io/tx/${hash}`}
          target="_blank"
          style={{
            cursor: "pointer",
            color: "#91669C",
            fontFamily: "Segoe UI",
            fontSize: "17px",
            fontWeight: "600",
            borderRadius: "10px",
            padding: "5px 10px",
            textDecoration: "none",
          }}
        >
          View On Etherscan
          <i
            style={{
              color: "#91669C",
              textAlign: "right",
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              marginLeft: "10px",
            }}
            className="fal fa-external-link"
          ></i>
        </a>
      </Box>
      <CustomizedButton
        fontFamily="Sandro"
        fontSize="18px"
        fontWeight="600"
        fun={onDismiss}
      >
        Close
      </CustomizedButton>
    </>
  );
  const { chainId } = useContext(AppContext);

  if (!chainId) return null;
  return (
    <>
      <StyledModal
        keepMounted
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="dialoge__content__section">
          <div
            style={{
              textAlign: "center",
              paddingBottom: "30px",
              width: matches ? "100%" : "400px",
            }}
          >
            {attemptingTxn ? (
              [PendingContent]
            ) : hash ? (
              [CompletedContent]
            ) : swapErrorMessage ? (
              <>{errorContent}</>
            ) : (
              <>
                {modalHeader}
                {modalBottom}
              </>
            )}
          </div>
        </DialogContent>
      </StyledModal>
    </>
  );
}
export default TransectionModal;
