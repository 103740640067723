import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  Divider,
  Grid,
  InputBase,
  Slide,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import { MaxUint256 } from "@ethersproject/constants";
import bubIcon from "../../../images/bubIcon.png";
import dialog from "../../../images/Dialog.png";
import ether from "../../../images/ether.png";
import eth1 from "../../../images/eth1.png";
import lock from "../../../images/lock.png";
import CloseIcon from "@material-ui/icons/Close";
import { BsCalculatorFill } from "react-icons/bs";
import { AppContext } from "../../utils/Utils";
import {
  ToastNotify,
  useStakingContract,
  useTokenContract,
} from "../BubbleProtcol/Connectivity/hooks";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { stakingAddress } from "../BubbleProtcol/Connectivity/Environment";
import Loading from "../BubbleProtcol/Connectivity/loading";
import { styled } from "@material-ui/styles";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import RoiCalculatorModal from "./RoiCalculatorModal";

const Transition1 = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DetailDialog = withStyles({
  paper: {
    background: "#F1F5FD",
    borderRadius: "16px",
    height: "210px",
    width: "392px",
  },
})(Dialog);
const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    position: "relative",
    fontSize: "16px",
    fontWeight: "400",
    color: "#ffffff",
    textAlign: "left",
    marginRight: "-8px",
    fontFamily: "Macpaw",
    backgroundColor: "transparent",
    // padding: "10px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});
export default function GridCards({ poolDataArray, initData }) {
  const matches = useMediaQuery("(max-width:960px)");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [depositAmount, setdepositAmount] = useState("");
  const [poolRewardPercentage, setpoolRewardPercentage] = useState(0);
  const [userEnterdAmount, setuserEnterdAmount] = useState(0);

  const [cardIndexValue, setcardIndexValue] = useState(0);
  const [isPersonal, setisPersonal] = useState(true);
  const [loading, setloading] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const { account, signer } = useContext(AppContext);
  const stakingContract = useStakingContract(signer);
  const tokenContract = useTokenContract(signer);

  const handleInput = async (e, index) => {
    try {
      setdepositAmount(e.target.value);
      setcardIndexValue(+index);
    } catch (error) {}
  };

  const depositHandler = async (_currentPool) => {
    if (account) {
      if (!depositAmount) {
        setAlertState({
          open: true,
          message: "Error! Please enter amount",
          severity: "error",
        });
      } else if (isNaN(+depositAmount) || +depositAmount < 0) {
        setAlertState({
          open: true,
          message: "Error! Please enter a valid amount",
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          const allowance = await tokenContract.allowance(
            account,
            stakingAddress
          );
          let allow = +formatUnits(allowance.toString(), 9);
          if (+allow < +depositAmount) {
            const approve = await tokenContract.approve(
              stakingAddress,
              MaxUint256.toString()
            );
            await approve.wait();
          }

          const tx = await stakingContract.stake(
            parseUnits(depositAmount.toString(), 9).toString(),
            _currentPool.toString()
          );
          await tx.wait();
          setAlertState({
            open: true,
            message: `Staked Successfully`,
            severity: "success",
          });
          initData();
          setloading(false);
        } catch (error) {
          setloading(false);
          if (error?.data?.message) {
            setAlertState({
              open: true,
              message: error?.data?.message,
              severity: "error",
            });
          } else if (error?.reason) {
            setAlertState({
              open: true,
              message: error?.reason,
              severity: "error",
            });
          } else {
            setAlertState({
              open: true,
              message: error?.message,
              severity: "error",
            });
          }
        }
      }
    } else {
      setAlertState({
        open: true,
        message: "Error! Please connect your wallet.",
        severity: "error",
      });
    }
  };
  const withdrawHandler = async (index) => {
    try {
      setloading(true);
      const tx = await stakingContract.withdraw(index.toString());
      await tx.wait();
      setAlertState({
        open: true,
        message: `Withdrawn Successfully`,
        severity: "success",
      });
      initData();
      setloading(false);
    } catch (error) {
      setloading(false);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.data?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
    }
  };
  const unstakeHandler = async (index) => {
    try {
      setloading(true);
      const tx = await stakingContract.unstake(index.toString());
      await tx.wait();
      setAlertState({
        open: true,
        message: `Unstaked Successfully`,
        severity: "success",
      });
      initData();
      setloading(false);
    } catch (error) {
      setloading(false);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.data?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
    }
  };
  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <RoiCalculatorModal
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        poolRewardPercentage={poolRewardPercentage}
        userEnterdAmount={userEnterdAmount}
      />
      <DetailDialog open={openDialog1} TransitionComponent={Transition1}>
        <Box
          style={{
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            style={{
              background: `url(${dialog})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontFamily: "sandro",
                fontWeight: "Bold",
                color: "#fff",
                textTransform: "uppercase",
              }}
            >
              Account Detail
            </Typography>
            <CloseIcon
              style={{
                cursor: "pointer",
                color: "#C3C9DA ",
              }}
              onClick={() => {
                setOpenDialog1(!openDialog1);
              }}
            />
          </Box>
          <Box
            mx={2}
            mt={2}
            style={{
              background: "#fff",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "56px",
              borderRadius: "8px",
            }}
          >
            <Box display="flex">
              <Box
                style={{
                  width: "32px",
                  height: "32px",
                  background: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <img src={eth1} width="15px" alt="" />
              </Box>
              <Box ml={1}>
                <Typography
                  style={{
                    fontSize: "12px",
                    fontFamily: "sandro",
                    fontWeight: "Bold",
                    color: "#2F3841",
                    textTransform: "uppercase",
                  }}
                >
                  0X1FC6...4FE2
                </Typography>
                <Typography
                  mt={1}
                  style={{
                    fontSize: matches ? "8px" : "12px",
                    fontFamily: "Macpaw",
                    fontWeight: "400",
                    color: "#677E96",
                  }}
                >
                  Connected with MetaMask
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography
                style={{
                  fontSize: "13px",
                  fontFamily: "Macpaw",
                  fontWeight: "600",
                  color: "#008BFF",
                }}
              >
                Change
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Box
              mt={2}
              style={{
                color: "#ff3e85",
                border: "2px solid #ff3e85",
                height: "40px",
                width: matches ? "45%" : "180px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "sandro",
                fontSize: "12px",
                fontWeight: "Bold",
                textTransform: "uppercase",
              }}
            >
              Copy address
            </Box>
            <Box
              mt={2}
              style={{
                color: "#ff3e85",
                border: "2px solid #ff3e85",
                height: "40px",
                width: matches ? "40%" : "154px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "sandro",
                fontSize: "12px",
                fontWeight: "Bold",
                textTransform: "uppercase",
              }}
            >
              Etherscan
            </Box>
          </Box>
        </Box>
      </DetailDialog>
      <Link to="/admin" style={{ textDecoration: "none" }}>
        <Box
          style={{
            width: "186px",
            height: "56px",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
            boxShadow: " 0px 7px 21px rgba(243, 95, 180, 0.4",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "sandro",
            fontSize: "14px",
            fontWeight: "Regular",
            color: "#fff",
            textTransform: "uppercase",
            cursor: "pointer",
          }}
          mb={2}
        >
          Admin
        </Box>
      </Link>
      <Grid container spacing={5} justifyContent="center" mb={5}>
        {poolDataArray.length > 0
          ? poolDataArray.map(
              (
                {
                  _rewardPercent,
                  _startTime,
                  _depositDuration,
                  _withdrawDuration,
                  _earlyWithdrwTax,
                  _totalStaked,
                  _totalWithdrawn,
                  _currentPool,
                  userStaked,
                  userReward,
                  userPoolIndex,
                  userstakedEndTime,
                  userIsWithdrawn,
                },
                index
              ) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box
                      sx={{
                        background: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "16px",
                        height: "500px",
                      }}
                    >
                      <Box
                        px={2}
                        pt={2}
                        sx={{
                          background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 2deg at 17.76% -92.91%, #FFFFFF 0deg, #A2B0C1 16.7deg, #F9FBFE 24.27deg, #939FAD 47.25deg, #C1D7DB 60.32deg, #C7E2DE 72deg, #D7F7EF 86.4deg, #DEFFF6 97.2deg, #EAFFF9 115.2deg, #F9FFFD 129.6deg, #FFFFFF 156.38deg, #B7C8DC 187.01deg, #E3ECFC 203.99deg, #FCFDFE 219.78deg, #F2F6FD 230.4deg, #EAF0FC 270deg, #FFFFFF 360deg)",
                          backdropFilter: "blur(13px)",
                          borderTopLeftRadius: "16px",
                          borderTopRightRadius: "16px",
                          height: "153px",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" alignItems="center">
                            <Box position="relative">
                              <img
                                src={ether}
                                style={{
                                  position: "absolute",
                                  left: "-22px",
                                  top: "-20px",
                                }}
                                width="60px"
                                alt=""
                              />
                              <img src={bubIcon} width="56px" alt="" />
                            </Box>
                            <Box ml={1}>
                              <Typography
                                style={{
                                  fontFamily: "sandro",
                                  fontSize: "24px",
                                  fontWeight: "Bold",
                                  color: "#2F3841",
                                }}
                              >
                                BUB
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "Macpaw",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                  color: "#3A5168",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={lock}
                                  style={{
                                    marginRight: "5px",
                                    marginTop: "-4px",
                                  }}
                                  width="20px"
                                  alt=""
                                />
                                1 Months
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            className="calHover"
                            onClick={() => {
                              setOpenDialog(true);
                              setpoolRewardPercentage(+_rewardPercent);
                              setuserEnterdAmount(+depositAmount);
                            }}
                            sx={{
                              background: "#ffffff",
                              width: "40px",
                              height: "40px",
                              padding: "8px",
                              borderRadius: "8px",
                              boxShadow: "0px 7px 21px rgba(0, 0, 0, 0.07)",
                              cursor: "pointer",
                              "&:hover": {
                                background: "#FF3E85",
                              },
                            }}
                          >
                            <BsCalculatorFill
                              style={{
                                color: "gray",
                                fontSize: "23px",
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box>
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#677E96",
                              }}
                            >
                              Earn:
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "13px",
                                fontWeight: "600",
                                color: "#223653",
                              }}
                            >
                              BUB
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#677E96",
                              }}
                            >
                              Unstake Tax
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "13px",
                                fontWeight: "600",
                                color: "#223653",
                              }}
                            >
                              {_earlyWithdrwTax}%
                            </Typography>
                          </Box>

                          <Box>
                            {" "}
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#677E96",
                              }}
                            >
                              sAPY
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "13px",
                                fontWeight: "600",
                                color: "#223653",
                              }}
                            >
                              {_rewardPercent * 12}%
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          width="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mt={4}
                        >
                          <Box
                            bgcolor="#C3C9DA"
                            display="flex"
                            alignItems="center"
                            borderRadius="16px"
                            width="100%"
                            mx={2}
                          >
                            <Box
                              style={{
                                background: isPersonal
                                  ? "url(dwdqwdq@4x.png), linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 2deg at 17.76% -92.91%, #FFFFFF 0deg, #A2B0C1 16.7deg, #F9FBFE 24.27deg, #939FAD 47.25deg, #C1D7DB 60.32deg, #C7E2DE 72deg, #D7F7EF 86.4deg, #DEFFF6 97.2deg, #EAFFF9 115.2deg, #F9FFFD 129.6deg, #FFFFFF 156.38deg, #B7C8DC 187.01deg, #E3ECFC 203.99deg, #FCFDFE 219.78deg, #F2F6FD 230.4deg, #EAF0FC 270deg, #FFFFFF 360deg)"
                                  : "transparent",
                                fontFamily: "Sandro",
                                fontSize: "12px",
                                fontWeight: "Bold",
                                cursor: "pointer",
                                textAlign: "center",
                              }}
                              borderRadius="16px"
                              p={1}
                              py={2}
                              width="50%"
                              color="#000000"
                              onClick={() => setisPersonal(true)}
                            >
                              PERSONAL
                            </Box>
                            <Box
                              style={{
                                background: !isPersonal
                                  ? "url(dwdqwdq@4x.png), linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 2deg at 17.76% -92.91%, #FFFFFF 0deg, #A2B0C1 16.7deg, #F9FBFE 24.27deg, #939FAD 47.25deg, #C1D7DB 60.32deg, #C7E2DE 72deg, #D7F7EF 86.4deg, #DEFFF6 97.2deg, #EAFFF9 115.2deg, #F9FFFD 129.6deg, #FFFFFF 156.38deg, #B7C8DC 187.01deg, #E3ECFC 203.99deg, #FCFDFE 219.78deg, #F2F6FD 230.4deg, #EAF0FC 270deg, #FFFFFF 360deg)"
                                  : "transparent",
                                fontFamily: "Sandro",
                                fontSize: "12px",
                                fontWeight: "Bold",
                                cursor: "pointer",
                                textAlign: "center",
                              }}
                              borderRadius="16px"
                              px={3}
                              py={2}
                              width="50%"
                              color="#000000"
                              onClick={() => setisPersonal(false)}
                            >
                              POOL
                            </Box>
                          </Box>
                        </Box>
                        {isPersonal ? (
                          <Box>
                            <Box
                              p={1}
                              mt={2}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Typography
                                style={{
                                  fontFamily: "Macpaw",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#C3C9DA",
                                }}
                              >
                                Your Staked
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "MacPaw",
                                  fontSize: "15px",
                                  fontWeight: "Regular",
                                  color: "#C3C9DA",
                                }}
                              >
                                {parseFloat(userStaked).toFixed(2)} BUB
                              </Typography>
                            </Box>
                            <Box
                              p={1}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Typography
                                style={{
                                  fontFamily: "Macpaw",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#C3C9DA",
                                }}
                              >
                                Your Reward
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "MacPaw",
                                  fontSize: "15px",
                                  fontWeight: "Regular",
                                  color: "#C3C9DA",
                                }}
                              >
                                {parseFloat(userReward).toFixed(2)} BUB
                              </Typography>
                            </Box>

                            <Box
                              p={1}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Typography
                                style={{
                                  fontFamily: "Macpaw",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#C3C9DA",
                                }}
                              >
                                Deposit Until
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "MacPaw",
                                  fontSize: "15px",
                                  fontWeight: "Regular",
                                  color: "#C3C9DA",
                                }}
                              >
                                {moment
                                  .unix(_startTime + _depositDuration)
                                  .format("lll")}
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box
                              p={1}
                              mt={2}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Typography
                                style={{
                                  fontFamily: "Macpaw",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#C3C9DA",
                                }}
                              >
                                Total Staked
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "MacPaw",
                                  fontSize: "15px",
                                  fontWeight: "Regular",
                                  color: "#C3C9DA",
                                }}
                              >
                                {parseFloat(_totalStaked).toFixed(2)} BUB
                              </Typography>
                            </Box>
                            <Box
                              p={1}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Typography
                                style={{
                                  fontFamily: "Macpaw",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#C3C9DA",
                                }}
                              >
                                Total Withdrawn
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "MacPaw",
                                  fontSize: "15px",
                                  fontWeight: "Regular",
                                  color: "#C3C9DA",
                                }}
                              >
                                {parseFloat(_totalWithdrawn).toFixed(2)} BUB
                              </Typography>
                            </Box>

                            <Box
                              p={1}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Typography
                                style={{
                                  fontFamily: "Macpaw",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#C3C9DA",
                                }}
                              >
                                End Time
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "MacPaw",
                                  fontSize: "15px",
                                  fontWeight: "Regular",
                                  color: "#C3C9DA",
                                }}
                              >
                                {moment
                                  .unix(_startTime + _withdrawDuration)
                                  .format("lll")}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        <Divider
                          style={{ background: "rgba(255, 255, 255, 0.05)" }}
                        />
                        <Box
                          p={1}
                          mt={1}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box>
                            <Typography
                              style={{
                                fontFamily: "Macpaw",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#C3C9DA",
                              }}
                            >
                              Enter Amount To Stake
                            </Typography>
                            <TextInput
                              placeholder="0"
                              type="number"
                              value={+cardIndexValue === index && depositAmount}
                              onChange={(e) => handleInput(e, index)}
                            />
                          </Box>
                          <Box
                            style={{
                              width: "118px",
                              height: "40px",
                              background: "rgba(0, 0, 0, 0.2)",
                              borderRadius: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontFamily: "sandro",
                              fontSize: "12px",
                              fontWeight: "Regular",
                              color: "#fff",
                              textTransform: "uppercase",
                              cursor: "pointer",
                              "&:hover": {
                                background:
                                  "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
                              },
                            }}
                            onClick={() => depositHandler(_currentPool)}
                          >
                            Invest
                          </Box>
                        </Box>
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Box
                            mr={2}
                            onClick={() => withdrawHandler(_currentPool)}
                            style={{
                              width: "186px",
                              height: "56px",
                              background: "rgba(255, 255, 255, 0.05)",
                              boxShadow: "0px 7px 21px rgba(0, 0, 0, 0.07)",
                              borderRadius: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontFamily: "sandro",
                              fontSize: "14px",
                              fontWeight: "Regular",
                              color: "#fff",
                              textTransform: "uppercase",
                              cursor: "pointer",
                            }}
                          >
                            Withdraw
                          </Box>
                          <Box
                            style={{
                              width: "186px",
                              height: "56px",
                              background:
                                "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
                              boxShadow: " 0px 7px 21px rgba(243, 95, 180, 0.4",
                              borderRadius: "16px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontFamily: "sandro",
                              fontSize: "14px",
                              fontWeight: "Regular",
                              color: "#fff",
                              textTransform: "uppercase",
                              cursor: "pointer",
                            }}
                            onClick={() => unstakeHandler(_currentPool)}
                          >
                            Unstake
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              }
            )
          : null}
      </Grid>
    </>
  );
}
