import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";

import application from "./application/reducer";
import { updateVersion } from "./global/actions";
import user from "./user/reducer";
import transactions from "./transactions/reducer";
import swap from "./swap/reducer";
import mint from "./mint/reducer";
import lists from "./lists/reducer";
import burn from "./burn/reducer";
import multicall from "./multicall/reducer";
import toasts from "./toasts";
import { getThemeCache } from "../utils/theme";

const PERSISTED_KEYS = ["user", "transactions"];
const loadedState = load({ states: PERSISTED_KEYS });
if (loadedState.user) {
  loadedState.user.userDarkMode = getThemeCache();
}

const store = configureStore({
  reducer: {
    application,
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
    toasts,
  },
  middleware: [
    ...getDefaultMiddleware({ thunk: false }),
    save({ states: PERSISTED_KEYS }),
  ],
  preloadedState: loadedState,
});

store.dispatch(updateVersion());

export default store;
