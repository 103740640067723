import React from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useHistory } from "react-router";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.tooltip,
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    padding: 20,
  },
}))(Tooltip);

export function FindPoolTabs() {
  const theme = useTheme();
  return (
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <a
          style={{ color: theme.palette.common.black }}
          component={Link}
          href="/pool"
        >
          <ArrowBackIosIcon />
        </a>
        <Typography style={{ color: theme.palette.common.black }}>
          Import Pool
        </Typography>
        <LightTooltip title="Use this tool to find pairs that do not automatically appear in the interface.">
          <IconButton aria-label="delete">
            <HelpOutlineIcon style={{ color: theme.palette.common.black }} />
          </IconButton>
        </LightTooltip>
      </Box>
    </Container>
  );
}

export function LiquidityTopNav({ adding }) {
  const theme = useTheme();
  const history = useHistory();
  return (
    <Box position="relative">
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link
          style={{
            color: theme.palette.common.black,
            cursor: "pointer",
            marginLeft: "20px",
          }}
          onClick={() => history.push("/pool")}
        >
          <ArrowBackIosIcon style={{ color: "#ffffff" }} />
        </Link>
        <Typography
          style={{
            color: "#ffffff",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: "Bold",
          }}
        >
          {adding ? "Add   " : "Remove  "}
          Liquidity
        </Typography>
        <Box></Box>
      </Box>
    </Box>
  );
}
