import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import { JSBI, Pair, Percent } from "moonchan-sdk";
import { useTheme } from "@material-ui/core";
import { currencyId } from "../../utils/currencyId";
import React, { useContext, useState } from "react";

import { useTokenBalance } from "../../state/wallet/hooks";
import { ExpandMoreIcon } from "../../utils/images";
import { unwrappedToken } from "../../utils/wrappedCurrency";
import { useTotalSupply } from "../../data/TotalSupply";
import DoubleCurrencyLogo from "../small components/DoubleCurrencyLogo/DoubleCurrencyLogo";
import CurrencyLogo from "../small components/CurrencyLogo/CurrencyLogo";
import { Link } from "react-router-dom";
import { AppContext } from "../../utils/Utils";

function AddRemoveLiquidityAccordian({ pair }) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const matches = useMediaQuery("(max-width:750px)");
  const { account } = useContext(AppContext);

  const currency0 = unwrappedToken(pair.token0);
  const currency1 = unwrappedToken(pair.token1);
  const userPoolBalance = useTokenBalance(
    account ?? undefined,
    pair.liquidityToken
  );
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(
            pair.token0,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
          pair.getLiquidityValue(
            pair.token1,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
        ]
      : [undefined, undefined];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Box width="100%">
        <Container maxWidth="sm">
          <Box width="100%">
            <Box
              width="100%"
              marginTop="20px"
              bgcolor={theme.palette.primary.contrastText}
            >
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: theme.palette.secondary.main,
                  borderRadius: "10px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Box display="flex" alignItems="center">
                    <DoubleCurrencyLogo
                      currency0={currency0}
                      currency1={currency1}
                      size={20}
                    />
                    <Box
                      fontSize={matches ? "14px" : "17px"}
                      color={theme.palette.common.black}
                      fontFamily="Sandro"
                      ml={1}
                    >
                      {!currency0 || !currency1
                        ? `Loading ${(
                            <CircularProgress size={20} color="secondary" />
                          )}`
                        : `${currency0.symbol}/${currency1.symbol}`}
                    </Box>
                  </Box>
                </AccordionSummary>
                <Divider
                  style={{
                    width: "100%",
                    backgroundColor: theme.palette.common.black,
                    height: "1px",
                    marginTop: "-6px",
                  }}
                />
                <AccordionDetails>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    mt={1}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        fontFamily="Sandro"
                        fontSize="12px"
                        fontWeight="Light"
                        color={theme.palette.common.black}
                      >
                        Pooled {currency0.symbol}:
                      </Box>
                      <Box
                        alignSelf="right"
                        display="flex"
                        alignItems="center"
                        fontFamily="Sandro"
                        fontSize="12px"
                        fontWeight="Light"
                        color={theme.palette.common.black}
                      >
                        {token0Deposited
                          ? token0Deposited?.toSignificant(6)
                          : "-"}
                        <CurrencyLogo size="20px" currency={currency0} />
                      </Box>
                    </Box>
                    <Box
                      mt={1}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        fontFamily="Sandro"
                        fontSize="12px"
                        fontWeight="Light"
                        color={theme.palette.common.black}
                      >
                        Pooled {currency1.symbol}:
                      </Box>
                      <Box
                        alignSelf="right"
                        display="flex"
                        alignItems="center"
                        fontFamily="Sandro"
                        fontSize="12px"
                        fontWeight="Light"
                        color={theme.palette.common.black}
                      >
                        {token1Deposited?.toSignificant(6)}
                        <CurrencyLogo
                          size="20px"
                          style={{ marginLeft: "8px" }}
                          currency={currency1}
                        />
                      </Box>
                    </Box>
                    <Box
                      mt={1}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        fontFamily="Sandro"
                        fontSize="12px"
                        fontWeight="Light"
                        color={theme.palette.common.black}
                      >
                        Your Pool Tokens:
                      </Box>
                      <Box
                        alignSelf="right"
                        fontFamily="Sandro"
                        fontSize="12px"
                        fontWeight="Light"
                        color={theme.palette.common.black}
                      >
                        {userPoolBalance
                          ? userPoolBalance.toSignificant(4)
                          : "-"}
                      </Box>
                    </Box>
                    <Box
                      mt={1}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        fontFamily="Sandro"
                        fontSize="12px"
                        fontWeight="Light"
                        color={theme.palette.common.black}
                      >
                        Your Pool Share:
                      </Box>
                      <Box
                        alignSelf="right"
                        display="flex"
                        alignItems="center"
                        fontFamily="Sandro"
                        fontSize="12px"
                        fontWeight="Light"
                        color={theme.palette.common.black}
                      >
                        {poolTokenPercentage
                          ? `${poolTokenPercentage.toFixed(2)}%`
                          : "-"}
                      </Box>
                    </Box>
                    <Box
                      mt={2}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box
                        width="120px"
                        borderRadius="16px"
                        px={2}
                        py={1}
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        fontFamily="Sandro"
                        fontSize="12px"
                        fontWeight="Light"
                        color={theme.palette.secondary.contrastText}
                        component={Link}
                        to={`/add/${currencyId(currency0)}/${currencyId(
                          currency1
                        )}`}
                        sx={{
                          background:
                            "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
                          "&:hover": {
                            border: "2px solid #fff",
                            color: "#fff",
                            background:
                              "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED4621 16.3%, #ED2196 87.94%)",
                          },
                        }}
                      >
                        Add
                      </Box>
                      <Box
                        width="120px"
                        borderRadius="16px"
                        px={2}
                        py={1}
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        fontFamily="Sandro"
                        fontSize="12px"
                        fontWeight="Light"
                        color={theme.palette.secondary.contrastText}
                        component={Link}
                        to={`/remove/${currencyId(currency0)}/${currencyId(
                          currency1
                        )}`}
                        sx={{
                          background:
                            "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
                          "&:hover": {
                            border: "2px solid #fff",
                            color: "#fff",
                            background:
                              "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED4621 16.3%, #ED2196 87.94%)",
                          },
                        }}
                      >
                        Remove
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default AddRemoveLiquidityAccordian;
