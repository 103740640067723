import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import theme from "./theme";
import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Main from "./main";
import { AppContextProvider } from "./eth/utils/Utils";
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";

const chains = [mainnet];
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "03d8051880f6b1fcc66bb3961bb68ff0" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({
    appName: "web3Modal",
    chains,
  }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

ReactDOM.render(
  <WagmiConfig client={wagmiClient}>
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <Web3Modal
          projectId="03d8051880f6b1fcc66bb3961bb68ff0"
          ethereumClient={ethereumClient}
        />
        <CssBaseline />
        <Main />
      </AppContextProvider>
    </ThemeProvider>
  </WagmiConfig>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
