import { ETHER } from "moonchan-sdk";
import { ArrowDownward } from "@material-ui/icons";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Search } from "@material-ui/icons";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { useAllTokens, useToken } from "../../../hooks/Tokens";
import { useSelectedListInfo } from "../../../state/lists/hooks";
import { isAddress } from "../../../utils";
import CurrencyList from "./CurrencyList";
import { filterTokens } from "./filtering";
import { useTokenComparator } from "./sorting";
import {
  Box,
  DialogContent,
  Divider,
  IconButton,
  InputBase,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { LightTooltip } from "../swap/AdvancedSwapDetails";
import { AppContext } from "../../../utils/Utils";

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  onDismiss,
  isOpen,
}) {
  const matches = useMediaQuery("(max-width:760px)");
  const theme = useTheme();

  const fixedList = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [invertSearchOrder, setInvertSearchOrder] = useState(false);
  const allTokens = useAllTokens();

  // if they input an address, use it
  const isAddressSearch = isAddress(searchQuery);
  const searchToken = useToken(searchQuery);

  const showETH = useMemo(() => {
    const s = searchQuery.toLowerCase().trim();
    return s === "" || s === "b" || s === "bn" || s === "bnb";
  }, [searchQuery]);

  const tokenComparator = useTokenComparator(invertSearchOrder);

  const audioPlay = useSelector((state) => state.user.audioPlay);

  const filteredTokens = useMemo(() => {
    if (isAddressSearch) return searchToken ? [searchToken] : [];
    return filterTokens(Object.values(allTokens), searchQuery);
  }, [isAddressSearch, searchToken, allTokens, searchQuery]);

  const filteredSortedTokens = useMemo(() => {
    if (searchToken) return [searchToken];
    const sorted = filteredTokens.sort(tokenComparator);
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0);
    if (symbolMatch.length > 1) return sorted;

    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter(
        (token) => token.symbol?.toLowerCase() === symbolMatch[0]
      ),
      ...sorted.filter(
        (token) => token.symbol?.toLowerCase() !== symbolMatch[0]
      ),
    ];
  }, [filteredTokens, searchQuery, searchToken, tokenComparator]);
  const handleCurrencySelect = useCallback(
    (currency) => {
      onCurrencySelect(currency);
      onDismiss();
      if (audioPlay) {
        const audio = document.getElementById("bgMusic");
        if (audio) {
          audio.play();
        }
      }
    },
    [onDismiss, onCurrencySelect, audioPlay]
  );

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery("");
  }, [isOpen]);

  // manage focus on modal show
  const inputRef = useRef();
  const handleInput = useCallback((event) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
    fixedList.current?.scrollTo(0);
  }, []);

  const handleEnter = useCallback(
    (e) => {
      if (e.key === "Enter") {
        const s = searchQuery.toLowerCase().trim();
        if (s === "bnb") {
          handleCurrencySelect(ETHER);
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() ===
              searchQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0]);
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, searchQuery]
  );

  const selectedListInfo = useSelectedListInfo();
  return (
    <DialogContent className="dialoge__content__section">
      <div
        style={{
          background: "#13161f",
          textAlign: "center",
          paddingBottom: "10px",
          width: "304px",
        }}
      >
        <Box
          py={2}
          px={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{
            background: "#a4ff66",
          }}
        >
          <Box
            color="#000"
            fontSize="16px"
            textAlign="left"
            fontFamily="Roboto"
            fontWeight="Light"
            pl={1}
          >
            SELECT A TOKEN
          </Box>
          <Box>
            <IconButton
              style={{ width: "22px", height: "22px" }}
              onClick={onDismiss}
              aria-label="delete"
            >
              <i
                style={{
                  color: "#000",
                  textAlign: "right",
                  fontSize: "17px",
                }}
                className="fal fa-times"
              ></i>
            </IconButton>
          </Box>
        </Box>
        <Box paddingLeft="20px" paddingRight="20px">
          <Box
            display="flex"
            alignItems="center"
            borderRadius="16px"
            bgcolor="rgba(0, 0, 0, 0.3)"
            px={2}
            py={1}
            mt={3}
            mb={2}
          >
            <Search style={{ color: "#fff", fontSize: "25px" }} />
            <InputBase
              sx={{
                fontSize: matches ? "11px" : "13px",
                fontFamily: "Roboto",
                textAlign: "center",
                width: "100%",
                paddingLeft: "10px",
                color: "#fff",
              }}
              type="text"
              id="token-search-input"
              placeholder="Search token"
              value={searchQuery}
              ref={inputRef}
              onChange={handleInput}
              onKeyDown={handleEnter}
              inputProps={{ "aria-label": "search" }}
            />
          </Box>
        </Box>
        <div style={{ flex: "1" }}>
          <CurrencyList
            height={100}
            showETH={showETH}
            currencies={filteredSortedTokens}
            onCurrencySelect={handleCurrencySelect}
            otherCurrency={otherSelectedCurrency}
            selectedCurrency={selectedCurrency}
            fixedListRef={fixedList}
          />
        </div>
      </div>
    </DialogContent>
  );
}

export default CurrencySearch;
