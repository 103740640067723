import React, { useState } from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ETHProviders from "./Eth-Providers";
import Navbar from "./eth/components/NavBar/Navbar";
import { Box } from "@material-ui/core";

function Main() {
  const [openConnect, setopenConnect] = useState(false);
  const [open, setOpen] = useState(true);

  return (
    <BrowserRouter>
      <ETHProviders>
        <Navbar />
        <Box mt={4}>
          <App open={open} setOpen={setOpen} setopenConnect={setopenConnect} />
        </Box>
      </ETHProviders>
    </BrowserRouter>
  );
}

export default Main;
