import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import liqImg from "../../../images/liqImg.png";
import infoImg from "../../../images/infoSqu.png";
import qusSqu from "../../../images/qusSqu.png";
import { withStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { styles } from "./styles/style";
import { boxheaderimg, stylelines, Line } from "../../utils/images";
import theme from "../../../theme";
import { Link, useLocation, useHistory } from "react-router-dom";
import { TopNav } from "../small components/top nav/TopNav";
import AddRemoveLiquidityAccordian from "../AddRemoveLiquidity/AddRemoveLiquidityAccordian";
import { usePairs } from "../../data/Reserves";
import { useTokenBalancesWithLoadingIndicator } from "../../state/wallet/hooks";
import {
  toV2LiquidityToken,
  useTrackedTokenPairs,
} from "../../state/user/hooks";
import { AppContext } from "../../utils/Utils";
import CustomizedButton from "../small components/CustomButton/CustomButton";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#F1F5FD",
    color: "#223653",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: "Light",
    fontFamily: "Roboto",
    padding: 20,
  },
}))(Tooltip);
export const Pool = withStyles(styles)(
  ({ classes, setopenConnect, adding }) => {
    const [active, setActive] = useState(true);
    const matches = useMediaQuery("(max-width:600px)");
    const loc = useLocation();
    const history = useHistory();
    const { account, connect } = useContext(AppContext);
    // const TranslateString = useI18n();

    // fetch the user's balances of all tracked V2 LP tokens
    const trackedTokenPairs = useTrackedTokenPairs();
    const tokenPairsWithLiquidityTokens = useMemo(
      () =>
        trackedTokenPairs.map((tokens) => ({
          liquidityToken: toV2LiquidityToken(tokens),
          tokens,
        })),
      [trackedTokenPairs]
    );
    const liquidityTokens = useMemo(
      () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
      [tokenPairsWithLiquidityTokens]
    );
    const [v2PairsBalances, fetchingV2PairBalances] =
      useTokenBalancesWithLoadingIndicator(
        account ?? undefined,
        liquidityTokens
      );

    console.log(v2PairsBalances, "v2PairsBalances");
    // fetch the reserves for all V2 pools in which the user has a balance
    const liquidityTokensWithBalances = useMemo(
      () =>
        tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) => {
          v2PairsBalances[liquidityToken.address]?.greaterThan("0");
        }),
      [tokenPairsWithLiquidityTokens, v2PairsBalances]
    );

    const v2Pairs = usePairs(
      liquidityTokensWithBalances.map(({ tokens }) => tokens)
    );
    const v2IsLoading =
      fetchingV2PairBalances ||
      v2Pairs?.length < liquidityTokensWithBalances.length ||
      v2Pairs?.some((V2Pair) => !V2Pair);

    const allV2PairsWithLiquidity = v2Pairs
      .map(([, pair]) => pair)
      .filter((v2Pair) => Boolean(v2Pair));
    // console.log(
    //   allV2PairsWithLiquidity,
    //   v2Pairs,
    //   liquidityTokensWithBalances,
    //   tokenPairsWithLiquidityTokens,
    //   "allV2PairsWithLiquidity"
    // );

    console.log(
      tokenPairsWithLiquidityTokens,
      v2PairsBalances,
      "allV2PairsWithLiquidity--->"
    );

    return (
      <>
        {/* <Box textAlign="center">
          <img width={matches ? "250px" : "450px"} src={liqImg} alt="" />
        </Box> */}

        <Container maxWidth="sm">
          <Box mt={0} className={classes.tradeContainer} align="center">
            <Box
              sx={{
                height: "auto",
                background: "#212535",
                margin: "0rem auto",
                borderRadius: 20,
                overflow: "hidden",
                width: "90%",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems={matches ? "left" : "center"}
                flexDirection={matches ? "column" : "row"}
                py={2}
                px={1.5}
                sx={{
                  background: "#a4ff66",
                }}
              >
                <Box display="flex" alignItems="center">
                  <img
                    width="20px"
                    src={infoImg}
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  <Box
                    textAlign="left"
                    fontFamily="Roboto"
                    fontSize="17px"
                    fontWeight="Light"
                    color="#000"
                  >
                    ADD LIQUIDITY TO RECEIVE LP TOKENS
                  </Box>
                </Box>

                <Box
                  textAlign="center"
                  fontFamily="Roboto"
                  fontSize="14px"
                  fontWeight="Bold"
                  color="#ffffff"
                  borderRadius="10px"
                  mt={matches ? 2 : 0}
                  width={matches ? "100%" : "220px"}
                  pl={2}
                  pr={2}
                  pt={1}
                  pb={1}
                  style={{
                    cursor: "pointer",
                    background: "#212535",
                  }}
                  onClick={() => history.push("/add/WETH")}
                >
                  ADD LIQUIDITY
                </Box>
              </Box>

              <Box
                mt={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={2}
              >
                <Box
                  color="#fff"
                  fontFamily="Roboto"
                  fontSize="16px"
                  fontWeight="Bold"
                >
                  YOUR LIQUIDITY
                </Box>
                <Box>
                  <LightTooltip
                    title={
                      // adding
                      //   ? "When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below"
                      //   :
                      "Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive."
                    }
                  >
                    <IconButton
                      style={{
                        borderRadius: "10px",
                        width: "25px",
                        height: "25px",
                      }}
                      aria-label="delete"
                    >
                      <img
                        width="20px"
                        src={qusSqu}
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                    </IconButton>
                  </LightTooltip>
                </Box>
              </Box>

              {!account ? (
                <Box mx={2} bgcolor="#b7b7b7" borderRadius="16px" py={3}>
                  <CustomizedButton
                    color="#fff"
                    variant="contained"
                    onClick={connect}
                    fullWidth
                  >
                    Connect Wallet
                  </CustomizedButton>
                </Box>
              ) : v2IsLoading ? (
                <Box
                  mx={5}
                  display="flex"
                  justifyContent="center"
                  padding="40px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    color="#ffffff"
                    textAlign="center"
                    fontFamily="Roboto"
                  >
                    Loading..
                    <CircularProgress
                      style={{ marginLeft: "5px" }}
                      size={20}
                      color="#ffffff"
                    />
                  </Box>
                </Box>
              ) : allV2PairsWithLiquidity?.length > 0 ? (
                <>
                  {allV2PairsWithLiquidity.map((v2Pair) => (
                    <AddRemoveLiquidityAccordian
                      key={v2Pair.liquidityToken.address}
                      pair={v2Pair}
                    />
                  ))}
                </>
              ) : (
                <Box mt={2} mx={2}>
                  <Box
                    fontFamily="Roboto"
                    fontWeight="Light"
                    bgcolor="#b7b7b7"
                    borderRadius="16px"
                    py={3}
                    color="#fff"
                    textAlign="center"
                  >
                    No liquidity found.
                  </Box>
                </Box>
              )}

              <Box
                pt={5}
                color={theme.palette.common.black}
                // color="red"
                fontSize={{ xs: 13, sm: 16 }}
                fontFamily="Roboto"
                // fontWeight="lighter"
                textAlign="left"
                px={2}
              >
                Don't see a pool you joined?{" "}
                <Link
                  // component="button"
                  to="/findpools"
                  style={{
                    color: "#fff",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    // fontWeight: "lighter",
                  }}
                >
                  Import it
                </Link>
              </Box>
              <Box
                fontSize={{ xs: 10, sm: 12 }}
                fontFamily="Roboto"
                // fontWeight="lighter"
                textAlign="left"
                color={theme.palette.common.black}
                px={2}
                pb={2}
              >
                Or, if you staked your LP tokens in a farm, unstake them to see
                them here
              </Box>
            </Box>
          </Box>
        </Container>
      </>
    );
  }
);
