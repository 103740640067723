import React from "react";
import { AlertTriangle } from "react-feather";
import { Box, Button, IconButton, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CustomizedButton from "../../small components/CustomButton/CustomButton";

export const ContentHeader = ({ children, onDismiss }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      color="#fff"
      width="100%"
      style={{
        background: "#A4FF66",
      }}
      p={1}
    >
      <Box
        color={"#000"}
        fontSize="16px"
        textAlign="center"
        fontWeight="Bold"
        fontFamily="Sandro"
      >
        Confirm Swap
      </Box>
      <IconButton onClick={onDismiss} variant="text">
        <Close style={{ color: "#000" }} />
      </IconButton>
    </Box>
  );
};

const TransactionErrorContent = ({ message, onDismiss }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box width="400px">
        <ContentHeader onDismiss={onDismiss}>Error</ContentHeader>
        <Box
          style={{ marginTop: 20, padding: "2rem 0" }}
          gap="24px"
          justify="center"
        >
          <AlertTriangle color="red" style={{ strokeWidth: 1.5 }} size={64} />
          <Box
            color={theme.palette.common.black}
            fontSize="14px"
            textAlign="center"
            fontWeight="Light"
            fontFamily="Sandro"
          >
            {message}
          </Box>
        </Box>
      </Box>
      <CustomizedButton fun={onDismiss}>Dismiss</CustomizedButton>
    </Box>
  );
};

export default TransactionErrorContent;
