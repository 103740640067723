import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles/style";
import { PairState, usePair } from "../../data/Reserves";
import { usePairAdder } from "../../state/user/hooks";
import { useTokenBalance } from "../../state/wallet/hooks";
import { Link, useLocation } from "react-router-dom";
import { ETHER, JSBI } from "moonchan-sdk";
import CurrencyLogo from "../small components/CurrencyLogo/CurrencyLogo";
import CurrencySearchModal from "../small components/SearchModal/CurrencySearchModal";
import currencyId from "../../utils/currencyId";
import infoBlue from "../../../images/infoBlue.png";
import { AppContext } from "../../utils/Utils";
let Fields = {
  TOKEN0: 0,
  TOKEN1: 1,
};
export const FindPools = withStyles(styles)(({ classes }) => {
  const [active, setActive] = useState(true);
  const matches = useMediaQuery("(max-width:600px)");
  const loc = useLocation();
  const theme = useTheme();
  const { account } = useContext(AppContext);

  const [showSearch, setShowSearch] = useState(false);
  const [activeField, setActiveField] = useState(Fields.TOKEN1);

  const [currency0, setCurrency0] = useState(ETHER);
  const [currency1, setCurrency1] = useState(null);

  const [pairState, pair] = usePair(
    currency0 ?? undefined,
    currency1 ?? undefined
  );
  const addPair = usePairAdder();

  // const TranslateString = useI18n();
  useEffect(() => {
    if (pair) {
      addPair(pair);
    }
  }, [pair, addPair]);

  const validPairNoLiquidity =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.raw, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.raw, JSBI.BigInt(0))
    );

  const position = useTokenBalance(account ?? undefined, pair?.liquidityToken);
  const hasPosition = Boolean(
    position && JSBI.greaterThan(position.raw, JSBI.BigInt(0))
  );

  const handleCurrencySelect = useCallback(
    (currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency);
      } else {
        setCurrency1(currency);
      }
    },
    [activeField]
  );

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false);
  }, [setShowSearch]);

  const prerequisiteMessage = (
    <Box padding="45px 10px">
      <Box
        fontFamily="Sandro"
        textAlign="center"
        fontSize="12px"
        color={theme.palette.common.black}
      >
        {!account
          ? "Connect to a wallet to find pools"
          : "Select a token to find your liquidity."}
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="sm">
      <Box mt={0} className={classes.tradeContainer} align="center">
        <Box
          style={{
            width: matches ? "100%" : "80%",
            height: "auto",
            background: "#212535",
            margin: "2rem auto",
            borderRadius: 20,
            overflow: "hidden",
          }}
        >
          <Box
            py={2}
            pl={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{
              background: "#a4ff66",
            }}
          >
            <Box>
              <Link to="/pool">
                <ArrowBackIosIcon style={{ color: "#000" }} />
              </Link>
            </Box>
            <Box
              color="#000"
              fontSize="20px"
              textAlign="left"
              fontFamily="Roboto"
              fontWeight="Bold"
              mr={2}
            >
              Import Pool
            </Box>
            <Box></Box>
          </Box>
          <Box
            component={Button}
            mt={2}
            mb={1}
            mx={2}
            pt={1}
            pb={1}
            width="93%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              setShowSearch(true);
              setActiveField(Fields.TOKEN0);
            }}
            style={{
              background: "#a4ff66",
              cursor: "pointer",
              borderRadius: "16px",
            }}
            startIcon={
              currency0 ? (
                <CurrencyLogo
                  currency={currency0}
                  style={{ marginRight: ".5rem" }}
                />
              ) : null
            }
            color="primary.main"
            variant="contained"
            endIcon={
              <i className="fad fa-angle-down" style={{ color: "#000" }}></i>
            }
          >
            <Box
              fontFamily="Roboto"
              fontSize="18px"
              fontWeight="Bold"
              color="#000"
              ml={1}
              mr={2}
            >
              {currency0 ? currency0.symbol : "Select a Token"}
            </Box>
          </Box>
          <Box
            color={theme.palette.common.black}
            fontSize="25px"
            textAlign="center"
            fontWeight="600"
            paddingLeft="20px"
            fontFamily="Sandro"
          >
            +
          </Box>
          <Box
            component={Button}
            my={1}
            mx={2}
            pt={1}
            pb={1}
            width="93%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              setShowSearch(true);
              setActiveField(Fields.TOKEN1);
            }}
            style={{
              background: "#a4ff66",
              cursor: "pointer",
              borderRadius: "16px",
            }}
            startIcon={
              currency1 ? (
                <CurrencyLogo
                  currency={currency1}
                  style={{
                    marginRight: ".5rem",
                    color: "#000",
                  }}
                />
              ) : null
            }
            color="secondary"
            variant="contained"
            endIcon={
              <i className="fad fa-angle-down" style={{ color: "#000" }}></i>
            }
          >
            <Box
              fontFamily="Roboto"
              fontSize="18px"
              fontWeight="Bold"
              color="#000"
              ml={1}
              mr={2}
            >
              {currency1 ? currency1.symbol : "Select a Token"}
            </Box>
          </Box>
          {hasPosition && (
            <Box
              style={{
                justifyItems: "center",
                padding: "12px 0px",
                borderRadius: "12px",
              }}
            >
              <Box
                style={{
                  textAlign: "center",
                  color: theme.palette.common.black,
                  fontSize: "18px",
                  fontFamily: "Roboto",
                }}
              >
                Pool found!
              </Box>
            </Box>
          )}

          {currency0 && currency1 ? (
            pairState === PairState.EXISTS ? (
              // hasPosition && pair ? (
              //   <MinimalPositionCard pair={pair} />
              // ) : (
              <Box padding="20px 10px">
                <Box gap="sm" justify="center">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: theme.palette.common.black,
                      fontSize: "16px",
                      fontFamily: "Roboto",
                    }}
                  >
                    <img
                      src={infoBlue}
                      alt=""
                      width="15px"
                      style={{ marginRight: "8px" }}
                    />{" "}
                    You don’t have liquidity in this pool yet.
                  </Box>
                  <Link
                    to={`/add/${currencyId(currency0)}/${currencyId(
                      currency1
                    )}`}
                    style={{ color: "#000000" }}
                  >
                    <Box
                      style={{
                        textAlign: "center",
                        color: "#000000",
                        fontWeight: 400,
                        fontSize: "20px",
                        marginTop: "10px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Add Liquidity
                    </Box>
                  </Link>
                </Box>
              </Box>
            ) : validPairNoLiquidity ? (
              <Box padding="20px 10px">
                <Box gap="sm" justify="center">
                  <Box
                    style={{
                      textAlign: "center",
                      color: theme.palette.common.black,
                      fontSize: "18px",
                      fontFamily: "Roboto",
                    }}
                  >
                    No pool found.
                  </Box>
                  <Link
                    to={`/add/${currencyId(currency0)}/${currencyId(
                      currency1
                    )}`}
                    style={{
                      color: theme.palette.secondary.main,
                      fontSize: "18px",
                      marginTop: "10px",
                      fontWeight: 400,
                    }}
                  >
                    Create pool.
                  </Link>
                </Box>
              </Box>
            ) : pairState === PairState.INVALID ? (
              <Box padding="20px 10px">
                <Box gap="sm" justify="center">
                  <Box
                    style={{
                      textAlign: "center",
                      color: theme.palette.common.black,
                      fontSize: "18px",
                      fontFamily: "Roboto",
                    }}
                  >
                    Invalid pair.
                  </Box>
                </Box>
              </Box>
            ) : pairState === PairState.LOADING ? (
              <Box padding="20px 10px">
                <Box gap="sm" justify="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color={theme.palette.common.black}
                    style={{ textAlign: "center" }}
                  >
                    Loading..{" "}
                    <CircularProgress
                      size={20}
                      color="common.black"
                      style={{ marginLeft: "5px" }}
                    />
                  </Box>
                </Box>
              </Box>
            ) : null
          ) : (
            prerequisiteMessage
          )}
        </Box>
        <CurrencySearchModal
          isOpen={showSearch}
          onCurrencySelect={handleCurrencySelect}
          onDismiss={handleSearchDismiss}
          showCommonBases
          selectedCurrency={
            (activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined
          }
        />
      </Box>
    </Container>
  );
});
