import {
  Box,
  Button,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useContext, useState } from "react";

import { useCurrencyBalance } from "../../../state/wallet/hooks";
import { escapeRegExp } from "../../../utils";
import { ExpandMoreIcon } from "../../../utils/images";
import { AppContext } from "../../../utils/Utils";
import CurrencyLogo from "../CurrencyLogo/CurrencyLogo";
import DoubleCurrencyLogo from "../DoubleCurrencyLogo/DoubleCurrencyLogo";
import CurrencySearchModal from "../SearchModal/CurrencySearchModal";

export default function CurrencyInput({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
}) {
  const matches = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const { account } = useContext(AppContext);
  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currency ?? undefined
  );
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);
  const enforcer = (nextUserInput) => {
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput);
    }
  };
  const translatedLabel = label || "Input";
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  return (
    <Box width="100%" borderRadius="5px" padding="5px">
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        style={{ background: "rgba(0, 0, 0, 0.05)", borderRadius: "10px" }}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py={1}
          >
            <Box display="flex" flexDirection="column" width="50%">
              <input
                value={value}
                onChange={(event) => {
                  // replace commas with periods, because uniswap exclusively uses period as the decimal separator
                  enforcer(event.target.value.replace(/,/g, "."));
                }}
                // universal input options
                inputMode="decimal"
                title="Token Amount"
                autoComplete="off"
                autoCorrect="off"
                // text-specific options
                type="text"
                pattern="^[0-9]*[.,]?[0-9]*$"
                placeholder={"0.0"}
                minLength={1}
                maxLength={79}
                spellCheck="false"
                style={{
                  textAlign: "left",
                  border: "none",
                  padding: "10px 10px",
                  borderRadius: "4px",
                  color: "#FFF",
                  backgroundColor: "transparent",
                  outline: "none",
                  fontSize: "25px",
                  fontFamily: "Roboto",
                  fontWeight: "Bold",
                }}
              />
            </Box>
            <Box
              borderLeft="2px solid lightGray"
              display="flex"
              alignItems="center"
              width="50%"
              pl={1}
            >
              <Box width="100%" display="flex" flexDirection="column">
                <Box width="100%" display="flex" justifyContent="flex-start">
                  <Box>
                    {pair ? (
                      <DoubleCurrencyLogo
                        currency0={pair.token0}
                        currency1={pair.token1}
                        size={18}
                        margin={true}
                      />
                    ) : currency ? (
                      <CurrencyLogo currency={currency} size="20px" />
                    ) : null}
                  </Box>{" "}
                  &nbsp;
                  <Box
                    fontFamily="Roboto"
                    fontWeight="Bold"
                    fontSize={{ xs: 16, sm: 20 }}
                    color="#fff"
                    textAlign="left"
                  >
                    {pair ? (
                      <>
                        {pair?.token0.symbol}:{pair?.token1.symbol}
                      </>
                    ) : (
                      <>
                        {currency &&
                        currency.symbol &&
                        currency.symbol.length > 20
                          ? `${currency.symbol.slice(
                              0,
                              4
                            )}...${currency.symbol.slice(
                              currency.symbol.length - 5,
                              currency.symbol.length
                            )}`
                          : currency?.symbol || "Select a currency"}
                      </>
                    )}
                  </Box>
                </Box>
                {account && (
                  <Box
                    // onClick={onMax}
                    fontSize="14px"
                    style={{ display: "inline" }}
                    textAlign="left"
                    color="#fff"
                    ml={0.5}
                    fontFamily="Roboto"
                    fontWeight="lighter"
                  >
                    {!hideBalance && !!currency && selectedCurrencyBalance
                      ? `Balance: ${selectedCurrencyBalance?.toSignificant(6)}`
                      : " -"}
                  </Box>
                )}
              </Box>
              {!disableCurrencySelect && onCurrencySelect && (
                <CurrencySearchModal
                  isOpen={modalOpen}
                  onDismiss={handleDismissSearch}
                  onCurrencySelect={onCurrencySelect}
                  selectedCurrency={currency}
                  otherSelectedCurrency={otherCurrency}
                  showCommonBases={showCommonBases}
                />
              )}
              {!disableCurrencySelect && (
                <ExpandMoreIcon
                  fontSize="15px"
                  style={{
                    color: "#fff",
                    background: "rgba(0, 0, 0, 0.05)",
                    marginLeft: "10px",
                    cursor: "pointer",
                    width: "24px",
                    height: "24px",
                  }}
                  onClick={() => {
                    if (!disableCurrencySelect) {
                      setModalOpen(true);
                    }
                  }}
                />
              )}
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12}>
        <Box
          width="100%"
          mt={1}
          display="flex"
          justifyContent="space-between"
        >
          
          
        </Box>
      </Grid> */}
        {/* <Grid item xs={8} sm={8} justifyContent="flex-end">
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {account && currency && showMaxButton && label !== "To" && (
            <Button
              onClick={onMax}
              scale="sm"
              flexBasis={1}
              variant="text"
              style={{
                backgroundColor: "#37324d",
                color: "#fff",
                borderRadius: "10px",
              }}
            >
              MAX
            </Button>
          )}
          
        </Box>
      </Grid> */}
      </Grid>
    </Box>
  );
}
