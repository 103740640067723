import { Box, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { styles } from "../../trade/styles/style";

export const TopNav = withStyles(styles)(({ swap }) => {
  const loc = useLocation();

  return (
    <>
      <Box
        display="flex"
        border="2px solid #7450fe"
        borderRadius="30px"
        mb={3}
        mx={3}
      >
        <Link
          to="/"
          className={
            loc.pathname === "/" || swap === "swap"
              ? "dream-btn"
              : "dream-btn-other"
          }
          style={{
            textDecoration: "none",
            color: "#fff",
            width: "50%",
          }}
        >
          <Box
            textAlign="center"
            sx={{ cursor: "pointer" }}
            // py={1}
            borderRadius="20px"
          >
            Swap
          </Box>
        </Link>

        {/* <a
          href="https://solarshepardstaking.solardex.finance/"
          style={{
            backgroundColor: swap == "staking" ? "rgba(75,192,192,1)" : "",
            color: "#fff",

            border: swap == "staking" ? "1px solid #02052C" : "",
            width: "50%",
            textDecoration: "none",
            borderRadius: "30px",
          }}
        >
          <Box
            textAlign="center"
            sx={{ cursor: "pointer" }}
            py={1}
            borderRadius="20px"
          >
            Staking
          </Box>
        </a> */}

        <Link
          to="/pool"
          className={swap === "liq" ? "dream-btn" : "dream-btn-other"}
          style={{
            color: "#fff",
            width: "50%",
            textDecoration: "none",
          }}
        >
          <Box
            textAlign="center"
            sx={{ cursor: "pointer" }}
            // py={1}
            borderRadius="20px"
          >
            Liquidity
          </Box>
        </Link>
        {/* <Link
          to="/widget"
          style={{
            backgroundColor: swap == "widget" ? "rgba(75,192,192,1)" : "",
            color: "#fff",

            border: swap == "widget" ? "1px solid #02052C" : "",
            width: "50%",
            textDecoration: "none",
            borderRadius: "30px",
          }}
        >
          <Box
            textAlign="center"
            sx={{ cursor: "pointer" }}
            py={1}
            borderRadius="20px"
          >
            Ramp
          </Box>
        </Link> */}
      </Box>
      {/* <Box className={classes.box}>
        <Button
          component={Link}
          to="/"
          className={loc.pathname === "/" ? classes.activeBtn : classes.btn}
        >
          <span>Swap</span>
        </Button>
        <Button
          component={Link}
          to="/pool"
          className={loc.pathname == "/pool" ? classes.activeBtn : classes.btn}
        >
          {" "}
          <span>Liquidity</span>
        </Button>
      </Box> */}
    </>
  );
});
