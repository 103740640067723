import { TradeType } from "moonchan-sdk";
import React, { useMemo, useState } from "react";
import { Repeat } from "react-feather";

import { Field } from "../../../state/swap/actions";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity,
} from "../../../utils/prices";
import { Box, Button, useMediaQuery } from "@material-ui/core";
import theme from "../../../../theme";
import { ONE_BIPS } from "../../../constants";
import rateImg from "../../../../images/rate.png";

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  disabledConfirm,
}) {
  const [showInverted, setShowInverted] = useState(false);
  const matches = useMediaQuery("(max-width:760px)");

  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [allowedSlippage, trade]
  );
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade]
  );
  const severity = warningSeverity(priceImpactWithoutFee);

  return (
    <>
      <Box mt={5}>
        <Box
          mt={3}
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            color={theme.palette.common.black}
            fontSize={matches ? "12px" : "15px"}
            textAlign="center"
            fontWeight="Light"
            fontFamily="MacPaw"
          >
            Price
          </Box>
          <Box
            color={theme.palette.common.black}
            fontSize={matches ? "12px" : "15px"}
            display="flex"
            alignItems="center"
            fontWeight="Light"
            fontFamily="MacPaw"
          >
            {formatExecutionPrice(trade, showInverted)}
            <Box ml={1} onClick={() => setShowInverted(!showInverted)}>
              <img src={rateImg} width="15px" alt="" />
            </Box>
          </Box>
        </Box>

        <Box
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={1}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "15px"}
              textAlign="center"
              fontWeight="Light"
              fontFamily="MacPaw"
            >
              {trade.tradeType === TradeType.EXACT_INPUT
                ? "Minimum received"
                : "Maximum sold"}
            </Box>
          </Box>

          <Box
            color={theme.palette.common.black}
            fontSize={matches ? "12px" : "15px"}
            textAlign="center"
            fontWeight="Light"
            fontFamily="MacPaw"
          >
            {trade.tradeType === TradeType.EXACT_INPUT
              ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? "-"
              : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ??
                "-"}{" "}
            {trade.tradeType === TradeType.EXACT_INPUT
              ? trade.outputAmount.currency.symbol
              : trade.inputAmount.currency.symbol}
          </Box>
        </Box>
        <Box
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={1}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "15px"}
              textAlign="center"
              fontWeight="Light"
              fontFamily="MacPaw"
            >
              Price Impact
            </Box>
          </Box>
          <Box
            color={
              severity === 3 || severity === 4
                ? "#ED2196"
                : severity === 2
                ? "#ED2196"
                : severity === 1
                ? "#ED2196"
                : "#ED2196"
            }
            fontSize={matches ? "12px" : "15px"}
            textAlign="center"
            fontWeight="Light"
            fontFamily="MacPaw"
          >
            {priceImpactWithoutFee
              ? priceImpactWithoutFee.lessThan(ONE_BIPS)
                ? "<0.01%"
                : `${priceImpactWithoutFee.toFixed(2)}%`
              : "-"}
          </Box>
        </Box>
        <Box
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={1}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "15px"}
              textAlign="center"
              fontWeight="Light"
              fontFamily="MacPaw"
            >
              Liquidity Provider Fee
            </Box>
          </Box>
          <Box
            color={theme.palette.common.black}
            fontSize={matches ? "12px" : "15px"}
            textAlign="center"
            fontWeight="Light"
            fontFamily="MacPaw"
          >
            {realizedLPFee
              ? `${realizedLPFee?.toSignificant(6)} ${
                  trade.inputAmount.currency.symbol
                }`
              : "-"}
          </Box>
        </Box>
      </Box>

      <Box mt={2}>
        <Button
          onClick={onConfirm}
          disabled={disabledConfirm}
          variant={severity > 2 ? "danger" : "primary"}
          style={{
            background: "#A4FF66",
            color: "#000",
            borderColor: theme.palette.secondary.main,
            fontFamily: "Sandro",
            fontWeight: "Bold",
            fontSize: "15px",
            height: "60px",
            width: "90%",
            borderRadius: "16px",
            "&:hover": {
              background: "#A4FF66",
              boxShadow: "none",
            },
            "&:disabled": {
              background: theme.palette.common.tooltip,
              boxShadow: "#ffcdd6",
            },
          }}
          mt="10px"
          id="confirm-swap-or-send"
          width="100%"
        >
          {severity > 2 ? "Swap Anyway" : "Confirm Swap"}
        </Button>
      </Box>
    </>
  );
}
