import React from "react";
import { Provider } from "react-redux";
import { NetworkContextName } from "./eth/constants";
import store from "./eth/state";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApplicationUpdater from "./eth/state/application/updater";
import ListsUpdater from "./eth/state/lists/updater";
import MulticallUpdater from "./eth/state/multicall/updater";
import TransactionUpdater from "./eth/state/transactions/updater";
// import ToastListener from "./components/ToastListener";

const Providers = ({ children }) => {
  return (
    <Provider store={store}>
      <ListsUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
      <ToastContainer />
      {children}
    </Provider>
  );
};

export default Providers;
