export const styles = (theme) => ({
  tradeContainer: {
    textAlign: "center",
  },
  heading: {
    color: "#e20553",
    marginBottom: "1rem",
    fontSize: "40px",
    fontWeight: 600,
  },
  box: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 30,
    display: "inline",
    padding: "13px 0px",
  },
  activeBtn: {
    border: "none",
    borderRadius: "30px",
    width: "23%",
    padding: "9px",
    marginBottom: "2px",
    color: theme.trade.box.textColor,
    // fontFamily: "Segoe UI",
    fontWeight: "bold",
    background: "#f18dad",
    "&:hover": {
      background: "#f18dad",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".7rem",
      padding: "13px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
      padding: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".5rem",
    },
  },

  btn: {
    border: "none",
    color: "#f18dad",
    fontWeight: "bold",
    borderRadius: "30px",
    width: "24%",
    // fontFamily: "Segoe UI",
    "&:hover": {
      background: theme.palette.primary.light,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".7rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".5rem",
    },
  },

  ExchangeBox: {
    height: "auto",
    border: "1px solid #fff",
    background: "#212535",
    // background:
    //   "url(dwdqwdq@4x.png), linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 2deg at 17.76% -94.66%, #FFFFFF 0deg, #A2B0C1 16.7deg, #F9FBFE 24.27deg, #939FAD 47.25deg, #C1D7DB 60.32deg, #C7E2DE 72deg, #D7F7EF 86.4deg, #DEFFF6 97.2deg, #EAFFF9 115.2deg, #F9FFFD 129.6deg, #FFFFFF 156.38deg, #B7C8DC 187.01deg, #E3ECFC 203.99deg, #FCFDFE 219.78deg, #F2F6FD 230.4deg, #EAF0FC 270deg, #FFFFFF 360deg)",
    margin: "0rem auto",
    borderRadius: 20,
    padding: "1rem 1rem",
    overflow: "hidden",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0.5rem 0.5rem",
    },
  },
  Exinput: {
    width: "100%",
    height: "100%",
    border: "none",
    fontSize: "2rem",
    paddingLeft: "1rem",
    color: theme.trade.box.textColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    backgroundColor: "transparent",
    "&:focus": {
      outline: "none",
    },
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  Middline: {
    // width: "100%",
    textAlign: "center",
    // borderBottom: `1px solid #f18dad`,
    display: "flex",
    justifyContent: "center",
    position: "relative",
    margin: "auto",
    lineHeight: "2rem",
  },
  circleBox: {
    width: "35px",
    height: "35px",
    borderRadius: "100%",
    background: "#a4ff66",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
