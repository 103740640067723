import React, { useState } from "react";
import {
  Box,
  Container,
  Dialog,
  Divider,
  Hidden,
  Slide,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";

import bubIcon from "../../../images/bubIcon.png";
import eth1 from "../../../images/eth1.png";
import dialog from "../../../images/Dialog.png";
import bub from "../../../images/bub.png";
import lock from "../../../images/lock.png";
import usdt from "../../../images/usdt.png";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import ViewListIcon from "@material-ui/icons/ViewList";
import CloseIcon from "@material-ui/icons/Close";
import { BsCalculatorFill } from "react-icons/bs";

const AntTabs = withStyles({
  root: {
    height: "46px",
    width: "97px",
    border: "1px solid rgba(255, 255, 255, 0.05)",
    borderRadius: "16px",
  },
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);
const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: "none",
  borderRadius: "7px",
  minWidth: "46px",
  color: "rgba(255, 255, 255, 0.5)",
  "&.Mui-selected": {
    background: "rgba(255, 255, 255, 0.05)",
    color: "#fff",
    // paddingTop: "17px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Transition1 = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StyledDialog = withStyles({
  paper: {
    background: "#F1F5FD",
    borderRadius: "16px",
    height: "435px",
    width: "392px",
  },
})(Dialog);

const DetailDialog = withStyles({
  paper: {
    background: "#F1F5FD",
    borderRadius: "16px",
    height: "210px",
    width: "392px",
  },
})(Dialog);
export default function Protcol() {
  const matches = useMediaQuery("(max-width:960px)");
  const matches1 = useMediaQuery("(min-width:1250px)");

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <StyledDialog
        // fullWidth
        open={openDialog}
        TransitionComponent={Transition}
      >
        <Box
          style={{
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            style={{
              background: `url(${dialog})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontFamily: "sandro",
                fontWeight: "Bold",
                color: "#fff",
                textTransform: "uppercase",
              }}
            >
              ROI Calculator
            </Typography>
            <CloseIcon
              style={{
                cursor: "pointer",
                color: "#C3C9DA ",
              }}
              onClick={() => {
                setOpenDialog(!openDialog);
              }}
            />
          </Box>
          <Box mx={2} mt={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                style={{
                  color: "#677E96",
                  fontFamily: "Macpaw",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                Days timeframe
              </Typography>
              <Typography
                style={{
                  color: "#677E96",
                  fontFamily: "Macpaw",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                ROI
              </Typography>
              <Typography
                style={{
                  color: "#677E96",
                  fontFamily: "Macpaw",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                $BUB per $1000
              </Typography>
            </Box>
            <Divider
              style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }}
            />
            <Box display="flex" justifyContent="space-between" mt={1.5}>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                1
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                0.14%
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                46.78
              </Typography>
            </Box>
            <Divider
              style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }}
            />
            <Box display="flex" justifyContent="space-between" mt={1.5}>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                7
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                1.02%
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                328.89
              </Typography>
            </Box>
            <Divider
              style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }}
            />
            <Box display="flex" justifyContent="space-between" mt={1.5}>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                30
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                4.44%
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                1433.31
              </Typography>
            </Box>
            <Divider
              style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }}
            />
            <Box display="flex" justifyContent="space-between" mt={1.5}>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                365 (APY)
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                69.62%
              </Typography>
              <Typography
                style={{
                  color: "#3A5168",
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                22481.57
              </Typography>
            </Box>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "12px",
                fontWeight: "400",
                marginTop: "20px",
              }}
            >
              Calculated based on current rates. Compounding 1x daily. Rates are
              estimates provided for your convenience only, and by no means
              represent guaranteed returns.
            </Typography>
            <Box
              mt={2}
              style={{
                color: "#ff3e85",
                border: "2px solid #ff3e85",
                height: "40px",
                width: "100%",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "sandro",
                fontSize: "12px",
                fontWeight: "Bold",
              }}
            >
              Get BUB-ETH LP
            </Box>
          </Box>
        </Box>
      </StyledDialog>
      <DetailDialog open={openDialog1} TransitionComponent={Transition1}>
        <Box
          style={{
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            style={{
              background: `url(${dialog})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontFamily: "sandro",
                fontWeight: "Bold",
                color: "#fff",
                textTransform: "uppercase",
              }}
            >
              Account Detail
            </Typography>
            <CloseIcon
              style={{
                cursor: "pointer",
                color: "#C3C9DA ",
              }}
              onClick={() => {
                setOpenDialog1(!openDialog1);
              }}
            />
          </Box>
          <Box
            mx={2}
            mt={2}
            style={{
              background: "#fff",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "56px",
              borderRadius: "8px",
            }}
          >
            <Box display="flex">
              <Box
                style={{
                  width: "32px",
                  height: "32px",
                  background: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <img src={eth1} width="15px" alt="" />
              </Box>
              <Box ml={1}>
                <Typography
                  style={{
                    fontSize: "12px",
                    fontFamily: "sandro",
                    fontWeight: "Bold",
                    color: "#2F3841",
                    textTransform: "uppercase",
                  }}
                >
                  0X1FC6...4FE2
                </Typography>
                <Typography
                  mt={1}
                  style={{
                    fontSize: matches ? "8px" : "12px",
                    fontFamily: "Macpaw",
                    fontWeight: "400",
                    color: "#677E96",
                  }}
                >
                  Connected with MetaMask
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography
                style={{
                  fontSize: "13px",
                  fontFamily: "Macpaw",
                  fontWeight: "600",
                  color: "#008BFF",
                }}
              >
                Change
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Box
              mt={2}
              style={{
                color: "#ff3e85",
                border: "2px solid #ff3e85",
                height: "40px",
                width: matches ? "45%" : "180px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "sandro",
                fontSize: "12px",
                fontWeight: "Bold",
                textTransform: "uppercase",
              }}
            >
              Copy address
            </Box>
            <Box
              mt={2}
              style={{
                color: "#ff3e85",
                border: "2px solid #ff3e85",
                height: "40px",
                width: matches ? "40%" : "154px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "sandro",
                fontSize: "12px",
                fontWeight: "Bold",
                textTransform: "uppercase",
              }}
            >
              Etherscan
            </Box>
          </Box>
        </Box>
      </DetailDialog>
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" mt={2}>
          <Box position="relative">
            <img
              src={bub}
              style={{
                position: "absolute",
                right: "-40px",
                bottom: "-10px",
              }}
              width={matches ? "0px" : "76px"}
              alt=""
            />
            <Typography
              style={{
                textTransform: "uppercase",
                fontFamily: "Sandro",
                fontSize: matches ? "18px" : "56px",
                fontWeight: matches ? "Bold" : "Regular",
              }}
            >
              Bubble Protocol
            </Typography>
          </Box>
        </Box>

        <Hidden smDown>
          <Box display="flex" justifyContent="center" mt={5}>
            <AntTabs value={value} onChange={handleChange}>
              <AntTab icon={<AppsOutlinedIcon />} />
              <AntTab icon={<ViewListIcon />} />
            </AntTabs>
          </Box>
        </Hidden>
        <TabPanel value={value} index={0}>
          <Box
            display={matches ? "block" : "flex"}
            justifyContent={matches ? "" : "center"}
          >
            <Box
              my={5}
              sx={{
                background: "rgba(255, 255, 255, 0.05)",
                borderRadius: "16px",
                height: "451px",
                width: matches ? "" : "437px",
              }}
            >
              <Box
                px={2}
                pt={2}
                sx={{
                  background:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 2deg at 17.76% -92.91%, #FFFFFF 0deg, #A2B0C1 16.7deg, #F9FBFE 24.27deg, #939FAD 47.25deg, #C1D7DB 60.32deg, #C7E2DE 72deg, #D7F7EF 86.4deg, #DEFFF6 97.2deg, #EAFFF9 115.2deg, #F9FFFD 129.6deg, #FFFFFF 156.38deg, #B7C8DC 187.01deg, #E3ECFC 203.99deg, #FCFDFE 219.78deg, #F2F6FD 230.4deg, #EAF0FC 270deg, #FFFFFF 360deg)",
                  backdropFilter: "blur(13px)",
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                  height: "153px",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <Box position="relative">
                      <img
                        src={usdt}
                        style={{
                          position: "absolute",
                          left: "-22px",
                          top: "-20px",
                        }}
                        width="60px"
                        alt=""
                      />
                      <img src={bubIcon} width="56px" alt="" />
                    </Box>
                    <Box ml={1}>
                      <Typography
                        style={{
                          fontFamily: "sandro",
                          fontSize: "24px",
                          fontWeight: "Bold",
                          color: "#2F3841",
                        }}
                      >
                        BUB/USDT
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Macpaw",
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#3A5168",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={lock}
                          style={{ marginRight: "10px", marginTop: "-4px" }}
                          width="20px"
                          alt=""
                        />
                        3-12 Months
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className="calHover"
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                    sx={{
                      background: "#fff",
                      width: "40px",
                      height: "40px",
                      padding: "8px",
                      borderRadius: "8px",
                      boxShadow: "0px 7px 21px rgba(0, 0, 0, 0.07)",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#FF3E85",
                      },
                    }}
                  >
                    <BsCalculatorFill />
                  </Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Box>
                    <Typography
                      style={{
                        fontFamily: "Macpaw",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#677E96",
                      }}
                    >
                      Earn:
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Macpaw",
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "#223653",
                      }}
                    >
                      BUB + Fees
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography
                      style={{
                        fontFamily: "Macpaw",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#677E96",
                      }}
                    >
                      Leverage
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Macpaw",
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "#223653",
                      }}
                    >
                      2X-5X
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography
                      style={{
                        fontFamily: "Macpaw",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#677E96",
                      }}
                    >
                      sAPY
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Macpaw",
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "#223653",
                      }}
                    >
                      47.06%+2.31%
                    </Typography>
                  </Box>
                </Box>
                <Box p={1} mt={6} display="flex" justifyContent="space-between">
                  <Box>
                    <Typography
                      style={{
                        fontFamily: "Macpaw",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#C3C9DA",
                      }}
                    >
                      Availible
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sandro",
                        fontSize: "18px",
                        fontWeight: "Regular",
                        color: "#C3C9DA",
                      }}
                    >
                      0 LP
                    </Typography>
                  </Box>
                  <Box
                    mb={3}
                    style={{
                      width: "101px",
                      height: "40px",
                      background: "rgba(255, 255, 255, 0.05)",
                      boxShadow: "0px 7px 21px rgba(0, 0, 0, 0.07",
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "sandro",
                      fontSize: "12px",
                      fontWeight: "Regular",
                      color: "#fff",
                    }}
                  >
                    Get LP
                  </Box>
                </Box>
                <Divider style={{ background: "rgba(255, 255, 255, 0.05)" }} />
                <Box p={1} mt={2} display="flex" justifyContent="space-between">
                  <Box>
                    <Typography
                      style={{
                        fontFamily: "Macpaw",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#C3C9DA",
                      }}
                    >
                      ETH Earned
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sandro",
                        fontSize: "18px",
                        fontWeight: "Regular",
                        color: "#C3C9DA",
                      }}
                    >
                      0
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "MacPaw",
                        fontSize: "12px",
                        fontWeight: "Regular",
                        color: "#677E96",
                      }}
                    >
                      ETH/USDT Staked
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      width: "118px",
                      height: "40px",
                      background: "rgba(0, 0, 0, 0.2)",
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "sandro",
                      fontSize: "12px",
                      fontWeight: "Regular",
                      color: "#fff",
                      textTransform: "uppercase",
                    }}
                  >
                    Harvest
                  </Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-evenly">
                  <Box
                    mr={2}
                    onClick={() => {
                      setOpenDialog1(true);
                    }}
                    style={{
                      width: "186px",
                      height: "56px",
                      background: "rgba(255, 255, 255, 0.05)",
                      boxShadow: "0px 7px 21px rgba(0, 0, 0, 0.07)",
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "sandro",
                      fontSize: "14px",
                      fontWeight: "Regular",
                      color: "#fff",
                      textTransform: "uppercase",
                    }}
                  >
                    DETAILS
                  </Box>
                  <Box
                    style={{
                      width: "186px",
                      height: "56px",
                      background:
                        "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
                      boxShadow: " 0px 7px 21px rgba(243, 95, 180, 0.4",
                      borderRadius: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "sandro",
                      fontSize: "14px",
                      fontWeight: "Regular",
                      color: "#fff",
                      textTransform: "uppercase",
                    }}
                  >
                    UNLOCK
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </TabPanel>
      </Container>

      <TabPanel value={value} index={1}>
        <Hidden smDown>
          <Box pb={30} mt={10} mx={2}>
            <Box display="flex" pl={2} mb={1}>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#C3C9DA",
                  marginRight: matches1 ? "300px" : "270px",
                }}
              >
                Yield
              </Typography>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#C3C9DA",
                  marginRight: matches1 ? "100px" : "70px",
                }}
              >
                Earn
              </Typography>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#C3C9DA",
                  marginRight: matches1 ? "100px" : "70px",
                }}
              >
                Leverage
              </Typography>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#C3C9DA",
                  marginRight: matches1 ? "170px" : "130px",
                }}
              >
                sAPY
              </Typography>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#C3C9DA",
                }}
              >
                Available
              </Typography>
            </Box>

            <Box
              mb={1}
              display="flex"
              style={{
                width: "100%",
                height: "104px",
                borderRadius: "16px",
                background:
                  "linear-gradient(155.94deg, rgba(44, 112, 213, 0.1) -34.45%, rgba(255, 255, 255, 0) 55.98%), rgba(255, 255, 255, 0.05)",
              }}
            >
              <Box
                px={2}
                pt={2}
                sx={{
                  background:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 2deg at 17.76% -92.91%, #FFFFFF 0deg, #A2B0C1 16.7deg, #F9FBFE 24.27deg, #939FAD 47.25deg, #C1D7DB 60.32deg, #C7E2DE 72deg, #D7F7EF 86.4deg, #DEFFF6 97.2deg, #EAFFF9 115.2deg, #F9FFFD 129.6deg, #FFFFFF 156.38deg, #B7C8DC 187.01deg, #E3ECFC 203.99deg, #FCFDFE 219.78deg, #F2F6FD 230.4deg, #EAF0FC 270deg, #FFFFFF 360deg)",
                  backdropFilter: "blur(13px)",
                  borderTopLeftRadius: "16px",
                  borderBottomLeftRadius: "16px",
                  height: "104px",
                  width: "305px",
                  marginRight: matches1 ? "40px" : "20px",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <Box position="relative">
                      <img
                        src={usdt}
                        style={{
                          position: "absolute",
                          left: "-22px",
                          top: "-20px",
                        }}
                        width="60px"
                        alt=""
                      />
                      <img src={bubIcon} width="56px" alt="" />
                    </Box>
                    <Box ml={1}>
                      <Typography
                        style={{
                          fontFamily: "sandro",
                          fontSize: "18px",
                          fontWeight: "Bold",
                          color: "#2F3841",
                        }}
                      >
                        BUB/USDT
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Macpaw",
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#3A5168",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={lock}
                          style={{ marginRight: "10px", marginTop: "-4px" }}
                          width="20px"
                          alt=""
                        />
                        3-12 Months
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className="calHover"
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                    sx={{
                      background: "#fff",
                      width: "40px",
                      height: "40px",
                      padding: "8px",
                      borderRadius: "8px",
                      boxShadow: "0px 7px 21px rgba(0, 0, 0, 0.07)",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#FF3E85",
                      },
                    }}
                  >
                    <BsCalculatorFill />
                  </Box>
                </Box>
              </Box>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "#fff",
                  marginRight: matches1 ? "55px" : "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                BUB + Fees
              </Typography>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "#fff",
                  marginRight: matches1 ? "110px" : "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                2X-5X
              </Typography>
              <Typography
                style={{
                  fontFamily: "Macpaw",
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "#fff",
                  marginRight: matches1 ? "110px" : "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                47.06%+2.31%
              </Typography>
              <Box display="flex" alignItems="center" mr={matches1 ? 7 : 4}>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "#fff",
                    marginRight: "10px",
                  }}
                >
                  0 LP
                </Typography>
                <Box
                  style={{
                    width: "101px",
                    height: "40px",
                    background: "rgba(255, 255, 255, 0.05)",
                    boxShadow: "0px 7px 21px rgba(0, 0, 0, 0.07",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "sandro",
                    fontSize: "12px",
                    fontWeight: "Regular",
                    color: "#fff",
                  }}
                >
                  Get LP
                </Box>
              </Box>
              <Box display="flex" alignItems="center" mr={matches1 ? 7 : 4}>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "#C3C9DA",
                    marginRight: "10px",
                  }}
                >
                  0
                </Typography>
                <Box
                  style={{
                    width: "118px",
                    height: "40px",
                    background: "rgba(0, 0, 0, 0.2)",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "sandro",
                    fontSize: "12px",
                    fontWeight: "Regular",
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  Harvest
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box
                  style={{
                    width: "109px",
                    height: "40px",
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
                    boxShadow: " 0px 7px 21px rgba(243, 95, 180, 0.4",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "sandro",
                    fontSize: "12px",
                    fontWeight: "Regular",
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  UNLOCK
                </Box>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </TabPanel>
    </>
  );
}
