import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../styles/style";
import { INITIAL_ALLOWED_SLIPPAGE } from "../../../constants/index";
import { Field } from "../../../state/swap/actions";
import { ApprovalState } from "../../../hooks/useApproveCallback";
import { useSwapCallback } from "../../../hooks/useSwapCallback";
import confirmPriceImpactWithoutFee from "./confirmPriceImpactWithoutFee";
import ImportExportIcon from "@material-ui/icons/ImportExport";

import { JSBI, Token } from "moonchan-sdk";
import { useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core";
import SlippingTolrance from "../../models/Slippage";
import useWrapCallback, { WrapType } from "../../../hooks/useWrapCallback";
import { TopNav } from "../../small components/top nav/TopNav";
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from "../../../state/swap/hooks";
import { useCurrency } from "../../../hooks/Tokens";

import {
  useExpertModeManager,
  useUserDeadline,
  useUserSlippageTolerance,
} from "../../../state/user/hooks";
import tradeImg from "../../../../images/tradeImg.png";
import errorImg from "../../../../images/error.png";
import settings from "../../../../images/settings.png";
import SettingsIcon from "@material-ui/icons/Settings";

import { useApproveCallbackFromTrade } from "../../../hooks/useApproveCallback";
import maxAmountSpend from "../../../utils/maxAmountSpend";
import {
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../../utils/prices";
import CurrencyInput from "../../small components/currency input/CurrencyInput";
import TradePrice from "../../small components/swap/TradePrice";
import CustomizedButton from "../../small components/CustomButton/CustomButton";
import ConfirmSwapModal from "../../small components/swap/ConfirmSwapModal";
import AdvancedSwapDetailsDropdown from "../../small components/AdvancedSwapDetailsDropdown/AdvancedSwapDetailsDropdown";
import SwapStepper from "../../small components/Stepper/Stepper";
import { AppContext } from "../../../utils/Utils";

export const Swap = withStyles(styles)(({ classes }) => {
  // const [active, setActive] = useState(true);
  const [rotaion, setRotation] = useState(false);
  const [openSlippage, setopenSlippage] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");
  const { account, signer, connect } = useContext(AppContext);
  const loc = useLocation();
  const rotationHandler = () => {
    if (rotaion) {
      setRotation(false);
    } else {
      setRotation(true);
    }
  };
  const loadedUrlParams = useDefaultsFromURLSearch();
  // const TranslateString = useI18n();
  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ];
  const [dismissTokenWarning, setDismissTokenWarning] = useState(false);
  const [transactionWarning, setTransactionWarning] = useState({
    selectedToken: null,
    purchaseType: null,
  });
  const urlLoadedTokens = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c) => c instanceof Token
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);
  const handleConfirmWarning = () => {
    setTransactionWarning({
      selectedToken: null,
      purchaseType: null,
    });
  };

  const theme = useTheme();
  const [isExpertMode] = useExpertModeManager();
  // get custom setting values for user
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  // modal and loading
  const [
    { showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState,
  ] = useState({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );
  const noRoute = !route;
  const [openConnectModal, setopenConnectModal] = useState(false);
  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput = maxAmountSpend(currencyBalances[Field.INPUT]);
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  );

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  const handleSwap = useCallback(() => {
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee)
    ) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState((prevState) => ({
      ...prevState,
      attemptingTxn: true,
      swapErrorMessage: undefined,
      txHash: undefined,
    }));
    swapCallback()
      .then((hash) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: undefined,
          txHash: hash,
        }));
      })
      .catch((error) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: error.message,
          txHash: undefined,
        }));
      });
  }, [priceImpactWithoutFee, swapCallback, setSwapState]);

  // errors
  const [showInverted, setShowInverted] = useState(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, showConfirm: false }));

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [onUserInput, txHash, setSwapState]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }));
  }, [trade]);

  // This will check to see if the user has selected Syrup or SafeMoon to either buy or sell.
  // If so, they will be alerted with a warning message.
  const checkForWarning = useCallback(
    (selected, purchaseType) => {
      if (["SYRUP", "SAFEMOON"].includes(selected)) {
        setTransactionWarning({
          selectedToken: selected,
          purchaseType,
        });
      }
    },
    [setTransactionWarning]
  );

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
      if (inputCurrency.symbol === "SYRUP") {
        checkForWarning(inputCurrency.symbol, "Selling");
      }
      if (inputCurrency.symbol === "SAFEMOON") {
        checkForWarning(inputCurrency.symbol, "Selling");
      }
    },
    [onCurrencySelection, setApprovalSubmitted, checkForWarning]
  );

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      if (outputCurrency.symbol === "SYRUP") {
        checkForWarning(outputCurrency.symbol, "Buying");
      }
      if (outputCurrency.symbol === "SAFEMOON") {
        checkForWarning(outputCurrency.symbol, "Buying");
      }
    },
    [onCurrencySelection, checkForWarning]
  );

  return (
    <>
      <SlippingTolrance
        openSlippage={openSlippage}
        setopenSlippage={setopenSlippage}
      />
      <Box textAlign="center" pb={{ xs: 6, sm: 12, md: 6 }}>
        {/* <Typography
          style={{
            fontSize: matches ? "23px" : "50px",
            fontWeight: "800",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundImage: "linear-gradient(60deg, #E6B100 0%, #fff 100%)",
          }}
          gutterBottom
        >
          Trade
        </Typography> */}
        {/* <img width={matches ? "200px" : "350px"} src={tradeImg} alt="" /> */}
      </Box>
      <Container maxWidth="sm">
        <Box className={classes.tradeContainer} align="center">
          <Box className={classes.ExchangeBox}>
            <ConfirmSwapModal
              isOpen={showConfirm}
              trade={trade}
              originalTrade={tradeToConfirm}
              onAcceptChanges={handleAcceptChanges}
              attemptingTxn={attemptingTxn}
              txHash={txHash}
              recipient={recipient}
              allowedSlippage={allowedSlippage}
              onConfirm={handleSwap}
              swapErrorMessage={swapErrorMessage}
              onDismiss={handleConfirmDismiss}
            />
            <Box position="relative">
              <Box
                position="absolute"
                left="0rem"
                right="0rem"
                top={{ xs: "-1.8rem", sm: "-2.3rem" }}
              >
                {/* <img src={boxheaderimg} alt="" width="30%" /> */}
              </Box>
            </Box>
            {/* <Box position="relative">
              <Box
                position="absolute"
                right={{ xs: "-5rem", sm: "-2rem" }}
                top="-2.2rem"
              >
                <img
                  style={{}}
                  src={stylelines}
                  alt=""
                  width={matches ? "60%" : "60%"}
                />
              </Box>
            </Box> */}
            {/* <Box position="relative">
              <Box
                position="absolute"
                right={{ xs: "-8rem", sm: "-9rem" }}
                top={{ xs: "-3.5rem", sm: "-4.2rem" }}
              >
                <img
                  style={{}}
                  src={logo}
                  alt=""
                  width={matches ? "20%" : "20%"}
                />
              </Box>
            </Box> */}
            <Box
              sx={{
                cursor: "pointer",
              }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Box
                display="flex"
                alignItems="center"
                fontFamily="Roboto"
                fontWeight="Bold"
                color="#fff"
                fontSize="18px"
              >
                Swap Tokens
              </Box>
              <Box
                display="flex"
                alignItems="center"
                fontFamily="Roboto"
                fontWeight="Light"
                color="#fff"
                fontSize="15px"
                mr={1}
                onClick={() => setopenSlippage(true)}
              >
                {" "}
                Settings <SettingsIcon style={{ color: "#fff" }} />
                {/* <img
                  width="15px"
                  src={settings}
                  alt=""
                  style={{ marginLeft: "5px" }}
                /> */}
              </Box>
            </Box>

            <Grid order={2}>
              <CurrencyInput
                label={
                  independentField === Field.OUTPUT && !showWrap && trade
                    ? "From (estimated)"
                    : "From"
                }
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={!atMaxAmountInput}
                currency={currencies[Field.INPUT]}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                id="swap-currency-input"
              />
            </Grid>
            <Grid>
              <Box mt={3} mb={3}>
                <Box className={classes.Middline}>
                  <Box
                    width="50px"
                    height="30px"
                    // bgcolor={theme.palette.primary.main}
                    position="absolute"
                    top="-1rem"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box className={classes.circleBox}>
                      <ImportExportIcon
                        onClick={() => {
                          setApprovalSubmitted(false); // reset 2 step UI for approvals
                          onSwitchTokens();
                        }}
                        // transform: "rotate(180deg)"
                        style={{
                          margin: ".1rem",
                          transform:
                            rotaion === true
                              ? "rotate(360deg)"
                              : "rotate(0deg)",
                          color: "#000",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid order={2}>
              <CurrencyInput
                value={formattedAmounts[Field.OUTPUT]}
                onUserInput={handleTypeOutput}
                label={
                  independentField === Field.INPUT && !showWrap && trade
                    ? "To (estimated)"
                    : "To"
                }
                showMaxButton={false}
                currency={currencies[Field.OUTPUT]}
                onCurrencySelect={handleOutputSelect}
                otherCurrency={currencies[Field.INPUT]}
                id="swap-currency-output"
              />
            </Grid>
            {recipient !== null && !showWrap ? (
              <>
                <Box justify="space-between" style={{ padding: "0 1rem" }}>
                  <Box clickable={false}>
                    <Box size="16" color="primary.contrastText" />
                  </Box>
                  <CustomizedButton
                    id="remove-recipient-button"
                    fun={() => onChangeRecipient(null)}
                  >
                    - Remove send
                  </CustomizedButton>
                </Box>
                <input
                  id="recipient"
                  value={recipient}
                  onChange={onChangeRecipient}
                />
              </>
            ) : null}

            <Box mt={2}>
              {!account ? (
                <CustomizedButton fun={() => connect()}>
                  Connect Wallet
                </CustomizedButton>
              ) : showWrap ? (
                <CustomizedButton
                  disabled={Boolean(wrapInputError)}
                  fun={onWrap}
                  width="100%"
                  color="secondary"
                  variant="contained"
                >
                  {wrapInputError ??
                    (wrapType === WrapType.WRAP
                      ? "Wrap"
                      : wrapType === WrapType.UNWRAP
                      ? "Unwrap"
                      : null)}
                </CustomizedButton>
              ) : noRoute && userHasSpecifiedInputOutput ? (
                <Box style={{ textAlign: "center" }}>
                  <Box
                    fontFamily="Roboto"
                    fontWeight="Bold"
                    mb="4px"
                    color={"#fff"}
                    bgcolor="rgba(0, 0, 0, 0.05)"
                    py={2}
                    borderRadius="16px"
                  >
                    <img
                      src={errorImg}
                      width="15px"
                      alt=""
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Insufficient liquidity for this trade.
                  </Box>
                </Box>
              ) : showApproveFlow ? (
                <Box>
                  <CustomizedButton
                    fun={approveCallback}
                    disabled={
                      approval !== ApprovalState.NOT_APPROVED ||
                      approvalSubmitted
                    }
                  >
                    {approval === ApprovalState.PENDING ? (
                      <Box
                        width="100%"
                        display="flex"
                        gap="6px"
                        justifyContent="center"
                      >
                        Approving{" "}
                        <CircularProgress size={20} color="secondary" />
                      </Box>
                    ) : approvalSubmitted &&
                      approval === ApprovalState.APPROVED ? (
                      "Approved"
                    ) : (
                      `Approve ${currencies[Field.INPUT]?.symbol}`
                    )}
                  </CustomizedButton>
                  <CustomizedButton
                    fun={() => {
                      if (isExpertMode) {
                        handleSwap();
                      } else {
                        setSwapState({
                          tradeToConfirm: trade,
                          attemptingTxn: false,
                          swapErrorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined,
                        });
                      }
                    }}
                    id="swap-button"
                    disabled={
                      !isValid ||
                      approval !== ApprovalState.APPROVED ||
                      (priceImpactSeverity > 3 && !isExpertMode)
                    }
                  >
                    {priceImpactSeverity > 3 && !isExpertMode
                      ? `Price Impact High`
                      : `Swap${priceImpactSeverity > 2 ? " Anyway" : ""}`}
                  </CustomizedButton>
                </Box>
              ) : (
                /* base */

                <CustomizedButton
                  fun={() => {
                    if (isExpertMode) {
                      handleSwap();
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined,
                      });
                    }
                  }}
                  id="swap-button"
                  disabled={
                    !isValid ||
                    (priceImpactSeverity > 3 && !isExpertMode) ||
                    !!swapCallbackError
                  }
                >
                  {swapInputError ||
                    (priceImpactSeverity > 3 && !isExpertMode
                      ? `Price Impact Too High`
                      : `Swap${priceImpactSeverity > 2 ? " Anyway" : ""}`)}
                </CustomizedButton>
              )}
              {/* <Button color="secondary" variant="contained" fullWidth>
                BUY BNBULL SWAP=
              </Button> */}
              {showApproveFlow && (
                <SwapStepper steps={[approval === ApprovalState.APPROVED]} />
              )}
            </Box>
            {console.log(showWrap, "showWrap")}

            <Box
              display="flex"
              alignItems="center"
              flexDirection={matches ? "column" : "row"}
              justifyContent={trade ? "space-between" : "center"}
              flexWrap="wrap"
            >
              {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt={2}
                >
                  <Box
                    fontFamily="Roboto"
                    fontWeight="Light"
                    fontSize="11px"
                    color={"#fff"}
                  >
                    Slippage Tolerance
                  </Box>
                  <Box
                    fontFamily="Roboto"
                    fontWeight="Light"
                    color={"#fff"}
                    fontSize="11px"
                    ml={1}
                  >
                    {allowedSlippage / 100}%
                  </Box>
                </Box>
              )}
              {console.log(trade, "trade")}
              {trade && (
                <>
                  {showWrap ? null : (
                    <Box padding=".25rem .75rem 0 .75rem" borderRadius="20px">
                      <Box>
                        {Boolean(trade) && (
                          <Box mt={1} display="flex" alignItems="center">
                            <Box
                              fontFamily="Roboto"
                              fontWeight="Light"
                              color={theme.palette.common.black}
                              fontSize="14px"
                              mr={1}
                            >
                              Price
                            </Box>
                            <TradePrice
                              price={trade?.executionPrice}
                              showInverted={showInverted}
                              setShowInverted={setShowInverted}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* 
            <Box position="relative" pb={4}>
              <Box
                position="absolute"
                left="-2rem"
                // top="0rem"
                // bottom="0rem"
                top={{ xs: "-2rem", sm: "-1.5rem" }}
              >
                <img src={Line} alt="" width="100%" />
              </Box>
            </Box> */}
          </Box>
          <AdvancedSwapDetailsDropdown trade={trade} />
        </Box>
      </Container>
    </>
  );
});
