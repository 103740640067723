import {
  Box,
  Dialog,
  Divider,
  Slide,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import dialog from "../../../images/Dialog.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StyledDialog = withStyles({
  paper: {
    background: "#F1F5FD",
    borderRadius: "16px",
    height: "430px",
    width: "392px",
  },
})(Dialog);
export default function RoiCalculatorModal({
  openDialog,
  setOpenDialog,
  poolRewardPercentage,
  userEnterdAmount,
}) {
  const [oneDayPer, setoneDayPer] = useState(0);
  const [sevenDaysPer, setsevenDaysPer] = useState(0);
  const [thirtyDaysPer, setthirtyDaysPer] = useState(0);
  const [oneYearPer, setoneYearPer] = useState(0);
  const [oneDayReward, setoneDayReward] = useState(0);
  const [sevenDaysReward, setsevenDaysReward] = useState(0);
  const [thirtyDaysReward, setthirtyDaysReward] = useState(0);
  const [oneYearReward, setoneYearReward] = useState(0);

  const init = async () => {
    try {
      if (+userEnterdAmount > 0) {
        let oneDayPercentage = +poolRewardPercentage / 30;
        let oneDay = (+userEnterdAmount * +oneDayPercentage) / 100;
        setoneDayReward(+oneDay);
        setoneDayPer(oneDayPercentage);
        let sevenDay = +oneDay * 7;
        setsevenDaysReward(+sevenDay);
        setsevenDaysPer(+oneDayPercentage * 7);
        let thirtyDay = +oneDay * 30;
        setthirtyDaysReward(thirtyDay);
        setthirtyDaysPer(+oneDayPercentage * 30);
        let oneYear = +oneDay * 365;
        setoneYearReward(oneYear);
        setoneYearPer(+oneDayPercentage * 365);
      }
    } catch (error) {}
  };
  useEffect(() => {
    init();
  }, [userEnterdAmount, poolRewardPercentage]);
  return (
    <StyledDialog open={openDialog} TransitionComponent={Transition}>
      <Box
        style={{
          borderRadius: "10px",
          height: "100%",
        }}
      >
        <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          style={{
            background: `url(${dialog})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontFamily: "sandro",
              fontWeight: "Bold",
              color: "#fff",
              textTransform: "uppercase",
            }}
          >
            ROI Calculator
          </Typography>
          <CloseIcon
            style={{
              cursor: "pointer",
              color: "#C3C9DA ",
            }}
            onClick={() => {
              setOpenDialog(false);
            }}
          />
        </Box>
        <Box px={2} py={2} bgcolor="#ffffff">
          <Box display="flex" justifyContent="space-between">
            <Typography
              style={{
                color: "#677E96",
                fontFamily: "Macpaw",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              Days timeframe
            </Typography>
            <Typography
              style={{
                color: "#677E96",
                fontFamily: "Macpaw",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              ROI
            </Typography>
            <Typography
              style={{
                color: "#677E96",
                fontFamily: "Macpaw",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              $BUB per ${+userEnterdAmount}
            </Typography>
          </Box>
          <Divider style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }} />
          <Box display="flex" justifyContent="space-between" mt={1.5}>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              1
            </Typography>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              {parseFloat(oneDayPer).toFixed(4)}%
            </Typography>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              {parseFloat(oneDayReward).toFixed(4)}
            </Typography>
          </Box>
          <Divider style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }} />
          <Box display="flex" justifyContent="space-between" mt={1.5}>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              7
            </Typography>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              {parseFloat(sevenDaysPer).toFixed(4)}%
            </Typography>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              {parseFloat(sevenDaysReward).toFixed(4)}
            </Typography>
          </Box>
          <Divider style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }} />
          <Box display="flex" justifyContent="space-between" mt={1.5}>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              30
            </Typography>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              {parseFloat(thirtyDaysPer).toFixed(4)}%
            </Typography>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              {parseFloat(thirtyDaysReward).toFixed(4)}
            </Typography>
          </Box>
          <Divider style={{ backgroundColor: "#C3C9DA", marginTop: "15px" }} />
          <Box display="flex" justifyContent="space-between" mt={1.5}>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              365 (APY)
            </Typography>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              {parseFloat(oneYearPer).toFixed(4)}%
            </Typography>
            <Typography
              style={{
                color: "#3A5168",
                fontFamily: "Macpaw",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              {parseFloat(oneYearReward).toFixed(4)}
            </Typography>
          </Box>
          <Typography
            style={{
              color: "#3A5168",
              fontFamily: "Macpaw",
              fontSize: "12px",
              fontWeight: "400",
              marginTop: "20px",
            }}
          >
            Calculated based on current rates. Compounding 1x daily. Rates are
            estimates provided for your convenience only, and by no means
            represent guaranteed returns.
          </Typography>
          <Box
            mt={2}
            style={{
              color: "#ff3e85",
              border: "2px solid #ff3e85",
              height: "40px",
              width: "100%",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "sandro",
              fontSize: "12px",
              fontWeight: "Bold",
            }}
          >
            Get BUB-ETH LP
          </Box>
        </Box>
      </Box>
    </StyledDialog>
  );
}
