import React, { useCallback, useContext, useMemo, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { Box, Container, Dialog, useMediaQuery } from "@material-ui/core";
import TransectionModal from "../../models/TransectionSubmittedModal";
import { styles } from "../styles/style";
import { Link } from "react-router-dom";
import CurrencyLogo from "../../small components/CurrencyLogo/CurrencyLogo";
import theme from "../../../../theme";
import { LiquidityTopNav } from "../../small components/box header/ModalHeader";
import { useCurrency } from "../../../hooks/Tokens";

import { wrappedCurrency } from "../../../utils/wrappedCurrency";
import { Field } from "../../../state/burn/actions";
import useDebouncedChangeHandler from "../../../utils/useDebouncedChangeHandler";
import { Button, CircularProgress } from "@material-ui/core";

import {
  useBurnActionHandlers,
  useDerivedBurnInfo,
  useBurnState,
} from "../../../state/burn/hooks";
import {
  useUserDeadline,
  useUserSlippageTolerance,
} from "../../../state/user/hooks";
import { usePairContract } from "../../../hooks/useContract";
import {
  ApprovalState,
  useApproveCallback,
} from "../../../hooks/useApproveCallback";
import CurrencyInput from "../../small components/currency input/CurrencyInput";
import { ROUTER_ADDRESS } from "../../../constants";
import { splitSignature } from "@ethersproject/bytes";
import { useTransactionAdder } from "../../../state/transactions/hooks";
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getRouterContract,
} from "../../../utils";
import { currencyEquals, ETHER, Percent, WETH } from "moonchan-sdk";
import { BigNumber } from "@ethersproject/bignumber";
import { useTheme } from "@material-ui/core";
import currencyId from "../../../utils/currencyId";
import DoubleCurrencyLogo from "../../small components/DoubleCurrencyLogo/DoubleCurrencyLogo";
import CustomizedButton from "../../small components/CustomButton/CustomButton";
import LPTokensBox from "../../small components/LPtokens box/LPTokensBox";
import { AppContext } from "../../../utils/Utils";

const PrettoSlider = withStyles({
  root: {
    color: theme.palette.secondary.main,
    height: 8,
    width: "95%",
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    color: theme.palette.secondary.main,
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

export const RemoveLiquidity = withStyles(styles)(
  ({
    history,
    match: {
      params: { currencyIdA, currencyIdB },
    },
    classes,
  }) => {
    const matches = useMediaQuery("(max-width:600px)");
    const [currencyA, currencyB] = [
      useCurrency(currencyIdA) ?? undefined,
      useCurrency(currencyIdB) ?? undefined,
    ];

    const handleClose = () => {
      setShowConfirm(false);
    };
    const { account, signer, web3Provider, chainId, connect } =
      useContext(AppContext);

    // const TranslateString = useI18n();
    const [tokenA, tokenB] = useMemo(
      () => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ],
      [currencyA, currencyB, chainId]
    );

    const theme = useTheme();
    // burn state
    const { independentField, typedValue } = useBurnState();
    const { pair, parsedAmounts, error } = useDerivedBurnInfo(
      currencyA ?? undefined,
      currencyB ?? undefined
    );
    const { onUserInput: _onUserInput } = useBurnActionHandlers();
    const isValid = !error;

    // modal and loading
    const [showConfirm, setShowConfirm] = useState(false);
    const [showDetailed, setShowDetailed] = useState(false);
    const [attemptingTxn, setAttemptingTxn] = useState(false); // clicked confirm

    // txn values
    const [txHash, setTxHash] = useState("");
    const [deadline] = useUserDeadline();
    const [allowedSlippage] = useUserSlippageTolerance();

    const formattedAmounts = {
      [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo(
        "0"
      )
        ? "0"
        : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(
            new Percent("1", "100")
          )
        ? "<1"
        : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
      [Field.LIQUIDITY]:
        independentField === Field.LIQUIDITY
          ? typedValue
          : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? "",
      [Field.CURRENCY_A]:
        independentField === Field.CURRENCY_A
          ? typedValue
          : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? "",
      [Field.CURRENCY_B]:
        independentField === Field.CURRENCY_B
          ? typedValue
          : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? "",
    };

    const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(
      new Percent("1")
    );

    // pair contract
    const pairContract = usePairContract(pair?.liquidityToken?.address);

    // allowance handling
    const [signatureData, setSignatureData] = useState(null);
    const [approval, approveCallback] = useApproveCallback(
      parsedAmounts[Field.LIQUIDITY],
      ROUTER_ADDRESS
    );
    const onAttemptToApprove = async () => {
      if (!pairContract || !pair || !web3Provider)
        throw new Error("missing dependencies");
      const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
      if (!liquidityAmount) throw new Error("missing liquidity amount");
      // try to gather a signature for permission
      const nonce = await pairContract.nonces(account);

      const deadlineForSignature = Math.ceil(Date.now() / 1000) + deadline;

      const EIP712Domain = [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ];
      const domain = {
        name: "Solarswap LPs",
        version: "1",
        chainId,
        verifyingContract: pair.liquidityToken.address,
      };
      const Permit = [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "value", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ];
      const message = {
        owner: account,
        spender: ROUTER_ADDRESS,
        value: liquidityAmount.raw.toString(),
        nonce: nonce.toHexString(),
        deadline: deadlineForSignature,
      };
      const data = JSON.stringify({
        types: {
          EIP712Domain,
          Permit,
        },
        domain,
        primaryType: "Permit",
        message,
      });

      web3Provider
        .send("eth_signTypedData_v4", [account, data])
        .then(splitSignature)
        .then((signature) => {
          setSignatureData({
            v: signature.v,
            r: signature.r,
            s: signature.s,
            deadline: deadlineForSignature,
          });
        })
        .catch((e) => {
          // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
          if (e?.code !== 4001) {
            approveCallback();
          }
        });
    };

    // wrapped onUserInput to clear signatures
    const onUserInput = useCallback(
      (field, val) => {
        setSignatureData(null);
        return _onUserInput(field, val);
      },
      [_onUserInput]
    );

    const onLiquidityInput = useCallback(
      (val) => onUserInput(Field.LIQUIDITY, val),
      [onUserInput]
    );
    const onCurrencyAInput = useCallback(
      (val) => onUserInput(Field.CURRENCY_A, val),
      [onUserInput]
    );
    const onCurrencyBInput = useCallback(
      (val) => onUserInput(Field.CURRENCY_B, val),
      [onUserInput]
    );

    // tx sending
    const addTransaction = useTransactionAdder();
    const onRemove = async () => {
      if (!chainId || !signer || !account)
        throw new Error("missing dependencies");
      const {
        [Field.CURRENCY_A]: currencyAmountA,
        [Field.CURRENCY_B]: currencyAmountB,
      } = parsedAmounts;
      if (!currencyAmountA || !currencyAmountB) {
        throw new Error("missing currency amounts");
      }
      const router = getRouterContract(chainId, signer, account);

      const amountsMin = {
        [Field.CURRENCY_A]: calculateSlippageAmount(
          currencyAmountA,
          allowedSlippage
        )[0],
        [Field.CURRENCY_B]: calculateSlippageAmount(
          currencyAmountB,
          allowedSlippage
        )[0],
      };

      if (!currencyA || !currencyB) throw new Error("missing tokens");
      const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
      if (!liquidityAmount) throw new Error("missing liquidity amount");

      const currencyBIsETH = currencyB === ETHER;
      const oneCurrencyIsETH = currencyA === ETHER || currencyBIsETH;
      const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;

      if (!tokenA || !tokenB) throw new Error("could not wrap");

      let methodNames;
      let args;
      // we have approval, use normal remove liquidity
      if (approval === ApprovalState.APPROVED) {
        // removeLiquidityETH
        if (oneCurrencyIsETH) {
          methodNames = [
            "removeLiquidityETH",
            "removeLiquidityETHSupportingFeeOnTransferTokens",
          ];
          args = [
            currencyBIsETH ? tokenA.address : tokenB.address,
            liquidityAmount.raw.toString(),
            amountsMin[
              currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
            ].toString(),
            amountsMin[
              currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
            ].toString(),
            account,
            deadlineFromNow,
          ];
        }
        // removeLiquidity
        else {
          methodNames = ["removeLiquidity"];
          args = [
            tokenA.address,
            tokenB.address,
            liquidityAmount.raw.toString(),
            amountsMin[Field.CURRENCY_A].toString(),
            amountsMin[Field.CURRENCY_B].toString(),
            account,
            deadlineFromNow,
          ];
        }
      }
      // we have a signataure, use permit versions of remove liquidity
      else if (signatureData !== null) {
        // removeLiquidityETHWithPermit
        if (oneCurrencyIsETH) {
          methodNames = [
            "removeLiquidityETHWithPermit",
            "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
          ];
          args = [
            currencyBIsETH ? tokenA.address : tokenB.address,
            liquidityAmount.raw.toString(),
            amountsMin[
              currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
            ].toString(),
            amountsMin[
              currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
            ].toString(),
            account,
            signatureData.deadline,
            false,
            signatureData.v,
            signatureData.r,
            signatureData.s,
          ];
        }
        // removeLiquidityETHWithPermit
        else {
          methodNames = ["removeLiquidityWithPermit"];
          args = [
            tokenA.address,
            tokenB.address,
            liquidityAmount.raw.toString(),
            amountsMin[Field.CURRENCY_A].toString(),
            amountsMin[Field.CURRENCY_B].toString(),
            account,
            signatureData.deadline,
            false,
            signatureData.v,
            signatureData.r,
            signatureData.s,
          ];
        }
      } else {
        throw new Error(
          "Attempting to confirm without approval or a signature. Please contact support."
        );
      }
      const safeGasEstimates = await Promise.all(
        methodNames.map((methodName, index) =>
          router.estimateGas[methodName](...args)
            .then(calculateGasMargin)
            .catch((e) => {
              console.error(`estimateGas failed`, index, methodName, args, e);
              return undefined;
            })
        )
      );

      const indexOfSuccessfulEstimation = safeGasEstimates.findIndex(
        (safeGasEstimate) => BigNumber.isBigNumber(safeGasEstimate)
      );

      // all estimations failed...
      if (indexOfSuccessfulEstimation === -1) {
        console.error("This transaction would fail. Please contact support.");
      } else {
        const methodName = methodNames[indexOfSuccessfulEstimation];
        const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation];

        setAttemptingTxn(true);
        await router[methodName](...args, {
          gasLimit: safeGasEstimate,
        })
          .then((response) => {
            setAttemptingTxn(false);

            addTransaction(response, {
              summary: `Remove ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(
                3
              )} ${currencyA?.symbol} and ${parsedAmounts[
                Field.CURRENCY_B
              ]?.toSignificant(3)} ${currencyB?.symbol}`,
            });

            setTxHash(response.hash);
          })
          .catch((e) => {
            setAttemptingTxn(false);
            // we only care if the error is something _other_ than the user rejected the tx
            console.error(e);
          });
      }
    };

    const pendingText = `Removing ${parsedAmounts[
      Field.CURRENCY_A
    ]?.toSignificant(6)} ${currencyA?.symbol} and ${parsedAmounts[
      Field.CURRENCY_B
    ]?.toSignificant(6)} ${currencyB?.symbol}`;

    const liquidityPercentChangeCallback = useCallback(
      (value) => {
        onUserInput(Field.LIQUIDITY_PERCENT, value.toString());
      },
      [onUserInput]
    );

    const oneCurrencyIsETH = currencyA === ETHER || currencyB === ETHER;
    const oneCurrencyIsWETH = Boolean(
      chainId &&
        ((currencyA && currencyEquals(WETH[chainId], currencyA)) ||
          (currencyB && currencyEquals(WETH[chainId], currencyB)))
    );

    const handleSelectCurrencyA = useCallback(
      (currency) => {
        if (currencyIdB && currencyId(currency) === currencyIdB) {
          history.push(`/remove/${currencyId(currency)}/${currencyIdA}`);
        } else {
          history.push(`/remove/${currencyId(currency)}/${currencyIdB}`);
        }
      },
      [currencyIdA, currencyIdB, history]
    );
    const handleSelectCurrencyB = useCallback(
      (currency) => {
        if (currencyIdA && currencyId(currency) === currencyIdA) {
          history.push(`/remove/${currencyIdB}/${currencyId(currency)}`);
        } else {
          history.push(`/remove/${currencyIdA}/${currencyId(currency)}`);
        }
      },
      [currencyIdA, currencyIdB, history]
    );

    const handleDismissConfirmation = useCallback(() => {
      setShowConfirm(false);
      setSignatureData(null); // important that we clear signature data to avoid bad sigs
      // if there was a tx hash, we want to clear the input
      if (txHash) {
        onUserInput(Field.LIQUIDITY_PERCENT, "0");
      }
      setTxHash("");
    }, [onUserInput, txHash]);

    const [innerLiquidityPercentage, setInnerLiquidityPercentage] =
      useDebouncedChangeHandler(
        Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
        liquidityPercentChangeCallback
      );
    const RemoveLiqConfirmModalHeader = (
      <>
        <div
          style={{
            textAlign: "center",
            width: matches ? "auto" : "400px",
          }}
        >
          <Box
            mb={5}
            paddingLeft="20px"
            paddingRight="20px"
            paddingTop="25px"
            display="flex"
            bgcolor={"#ED2196"}
            justifyContent="space-between"
            pb="20px"
          >
            <Box
              color={theme.palette.secondary.contrastText}
              fontSize="18px"
              textAlign="left"
              fontWeight="Bold"
              fontFamily="Sandro"
            >
              You will receive
            </Box>
            <Box>
              <i
                onClick={handleClose}
                style={{
                  color: theme.palette.secondary.contrastText,
                  textAlign: "right",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
                className="fal fa-times"
              ></i>
            </Box>
          </Box>
          <Box
            mt={3}
            px={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "16px"}
              textAlign="center"
              fontWeight="Light"
              fontFamily="Sandro"
            >
              {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "16px"}
              textAlign="center"
              fontWeight="Light"
              fontFamily="Sandro"
            >
              <CurrencyLogo currency={currencyA} size="24px" />
              {currencyA?.symbol}
            </Box>
          </Box>
          <Box
            px={3}
            my={1}
            color={theme.palette.common.black}
            fontSize={matches ? "12px" : "16px"}
            textAlign="left"
            fontWeight="Light"
            fontFamily="Sandro"
          >
            +
          </Box>
          <Box
            px={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "15px"}
              textAlign="center"
              fontWeight="Light"
              fontFamily="Sandro"
            >
              {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "15px"}
              textAlign="center"
              fontWeight="Light"
              fontFamily="Sandro"
            >
              <CurrencyLogo currency={currencyB} size="24px" />{" "}
              {currencyB?.symbol}
            </Box>
          </Box>
          <Box
            px={3}
            my={3}
            color={theme.palette.common.black}
            fontSize={matches ? "11px" : "14px"}
            textAlign="center"
            fontWeight="Light"
            fontFamily="Sandro"
          >
            Output is estimated. If the price changes by more than 0.8% your
            transaction will revert.
          </Box>
        </div>
      </>
    );

    const RemoveLiqConfirmModalBottom = (
      <>
        <div
          style={{
            textAlign: "center",
            paddingBottom: "15x",
            width: matches ? "auto" : "400px",
          }}
        >
          <Box
            px={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "15px"}
              textAlign="center"
              fontWeight="Light"
              fontFamily="Sandro"
            >
              {`LP ${currencyA?.symbol}/${currencyB?.symbol}`} Burned
            </Box>
            <Box
              display="flex"
              alignItems="center"
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "15px"}
              textAlign="center"
              fontWeight="Light"
              fontFamily="Sandro"
            >
              <DoubleCurrencyLogo
                currency0={currencyA}
                currency1={currencyB}
                margin
              />
              {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
            </Box>
          </Box>
          {pair && (
            <>
              <Box
                my={1}
                px={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  color={theme.palette.common.black}
                  fontSize={matches ? "12px" : "15px"}
                  textAlign="center"
                  fontWeight="Light"
                  fontFamily="Sandro"
                >
                  Price
                </Box>
                <Box
                  color={theme.palette.common.black}
                  fontSize={matches ? "12px" : "15px"}
                  textAlign="center"
                  fontWeight="Light"
                  fontFamily="Sandro"
                >
                  1 {currencyA?.symbol} =
                  {tokenA ? pair.priceOf(tokenA).toSignificant(6) : "-"}
                  {currencyB?.symbol}
                </Box>
              </Box>
              <Box
                px={3}
                mb={4}
                color={theme.palette.common.black}
                fontSize={matches ? "12px" : "15px"}
                textAlign="right"
                fontWeight="Light"
                fontFamily="Sandro"
              >
                1 {currencyB?.symbol} =
                {tokenB ? pair.priceOf(tokenB).toSignificant(6) : "-"}
                {currencyA?.symbol}
              </Box>
            </>
          )}
          <CustomizedButton
            disabled={
              !(approval === ApprovalState.APPROVED || signatureData !== null)
            }
            fun={onRemove}
          >
            Confirm
          </CustomizedButton>
        </div>
      </>
    );

    return (
      <Container maxWidth="sm">
        <Box
          mt={0}
          className={classes.tradeContainer}
          align="center"
          textAlign="center"
        >
          <Box
            style={{
              width: matches ? "100%" : "80%",
              height: "auto",
              backgroundColor: "#02052c",
              margin: "2rem auto",
              borderRadius: 20,
              overflow: "hidden",
              border: "1px solid",
              borderColor: theme.palette.secondary.main,
            }}
          >
            <Box pl={2} pr={1} py={2}>
              <LiquidityTopNav />
            </Box>
            <TransectionModal
              isOpen={showConfirm}
              onDismiss={handleDismissConfirmation}
              attemptingTxn={attemptingTxn}
              hash={txHash || ""}
              modalBottom={RemoveLiqConfirmModalBottom}
              modalHeader={RemoveLiqConfirmModalHeader}
              pendingText={pendingText}
            />
            <Box
              my={2}
              mx={2}
              p={matches ? 1 : 2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              borderRadius="15px"
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: theme.palette.secondary.main,
              }}
            >
              <Box width="100%" display="flex" justifyContent="space-between">
                <Box
                  fontWeight="Light"
                  fontFamily="Sandro"
                  fontSize="15px"
                  color={theme.palette.common.black}
                >
                  Amount
                </Box>
                <Box>
                  <a
                    style={{
                      textDecoration: "none",
                      fontWeight: "Light",
                      fontFamily: "Sandro",
                      fontSize: "15px",
                      color: theme.palette.common.black,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowDetailed(!showDetailed);
                    }}
                  >
                    {showDetailed ? "Simple" : "Detailed"}
                  </a>
                </Box>
              </Box>
              <Box
                mt={1}
                fontWeight="Light"
                fontFamily="Sandro"
                fontSize="25px"
                textAlign="left"
                color={theme.palette.common.black}
              >
                {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
              </Box>

              <PrettoSlider
                ThumbComponent={AirbnbThumbComponent}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                value={innerLiquidityPercentage}
                onChange={(e, v) => setInnerLiquidityPercentage(v)}
              />
              {!showDetailed && (
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  mt={1}
                >
                  <Box
                    fontWeight="Light"
                    fontFamily="Sandro"
                    fontSize="14px"
                    borderRadius="25px"
                    px={2}
                    py={1}
                    bgcolor={theme.palette.secondary.main}
                    color={theme.palette.secondary.contrastText}
                    sx={{ cursor: "pointer" }}
                    onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, "25")}
                  >
                    25%
                  </Box>
                  <Box
                    fontWeight="Light"
                    fontFamily="Sandro"
                    fontSize="14px"
                    borderRadius="25px"
                    px={2}
                    py={1}
                    bgcolor={theme.palette.secondary.main}
                    color={theme.palette.secondary.contrastText}
                    onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, "50")}
                    sx={{ cursor: "pointer" }}
                  >
                    50%
                  </Box>
                  <Box
                    fontWeight="Light"
                    fontFamily="Sandro"
                    fontSize="14px"
                    borderRadius="25px"
                    px={2}
                    py={1}
                    bgcolor={theme.palette.secondary.main}
                    color={theme.palette.secondary.contrastText}
                    onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, "75")}
                    sx={{ cursor: "pointer" }}
                  >
                    75%
                  </Box>
                  <Box
                    fontWeight="Light"
                    fontFamily="Sandro"
                    fontSize="14px"
                    px={2}
                    py={1}
                    borderRadius="25px"
                    bgcolor={theme.palette.secondary.main}
                    color={theme.palette.secondary.contrastText}
                    onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, "100")}
                    sx={{ cursor: "pointer" }}
                  >
                    Max
                  </Box>
                </Box>
              )}
            </Box>
            {!showDetailed && (
              <>
                <Box>
                  <i
                    style={{
                      color: theme.palette.secondary.contrastText,
                      textAlign: "center",
                      fontSize: "25px",
                    }}
                    className="far fa-arrow-down"
                  ></i>
                </Box>
                <Box
                  my={2}
                  mx={2}
                  p={2}
                  display="flex"
                  flexDirection="column"
                  borderRadius="15px"
                  style={{
                    border: "1px solid",
                    borderColor: theme.palette.secondary.main,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      fontWeight="Light"
                      fontFamily="Sandro"
                      fontSize="14px"
                      color={theme.palette.common.black}
                    >
                      {formattedAmounts[Field.CURRENCY_A] || "-"}
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      fontWeight="Light"
                      fontFamily="Sandro"
                      fontSize="15px"
                      color={theme.palette.common.black}
                    >
                      <CurrencyLogo currency={currencyA} size={20} />
                      {currencyA?.symbol}
                    </Box>
                  </Box>
                  <Box
                    mt={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      fontWeight="Light"
                      fontFamily="Sandro"
                      fontSize="15px"
                      color={theme.palette.common.black}
                    >
                      {formattedAmounts[Field.CURRENCY_B] || "-"}
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      fontSize="15px"
                      fontWeight="Light"
                      fontFamily="Sandro"
                      color={theme.palette.common.black}
                    >
                      <CurrencyLogo currency={currencyB} size={20} />{" "}
                      {currencyB?.symbol}
                    </Box>
                  </Box>
                  {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                    <Box
                      mt={1}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      {oneCurrencyIsETH ? (
                        <Box
                          style={{
                            textDecoration: "none",
                            fontFamily: "Sandro",
                            fontSize: "15px",
                            fontWeight: "Bold",
                            color: theme.palette.secondary.main,
                          }}
                          component={Link}
                          to={`/remove/${
                            currencyA === ETHER
                              ? WETH[chainId].address
                              : currencyIdA
                          }/${
                            currencyB === ETHER
                              ? WETH[chainId].address
                              : currencyIdB
                          }`}
                        >
                          Receive WETH
                        </Box>
                      ) : oneCurrencyIsWETH ? (
                        <Box
                          style={{
                            textDecoration: "none",
                            fontFamily: "Sandro",
                            fontSize: "15px",
                            fontWeight: "Bold",
                            color: theme.palette.secondary.main,
                          }}
                          component={Link}
                          to={`/remove/${
                            currencyA &&
                            currencyEquals(currencyA, WETH[chainId])
                              ? "ETH"
                              : currencyIdA
                          }/${
                            currencyB &&
                            currencyEquals(currencyB, WETH[chainId])
                              ? "ETH"
                              : currencyIdB
                          }`}
                        >
                          Receive ETH
                        </Box>
                      ) : null}
                    </Box>
                  ) : null}
                </Box>
              </>
            )}
            <Box px={4}>
              {showDetailed && (
                <>
                  <CurrencyInput
                    value={formattedAmounts[Field.LIQUIDITY]}
                    onUserInput={onLiquidityInput}
                    onMax={() => {
                      onUserInput(Field.LIQUIDITY_PERCENT, "100");
                    }}
                    showMaxButton={!atMaxAmount}
                    disableCurrencySelect
                    currency={pair?.liquidityToken}
                    pair={pair}
                    id="liquidity-amount"
                  />
                  <Box mb={-2} mt={2}>
                    <i
                      style={{
                        color: theme.palette.secondary.contrastText,
                        textAlign: "center",
                        fontSize: "25px",
                      }}
                      className="fad fa-arrow-alt-square-down"
                    ></i>
                  </Box>
                  <CurrencyInput
                    hideBalance
                    value={formattedAmounts[Field.CURRENCY_A]}
                    onUserInput={onCurrencyAInput}
                    onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, "100")}
                    showMaxButton={!atMaxAmount}
                    currency={currencyA}
                    label="Output"
                    onCurrencySelect={handleSelectCurrencyA}
                    id="remove-liquidity-tokena"
                  />
                  <Box mb={-2} mt={2}>
                    <i
                      style={{
                        color: theme.palette.secondary.contrastText,
                        textAlign: "center",
                        fontSize: "25px",
                      }}
                      className="fad fa-plus-octagon"
                    ></i>
                  </Box>
                  <CurrencyInput
                    hideBalance
                    value={formattedAmounts[Field.CURRENCY_B]}
                    onUserInput={onCurrencyBInput}
                    onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, "100")}
                    showMaxButton={!atMaxAmount}
                    currency={currencyB}
                    label="Output"
                    onCurrencySelect={handleSelectCurrencyB}
                    id="remove-liquidity-tokenb"
                  />
                </>
              )}
            </Box>
            {pair && (
              <>
                <Box
                  px={4}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    fontFamily="Sandro"
                    fontSize="15px"
                    fontWeight="Light"
                    color={theme.palette.common.black}
                  >
                    Price
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    fontFamily="Sandro"
                    fontSize="15px"
                    fontWeight="Light"
                    color={theme.palette.common.black}
                  >
                    1 {currencyA?.symbol} ={" "}
                    {tokenA ? pair.priceOf(tokenA).toSignificant(6) : "-"}{" "}
                    {currencyB?.symbol}
                  </Box>
                </Box>
                <Box
                  px={4}
                  pb={4}
                  mt={1}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    fontFamily="Sandro"
                    fontSize="15px"
                    fontWeight="Light"
                    color={theme.palette.common.black}
                  >
                    1 {currencyB?.symbol} ={" "}
                    {tokenB ? pair.priceOf(tokenB).toSignificant(6) : "-"}{" "}
                    {currencyA?.symbol}
                  </Box>
                </Box>
              </>
            )}
            {!account ? (
              <Button
                width="100%"
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  marginBottom: "20px",
                  color: "#fff",
                }}
                variant="contained"
                onClick={connect}
              >
                Connect Wallet
              </Button>
            ) : (
              <Box
                px={4}
                pb={3}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  onClick={onAttemptToApprove}
                  variant={
                    approval === ApprovalState.APPROVED ||
                    signatureData !== null
                      ? "success"
                      : "primary"
                  }
                  disabled={
                    approval !== ApprovalState.NOT_APPROVED ||
                    signatureData !== null
                  }
                  mr="8px"
                  style={{ backgroundColor: theme.palette.secondary.main }}
                  color="#fff"
                  textAlign="center"
                >
                  {approval === ApprovalState.PENDING
                    ? `Approving ${(
                        <CircularProgress size={20} color="secondary" />
                      )}`
                    : approval === ApprovalState.APPROVED ||
                      signatureData !== null
                    ? "Approved"
                    : "Approve"}
                </Button>
                <Button
                  onClick={() => {
                    setShowConfirm(true);
                  }}
                  disabled={
                    !isValid ||
                    (signatureData === null &&
                      approval !== ApprovalState.APPROVED)
                  }
                  variant={
                    !isValid &&
                    !!parsedAmounts[Field.CURRENCY_A] &&
                    !!parsedAmounts[Field.CURRENCY_B]
                      ? "danger"
                      : "contained"
                  }
                  style={{ backgroundColor: theme.palette.secondary.main }}
                  color="#fff"
                >
                  {error || "Remove"}
                </Button>
              </Box>
            )}
          </Box>
          {pair ? (
            <LPTokensBox showUnwrapped={oneCurrencyIsWETH} pair={pair} />
          ) : null}
        </Box>
      </Container>
    );
  }
);
