import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Tab,
  Typography,
  useMediaQuery,
  Tabs,
  Switch,
  Hidden,
  InputBase,
} from "@material-ui/core";
import GridCards from "./GridCards";
import ListCards from "./ListCards";
import { styled, withStyles } from "@material-ui/core/styles";

import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import ViewListIcon from "@material-ui/icons/ViewList";
import SearchIcon from "@material-ui/icons/Search";
import bub from "../../../images/bub.png";
import smile from "../../../images/smile.png";
import { AppContext } from "../../utils/Utils";
import { useStakingContract } from "../BubbleProtcol/Connectivity/hooks";
import moment from "moment";
import { formatUnits } from "@ethersproject/units";

const AntTabs = withStyles({
  root: {
    height: "46px",
    width: "97px",
    border: "1px solid rgba(255, 255, 255, 0.05)",
    borderRadius: "16px",
  },
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);
const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: "none",
  borderRadius: "7px",
  minWidth: "46px",
  color: "rgba(255, 255, 255, 0.5)",
  "&.Mui-selected": {
    background: "rgba(255, 255, 255, 0.05)",
    color: "#fff",
    // paddingTop: "17px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const FarmTabs = withStyles({
  root: {
    // height: "48px",
    minWidth: "233px",
    padding: "4px",
    border: "1px solid rgba(255, 255, 255, 0.05)",
    borderRadius: "16px",
    background: "rgba(0, 0, 0, 0.11)",
  },
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);
const FarmTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: "none",
  minWidth: "fit-content",
  padding: "9px 24px 7px 24px",
  fontSize: "14px",
  fontFamily: "sandro",
  fontWeight: "Regular",
  borderRadius: "7px",
  color: "#fff",
  textTransform: "uppercase",
  "&.Mui-selected": {
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 2deg at 17.76% -92.91%, #FFFFFF 0deg, #A2B0C1 16.7deg, #F9FBFE 24.27deg, #939FAD 47.25deg, #C1D7DB 60.32deg, #C7E2DE 72deg, #D7F7EF 86.4deg, #DEFFF6 97.2deg, #EAFFF9 115.2deg, #F9FFFD 129.6deg, #FFFFFF 156.38deg, #B7C8DC 187.01deg, #E3ECFC 203.99deg, #FCFDFE 219.78deg, #F2F6FD 230.4deg, #EAF0FC 270deg, #FFFFFF 360deg)",
    color: "#223653",
    borderRadius: "12px",
  },
}));
function TabPanel1(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel1"
      hidden={value != index}
      id={`simple-tabpanel1-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[700],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "rgba(103, 126, 150, 0.17)",
        borderColor: "rgba(103, 126, 150, 0.17)",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: "1px solid rgba(103, 126, 150, 0.17)",
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    position: "relative",
    borderRadius: "5px",
    color: "#FFF",
    fontSize: "14px",
    fontFamily: "Macpaw",
    fontWeight: "400",
    backgroundColor: "transparent",
    padding: "10px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});
export default function Synthetic() {
  const matches = useMediaQuery("(max-width:960px)");
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [stakingPoolArray, setstakingPoolArray] = useState([]);
  const [stakingPoolArrayFinished, setstakingPoolArrayFinished] = useState([]);
  const [isUserStakedInLive, setisUserStakedInLive] = useState([]);
  const [isUserStakedInFinished, setisUserStakedInFinished] = useState([]);
  const [currentPoolIndex, setcurrentPoolIndex] = useState(0);
  const [checked, setChecked] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };
  const [value1, setValue1] = useState(0);
  const handleChange1 = (event1, newValue1) => {
    setValue1(newValue1);
  };
  let currentTime = moment().format("X");
  const { account, signer } = useContext(AppContext);
  const stakingContract = useStakingContract(signer);

  const initData = async () => {
    try {
      const ind = await stakingContract.currentPoolIndex();
      setcurrentPoolIndex(+ind);
      let poolArray = [];
      let poolArrayFinished = [];
      let userStakedArrayLive = [];
      let userStakedArrayFinished = [];
      for (let index = 1; index <= +ind; index++) {
        if (account) {
          const { _amount, _reward, _endTime, _isWithdrawn } =
            await stakingContract.getUserStakeInfo(account, +index);
          const {
            _rewardPercent,
            _startTime,
            _depositDuration,
            _withdrawDuration,
            _earlyWithdrwTax,
            _totalStaked,
            _totalWithdrawn,
          } = await stakingContract.getPoolInfo(index);
          let obj = {
            _rewardPercent: +formatUnits(_rewardPercent.toString(), 2),
            _startTime: +_startTime,
            _depositDuration: +_depositDuration,
            _withdrawDuration: +_withdrawDuration,
            _earlyWithdrwTax: +formatUnits(_earlyWithdrwTax.toString(), 2),
            _totalStaked: +formatUnits(_totalStaked.toString(), 9),
            _totalWithdrawn: +formatUnits(_totalWithdrawn.toString(), 9),
            _currentPool: +index,
            userStaked: +formatUnits(_amount.toString(), 9),
            userReward: +formatUnits(_reward.toString(), 9),
            userstakedEndTime: +_endTime,
            userIsWithdrawn: _isWithdrawn,
          };
          let timeEnd = +_startTime + +_withdrawDuration;
          if (+currentTime > +timeEnd) {
            poolArrayFinished.push(obj);
          }
          if (+currentTime < +timeEnd) {
            poolArray.push(obj);
          }
          if (+_amount > 0 && +currentTime > +timeEnd) {
            userStakedArrayFinished.push(obj);
          }
          if (+_amount > 0 && +currentTime < +timeEnd) {
            userStakedArrayLive.push(obj);
          }
        }
      }
      setstakingPoolArray([...poolArray]);
      setstakingPoolArrayFinished([...poolArrayFinished]);
      setisUserStakedInLive([...userStakedArrayLive]);
      setisUserStakedInFinished([...userStakedArrayFinished]);
    } catch (error) {
      console.log(error, "Error__DATA");
    }
  };
  useEffect(() => {
    initData();
  }, [stakingContract, account, checked]);
  return (
    <>
      <Box display="flex" justifyContent="center" mt={2}>
        <Box position="relative">
          <img
            src={bub}
            style={{
              position: "absolute",
              right: "-40px",
              bottom: "-10px",
            }}
            width={matches ? "0px" : "76px"}
            alt=""
          />
          <Typography
            style={{
              textTransform: "uppercase",
              fontFamily: "Sandro",
              fontSize: matches ? "18px" : "56px",
              fontWeight: matches ? "Bold" : "Regular",
            }}
          >
            Synthetic Yield
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="xl">
        <Box
          display="flex"
          flexDirection={matches ? "column" : "row"}
          justifyContent={matches ? "center" : "space-between"}
          mt={7}
        >
          <Box
            mb={matches ? 1 : 0}
            display="flex"
            justifyContent={matches ? "center" : ""}
            alignItems="center"
          >
            {/* <Hidden smDown>
              <AntTabs value={value} onChange={handleChange}>
                <AntTab icon={<AppsOutlinedIcon />} />
                <AntTab icon={<ViewListIcon />} />
              </AntTabs>
            </Hidden> */}
            <Box display="flex" ml={2} alignItems="center">
              <AntSwitch checked={checked} onChange={handleChangeChecked} />
              <Typography
                style={{
                  color: "#fff",
                  fontFamily: "MacPaw",
                  fontSize: "13px",
                  marginLeft: "10px",
                }}
              >
                Staked only
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent={matches ? "center" : ""}
            mt={matches ? 1 : 0}
          >
            <FarmTabs value={value1} onChange={handleChange1}>
              <FarmTab label="Live" />
              <FarmTab label="Finished" />
            </FarmTabs>
          </Box>
          <Hidden smDown>
            <Box
              zIndex={1}
              display="flex"
              alignItems="center"
              style={{
                background: "rgba(0, 0, 0, 0.11)",
                height: "48px",
                width: "233px",
                borderRadius: "16px",
                border: "1px solid rgba(255, 255, 255, 0.06)",
              }}
            >
              <SearchIcon
                style={{
                  color: "#677E96",
                  paddingLeft: "10px",
                  fontSize: "36px",
                }}
              />
              <TextInput
                placeholder="Search farms"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
          </Hidden>
        </Box>

        <TabPanel value={value} index={0}>
          <Box mt={5} pb={5}>
            <TabPanel1 value={value1} index={0}>
              {checked ? (
                <>
                  {+isUserStakedInLive.length > 0 ? (
                    <GridCards
                      poolDataArray={isUserStakedInLive}
                      initData={initData}
                    />
                  ) : (
                    <Box
                      py={10}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <img src={smile} width="80px" alt="" />
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: matches ? "16px" : "20px",
                          fontFamily: "Macpaw",
                          fontWeight: "400",
                          marginTop: "10px",
                        }}
                      >
                        You have no tokens / pools with already
                        <br /> staked amount
                      </Typography>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {+stakingPoolArray.length > 0 ? (
                    <GridCards
                      poolDataArray={stakingPoolArray}
                      initData={initData}
                    />
                  ) : (
                    <Box
                      py={10}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <img src={smile} width="80px" alt="" />
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: matches ? "16px" : "20px",
                          fontFamily: "Macpaw",
                          fontWeight: "400",
                          marginTop: "10px",
                        }}
                      >
                        There is no live pool yet
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </TabPanel1>
            <TabPanel1 value={value1} index={1}>
              {checked ? (
                <>
                  {+isUserStakedInFinished.length > 0 ? (
                    <GridCards
                      poolDataArray={isUserStakedInFinished}
                      initData={initData}
                    />
                  ) : (
                    <Box
                      py={10}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <img src={smile} width="80px" alt="" />
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: matches ? "16px" : "20px",
                          fontFamily: "Macpaw",
                          fontWeight: "400",
                          marginTop: "10px",
                        }}
                      >
                        You have no tokens / pools with already
                        <br /> staked amount
                      </Typography>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {+stakingPoolArrayFinished.length > 0 ? (
                    <GridCards
                      poolDataArray={stakingPoolArrayFinished}
                      initData={initData}
                    />
                  ) : (
                    <Box
                      py={10}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <img src={smile} width="80px" alt="" />
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: matches ? "16px" : "20px",
                          fontFamily: "Macpaw",
                          fontWeight: "400",
                          marginTop: "10px",
                        }}
                      >
                        There is no finished pool yet
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </TabPanel1>
          </Box>
        </TabPanel>

        {/* <TabPanel value={value} index={1}>
          <Box mt={5}>
            <TabPanel1 value={value1} index={0}>
              <Hidden smDown>
                <ListCards />
              </Hidden>
            </TabPanel1>
            <TabPanel1 value={value1} index={1}>
              <Box
                py={10}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <img src={smile} width="80px" alt="" />
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: matches ? "16px" : "20px",
                    fontFamily: "Macpaw",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  You have no tokens / pools with already
                  <br /> staked amount
                </Typography>
              </Box>
            </TabPanel1>
          </Box>
        </TabPanel> */}
      </Container>
    </>
  );
}
