import React, { useContext, useState } from "react";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import { AppContext } from "../../utils/Utils";
import { Container, Paper, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import logo from "../../../images/logo.png";
import eth from "../../../images/eth.png";
import Dbg from "../../../images/Dbg.png";
import sh1 from "../../../images/sh1.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
    paddingLeft: "30px",
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#212535 !important",
    justifyContent: "flex-start",
    width: "100%",
  },
});

export default function Navbar() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const { account, connect, disconnect } = useContext(AppContext);

  const matches = useMediaQuery("(max-width:960px)");
  const matches1 = useMediaQuery("(max-width:1279px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        // background: `url(${Dbg})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
      }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <img width="170px" src={logo} alt="" />
        <Box mr={5} onClick={toggleDrawer(anchor, false)}>
          <CloseIcon style={{ fontSize: "30px", color: "#fff" }} />
        </Box>
      </Box>
      <List>
        <Link to="/trade" style={{ textDecoration: "none" }}>
          <ListItem
            button
            style={{
              color: "#fff",
              fontSize: "20px",
              fontFamily: "Roboto",
              fontWeight: "Bold",
              textTransform: "uppercase",
              marginTop: "50px",
            }}
          >
            Trade
          </ListItem>
        </Link>
        <Link to="/pool" style={{ textDecoration: "none" }}>
          <ListItem
            button
            style={{
              color: "#fff",
              fontSize: "20px",
              fontFamily: "Roboto",
              fontWeight: "Bold",
              textTransform: "uppercase",
            }}
          >
            Liquidity
          </ListItem>
        </Link>
      </List>
      <Box
        mt={10}
        style={{
          width: "180px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* <img src={eth} width="27px" alt="" /> */}
        <Typography
          style={{
            color: "#fff",
            fontSize: "20px",
            fontFamily: "Roboto",
            fontWeight: "Bold",
            textTransform: "uppercase",
          }}
        >
          Ethereum
        </Typography>
        <ExpandMoreIcon />
      </Box>
      <Box mb={1} display="flex" zIndex={1} position="relative">
        {account ? (
          <Box
            mt={2}
            zIndex={1}
            sx={{
              background: "#a4ff66",
              cursor: "pointer",
              ml: 2,
            }}
            width="200px"
            height="40px"
            boxShadow=" 0px 7px 21px rgba(0, 0, 0, 0.07)"
            borderRadius="20px"
            fontSize="16px"
            fontWeight="600"
            color="#000"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontFamily="Roboto"
            textTransform="uppercase"
            onClick={() => disconnect()}
          >
            {account.slice(0, 4) + "..." + account.slice(-4)}
          </Box>
        ) : (
          <Box
            mt={2}
            zIndex={1}
            sx={{
              background: "#a4ff66",
              cursor: "pointer",
              ml: 2,
            }}
            width="200px"
            height="40px"
            boxShadow=" 0px 7px 21px rgba(0, 0, 0, 0.07)"
            borderRadius="20px"
            fontSize="16px"
            fontWeight="600"
            color="#000"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontFamily="Roboto"
            textTransform="uppercase"
            onClick={() => connect()}
          >
            CONNECT WALLET
          </Box>
        )}
      </Box>
    </div>
  );
  return (
    <>
      <Box
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        zIndex={2}
        height="92px"
        width="100%"
      >
        <Container maxWidth="xl">
          <Box width="100%">
            <Box
              display="flex"
              justifyContent={matches ? "center" : "space-between"}
              px={matches ? 0 : 2}
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                ml={{ xs: 0, md: 2 }}
                mt={1}
                flexGrow="1"
              >
                <img src={logo} width="230px" style={{ zIndex: 1 }} alt="" />
              </Box>
              <Box
                display="flex"
                justifyContent={matches1 ? "end" : "space-between"}
                alignItems="center"
                // flexBasis={matches1 ? "45px" : "78%"}
              >
                <Box
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                  zIndex={2}
                >
                  <Hidden smDown>
                    <Link to="/trade" style={{ textDecoration: "none" }}>
                      <Box
                        mr={1}
                        fontSize="20px"
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                          color: "#f5f5f5",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          background: "transparent",
                          width: "fit-content",
                          padding: "11px 20px",
                          height: "40px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover": {
                            background: "#a4ff66",
                            color: "#000",

                            width: "fit-content",
                            padding: "11px 20px",
                            height: "40px",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                        }}
                      >
                        Trade
                      </Box>
                    </Link>

                    <Link to="/pool" style={{ textDecoration: "none" }}>
                      <Box
                        mr={1}
                        fontSize="20px"
                        zIndex="1"
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                          color: "#f5f5f5",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          background: "transparent",
                          width: "fit-content",
                          padding: "11px 20px",
                          height: "40px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",

                          "&:hover": {
                            background: "#a4ff66",
                            color: "#000",
                            width: "fit-content",
                            padding: "11px 20px",
                            height: "40px",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                        }}
                      >
                        Liquidity
                      </Box>
                    </Link>
                  </Hidden>
                </Box>
              </Box>
              <Hidden smDown>
                <Box zIndex={1} display="flex">
                  <Box
                    style={{
                      width: "130px",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <img src={eth} width="20px" height="20px" alt="" />
                    <Typography
                      ml={1}
                      style={{
                        color: "#00",
                        fontSize: "20px",
                        fontFamily: "Roboto",
                        fontWeight: "Light",
                        textTransform: "uppercase",
                      }}
                    >
                      Ethereum
                    </Typography>
                    {/* <ExpandMoreIcon /> */}
                  </Box>
                  {account ? (
                    <Box
                      ml={1}
                      zIndex={1}
                      sx={{
                        background: "#a4ff66",
                        cursor: "pointer",
                        ml: 1,
                      }}
                      width="200px"
                      height="40px"
                      boxShadow=" 0px 7px 21px rgba(0, 0, 0, 0.07)"
                      borderRadius="20px"
                      fontSize="16px"
                      fontWeight="600"
                      color="#000"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      fontFamily="Roboto"
                      textTransform="uppercase"
                      onClick={() => disconnect()}
                    >
                      {account.slice(0, 4) + "..." + account.slice(-4)}
                    </Box>
                  ) : (
                    <Box
                      ml={1}
                      zIndex={1}
                      sx={{
                        background: "#a4ff66",
                        cursor: "pointer",
                        ml: 1,
                      }}
                      width="200px"
                      height="40px"
                      boxShadow=" 0px 7px 21px rgba(0, 0, 0, 0.07)"
                      borderRadius="20px"
                      fontSize="16px"
                      fontWeight="600"
                      color="#000"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      fontFamily="Roboto"
                      textTransform="uppercase"
                      onClick={() => connect()}
                    >
                      CONNECT WALLET
                    </Box>
                  )}
                </Box>
              </Hidden>

              <Hidden mdUp>
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      onClick={toggleDrawer(anchor, true)}
                      style={{ zIndex: 1 }}
                    >
                      <MenuIcon
                        style={{
                          fontSize: "38px",
                          cursor: "pointer",
                          color: "#fff",
                        }}
                      ></MenuIcon>
                    </Button>
                    <Paper style={{ background: "#1C0D38" }}>
                      <SwipeableDrawer
                        classes={{ paper: classes.paper }}
                        anchor={anchor}
                        open={state[anchor]}
                        // onClose={toggleDrawer(anchor, false)}
                        // onOpen={toggleDrawer(anchor, true)}
                      >
                        {list(anchor)}
                      </SwipeableDrawer>
                    </Paper>
                  </React.Fragment>
                ))}
              </Hidden>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
