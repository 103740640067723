import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

const whiteColor = "white";
const hotred = "#edba6f";
const trade = "#edba6f";
let theme = createTheme({
  palette: {
    common: {
      black: "#FFF",
      tooltip: "rgba(255, 255, 255, 0.56)",
    },
    primary: {
      main: "#ffffff",
      light: "#E9FBFA",
      contrastText: "#000",
    },
    secondary: {
      main: "rgb(75, 192, 192)",
      light: "#fff",
      contrastText: "#ffffff",
    },
    success: {
      main: "#26A17B",
      light: "#fff",
    },
  },
  modalColors: {
    modalbg: "#1E293B",
    textColor: "#FFFFFF",
    font: "interBold",
    cros: "#E61F43",
    buttonCol: "linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
    contextColor: "#94A3B8",
    box: {
      maxWidth: "auto",
      height: "100px",
      background: "#334155",
      borderRadius: "10px",
    },
  },
  trade: {
    box: {
      backgroundColor: trade,
      btnColor: hotred,
      textColor: whiteColor,
      font: "segoeui",
      headingColor: "#E6D3D9",
      subheadingColor: "#9EA3AB",
      shadowColor: "#BBC3D666",
      arrowColor: "#707070",
      lineColor: "#70707040",
      lineboxColor: hotred,
    },
    tradeStyle: {
      color: "wihte",
      fontFamily: "interBold",
      fontSize: "2.5rem",
    },
  },
});
theme.overrides = {
  MuiCssBaseline: {
    "@global": {
      body: {
        color: whiteColor,
      },
      ".img-fluid": {
        maxWidth: "100%",
        height: "auto",
      },
    },
  },
};

theme = responsiveFontSizes(theme);
export default theme;
