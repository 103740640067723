import React, { useMemo } from "react";
import { TradeType } from "moonchan-sdk";
import { AlertTriangle } from "react-feather";
import { Field } from "../../../state/swap/actions";
import { isAddress, shortenAddress } from "../../../utils";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../../utils/prices";
import CurrencyLogo from "../CurrencyLogo/CurrencyLogo";
import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core";
import CustomizedButton from "../CustomButton/CustomButton";

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
  onDismiss,
}) {
  const matches = useMediaQuery("(max-width:760px)");

  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [trade, allowedSlippage]
  );
  const { priceImpactWithoutFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade]
  );
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  const theme = useTheme();

  return (
    <div
      style={{
        textAlign: "center",
        paddingBottom: "15x",
        width: "100%",
        background: "#212535",
      }}
    >
      <Box
        mb={2}
        paddingLeft="20px"
        paddingRight="20px"
        paddingTop="25px"
        display="flex"
        style={{
          background: "#A4FF66",
        }}
        justifyContent="space-between"
        pb="20px"
      >
        <Box
          color={"#000"}
          fontSize="24px"
          textAlign="left"
          fontWeight="Bold"
          fontFamily="Roboto"
        >
          Confirm Swap
        </Box>
        <Box>
          <i
            onClick={onDismiss}
            style={{
              color: theme.palette.secondary.contrastText,
              textAlign: "right",
              fontSize: "25px",
              cursor: "pointer",
            }}
            className="fal fa-times"
          ></i>
        </Box>
      </Box>
      <Box
        mt={1}
        mx={2.6}
        px={2}
        py={1.5}
        // bgcolor="#ffffff"
        borderRadius="16px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          color={
            showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT
              ? theme.palette.common.black
              : theme.palette.common.black
          }
          display="flex"
          alignItems="center"
          fontSize={matches ? "17px" : "20px"}
          textAlign="center"
          fontWeight="Bold"
          fontFamily="Roboto"
        >
          <CurrencyLogo
            currency={trade.inputAmount.currency}
            size="22px"
            style={{ marginRight: "10px" }}
          />{" "}
          {trade.inputAmount.toSignificant(6)}
        </Box>

        <Box
          color={theme.palette.common.black}
          fontSize={matches ? "14px" : "17px"}
          textAlign="center"
          fontWeight="Bold"
          fontFamily="Roboto"
        >
          {trade.inputAmount.currency.symbol}
        </Box>
      </Box>

      <Box
        mt={1}
        mx={2.6}
        px={2}
        py={1.5}
        // bgcolor="#ffffff"
        borderRadius="16px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          fontSize={matches ? "17px" : "20px"}
          textAlign="center"
          fontWeight="Bold"
          fontFamily="Roboto"
          color={
            priceImpactSeverity > 2
              ? "#ED4B9E"
              : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
              ? theme.palette.common.black
              : theme.palette.common.black
          }
        >
          <CurrencyLogo
            currency={trade.outputAmount.currency}
            size="20px"
            style={{ marginRight: "10px" }}
          />{" "}
          {trade.outputAmount.toSignificant(6)}
        </Box>
        <Box
          color={theme.palette.common.black}
          fontSize={matches ? "14px" : "17px"}
          textAlign="center"
          fontWeight="Bold"
          fontFamily="Roboto"
        >
          {trade.outputAmount.currency.symbol}
        </Box>
      </Box>
      {showAcceptChanges ? (
        <Box justify="flex-start" gap="0px">
          <Box>
            <Box>
              <AlertTriangle
                size={20}
                style={{ marginRight: "8px", minWidth: 24 }}
              />
              <Box fontWeight="Bold" fontFamily="Roboto" color="secondary">
                {" "}
                Price Updated
              </Box>
            </Box>
            <CustomizedButton onClick={onAcceptChanges}>
              Accept
            </CustomizedButton>
          </Box>
        </Box>
      ) : null}
      <Box
        color={theme.palette.common.black}
        fontSize={matches ? "12px" : "15px"}
        textAlign="center"
        fontWeight="Light"
        fontFamily="Roboto"
        style={{ padding: "16px 0 0" }}
      >
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <>
            <Box
              fontSize={matches ? "14px" : "17px"}
              fontFamily="Roboto"
              fontWeight="Bold"
              color={theme.palette.common.black}
              textAlign="left"
              ml={3}
            >
              Output is estimated.
            </Box>
            <Box
              fontSize={matches ? "15px" : "17px"}
              fontFamily="Roboto"
              fontWeight="Light"
              color={theme.palette.common.black}
              mx={2.6}
              p={2}
              // bgcolor="#ffffff"
              borderRadius="16px"
              textAlign="left"
            >
              You will receive at least
              <Box
                display="inline"
                color={theme.palette.secondary.main}
                fontWeight="Bold"
                fontSize={matches ? "15px" : "17px"}
                fontFamily="Roboto"
                ml={0.5}
              >
                {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)}{" "}
                {trade.outputAmount.currency.symbol}
              </Box>
              {" or the transaction will revert."}
            </Box>
          </>
        ) : (
          <>
            <Box
              fontSize={matches ? "15px" : "17px"}
              fontFamily="Roboto"
              fontWeight="Bold"
              color={theme.palette.common.black}
              textAlign="left"
              ml={3}
            >
              Input is estimated.
            </Box>
            <Box
              fontSize={matches ? "15px" : "17px"}
              fontFamily="Roboto"
              fontWeight="Light"
              color={theme.palette.common.black}
              mx={2.6}
              p={2}
              // bgcolor="#ffffff"
              borderRadius="16px"
              textAlign="left"
            >
              {`You will sell at most`}{" "}
              <span>
                {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)}{" "}
                {trade.inputAmount.currency.symbol}
              </span>
              {" or the transaction will revert."}
            </Box>
          </>
        )}
      </Box>
      {recipient !== null ? (
        <Box
          color={theme.palette.common.black}
          fontSize={matches ? "17px" : "20px"}
          fontFamily="Roboto"
          textAlign="center"
          fontWeight="Light"
          style={{ padding: "16px 0 0" }}
        >
          <Box>
            Output will be sent to{" "}
            <Box
              title={recipient}
              color={theme.palette.secondary.main}
              fontSize={matches ? "15px" : "18px"}
              fontWeight="Bold"
              fontFamily="MacPaw"
            >
              {isAddress(recipient) ? shortenAddress(recipient) : recipient}
            </Box>
          </Box>
        </Box>
      ) : null}
    </div>
  );
}
