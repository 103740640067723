import { Box, Container, Grid, useMediaQuery } from "@material-ui/core";
import React, { useCallback, useContext, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useCurrency } from "../../../hooks/Tokens";
import { styles } from "../styles/style";
import { ONE_BIPS } from "../../../constants";
import {
  useDerivedMintInfo,
  useMintActionHandlers,
  useMintState,
} from "../../../state/mint/hooks";
import AddIcon from "@material-ui/icons/Add";
import { BigNumber } from "@ethersproject/bignumber";
import { currencyEquals, ETHER, WETH } from "moonchan-sdk";
import { useTheme } from "@material-ui/core";
import {
  ApprovalState,
  useApproveCallback,
} from "../../../hooks/useApproveCallback";
import {
  useIsExpertMode,
  useUserDeadline,
  useUserSlippageTolerance,
} from "../../../state/user/hooks";
import { PairState } from "../../../data/Reserves";
import { Field } from "../../../state/mint/actions";
import { maxAmountSpend } from "../../../utils/maxAmountSpend";
import { ROUTER_ADDRESS } from "../../../constants";
import { useTransactionAdder } from "../../../state/transactions/hooks";
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getRouterContract,
} from "../../../utils";
import { currencyId } from "../../../utils/currencyId";
import { useSwapActionHandlers } from "../../../state/swap/hooks";
import { wrappedCurrency } from "../../../utils/wrappedCurrency";
import CurrencyInput from "../../small components/currency input/CurrencyInput";
import { TopNav } from "../../small components/top nav/TopNav";
import { LiquidityTopNav } from "../../small components/box header/ModalHeader";
import ConfirmAddModalBottom from "./ConfirmAddModalBottom";
import DoubleCurrencyLogo from "../../small components/DoubleCurrencyLogo/DoubleCurrencyLogo";
import TransectionModal from "../../models/TransectionSubmittedModal";
import CustomizedButton from "../../small components/CustomButton/CustomButton";
import LPTokensBox from "../../small components/LPtokens box/LPTokensBox";
import { AppContext } from "../../../utils/Utils";
import { toast } from "react-toastify";

export const AddLiquidity = withStyles(styles)(
  ({
    classes,
    match: {
      params: { currencyIdA, currencyIdB },
    },
    history,
  }) => {
    // const [active, setActive] = useState(true);

    const [rotaion, setRotation] = useState(false);
    const matches = useMediaQuery("(max-width:600px)");
    const theme = useTheme();
    const { account, chainId, signer } = useContext(AppContext);
    const currencyA = useCurrency(currencyIdA);
    const currencyB = useCurrency(currencyIdB);
    // const TranslateString = useI18n();
    const oneCurrencyIsWBNB = Boolean(
      chainId &&
        ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
          (currencyB && currencyEquals(currencyB, WETH[chainId])))
    );
    const expertMode = useIsExpertMode();
    const { onSwitchTokens } = useSwapActionHandlers();
    // mint state
    const { independentField, typedValue, otherTypedValue } = useMintState();
    const {
      dependentField,
      currencies,
      pair,
      pairState,
      currencyBalances,
      parsedAmounts,
      price,
      noLiquidity,
      liquidityMinted,
      poolTokenPercentage,
      error,
    } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);

    const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

    const isValid = !error;

    // modal and loading
    const [showConfirm, setShowConfirm] = useState(false);
    const [attemptingTxn, setAttemptingTxn] = useState(false); // clicked confirm

    // txn values
    const [deadline] = useUserDeadline(); // custom from users settings
    const [allowedSlippage] = useUserSlippageTolerance(); // custom from users
    const [txHash, setTxHash] = useState("");
    const [openConnectModal, setopenConnectModal] = useState(false);

    // get formatted amounts
    const formattedAmounts = {
      [independentField]: typedValue,
      [dependentField]: noLiquidity
        ? otherTypedValue
        : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
    };

    // get the max amounts user can add
    const maxAmounts = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
      (accumulator, field) => {
        return {
          ...accumulator,
          [field]: maxAmountSpend(currencyBalances[field]),
        };
      },
      {}
    );

    const atMaxAmounts = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
      (accumulator, field) => {
        return {
          ...accumulator,
          [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? "0"),
        };
      },
      {}
    );

    // check whether the user has approved the router on the tokens
    const [approvalA, approveACallback] = useApproveCallback(
      parsedAmounts[Field.CURRENCY_A],
      ROUTER_ADDRESS
    );
    const [approvalB, approveBCallback] = useApproveCallback(
      parsedAmounts[Field.CURRENCY_B],
      ROUTER_ADDRESS
    );

    const addTransaction = useTransactionAdder();

    const onAdd = async () => {
      try {
        if (!chainId || !signer || !account) return;
        const router = getRouterContract(chainId, signer, account);

        const {
          [Field.CURRENCY_A]: parsedAmountA,
          [Field.CURRENCY_B]: parsedAmountB,
        } = parsedAmounts;
        if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
          return;
        }

        const amountsMin = {
          [Field.CURRENCY_A]: calculateSlippageAmount(
            parsedAmountA,
            noLiquidity ? 0 : allowedSlippage
          )[0],
          [Field.CURRENCY_B]: calculateSlippageAmount(
            parsedAmountB,
            noLiquidity ? 0 : allowedSlippage
          )[0],
        };

        const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;

        let estimate;
        let method;
        let args;
        let value;
        if (currencyA === ETHER || currencyB === ETHER) {
          const tokenBIsBNB = currencyB === ETHER;
          estimate = router.estimateGas.addLiquidityETH;
          method = router.addLiquidityETH;
          args = [
            wrappedCurrency(tokenBIsBNB ? currencyA : currencyB, chainId)
              ?.address ?? "", // token
            (tokenBIsBNB ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
            amountsMin[
              tokenBIsBNB ? Field.CURRENCY_A : Field.CURRENCY_B
            ].toString(), // token min
            amountsMin[
              tokenBIsBNB ? Field.CURRENCY_B : Field.CURRENCY_A
            ].toString(), // eth min
            account,
            deadlineFromNow,
          ];
          value = BigNumber.from(
            (tokenBIsBNB ? parsedAmountB : parsedAmountA).raw.toString()
          );
        } else {
          estimate = router.estimateGas.addLiquidity;
          method = router.addLiquidity;
          args = [
            wrappedCurrency(currencyA, chainId)?.address ?? "",
            wrappedCurrency(currencyB, chainId)?.address ?? "",
            parsedAmountA.raw.toString(),
            parsedAmountB.raw.toString(),
            amountsMin[Field.CURRENCY_A].toString(),
            amountsMin[Field.CURRENCY_B].toString(),
            account,
            deadlineFromNow,
          ];
          value = null;
        }

        setAttemptingTxn(true);
        // const aa = await estimate(...args, value ? { value } : {})
        await estimate(...args, value ? { value } : {})
          .then((estimatedGasLimit) =>
            method(...args, {
              ...(value ? { value } : {}),
              gasLimit: calculateGasMargin(estimatedGasLimit),
            }).then((response) => {
              setAttemptingTxn(false);

              addTransaction(response, {
                summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(
                  3
                )} ${currencies[Field.CURRENCY_A]?.symbol} and ${parsedAmounts[
                  Field.CURRENCY_B
                ]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
              });

              setTxHash(response.hash);
            })
          )
          .catch((e) => {
            setAttemptingTxn(false);
            // we only care if the error is something _other_ than the user rejected the tx
            if (e?.code !== 4001) {
              console.error(e);
            }
          });
      } catch (error) {
        console.log(error);
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        } else {
          toast.error(error?.reason);
        }
      }
    };

    const handleCurrencyASelect = useCallback(
      (currA) => {
        const newCurrencyIdA = currencyId(currA);
        if (newCurrencyIdA === currencyIdB) {
          history.push(`/add/${currencyIdB}/${currencyIdA}`);
        } else {
          history.push(`/add/${newCurrencyIdA}/${currencyIdB}`);
        }
      },
      [currencyIdB, history, currencyIdA]
    );
    const handleCurrencyBSelect = useCallback(
      (currB) => {
        const newCurrencyIdB = currencyId(currB);
        if (currencyIdA === newCurrencyIdB) {
          if (currencyIdB) {
            history.push(`/add/${currencyIdB}/${newCurrencyIdB}`);
          } else {
            history.push(`/add/${newCurrencyIdB}`);
          }
        } else {
          history.push(`/add/${currencyIdA || "WBNB"}/${newCurrencyIdB}`);
        }
      },
      [currencyIdA, history, currencyIdB]
    );
    const handleDismissConfirmation = useCallback(() => {
      setShowConfirm(false);
      // if there was a tx hash, we want to clear the input
      if (txHash) {
        onFieldAInput("");
      }
      setTxHash("");
    }, [onFieldAInput, txHash]);
    const rotationHandler = () => {
      if (rotaion) {
        setRotation(false);
      } else {
        setRotation(true);
      }
    };

    const modalHeader = noLiquidity ? (
      <>
        <Box
          paddingLeft="20px"
          paddingRight="20px"
          paddingTop="25px"
          display="flex"
          bgcolor={theme.palette.secondary.main}
          justifyContent="space-between"
          pb="20px"
        >
          <Box
            color={theme.palette.secondary.contrastText}
            fontSize="15px"
            textAlign="left"
            fontWeight="Bold"
            fontFamily="Sandro"
          >
            You are creating a pool
          </Box>
          <Box>
            <i
              onClick={handleDismissConfirmation}
              style={{
                color: theme.palette.secondary.contrastText,
                textAlign: "right",
                fontSize: "25px",
                cursor: "pointer",
              }}
              className="fal fa-times"
            ></i>
          </Box>
        </Box>
        <Box display="flex" p={3}>
          <Box
            fontFamily="Sandro"
            fontSize="25px"
            fontWeight="Bold"
            color={theme.palette.common.black}
          >
            {`${currencies[Field.CURRENCY_A]?.symbol}/${
              currencies[Field.CURRENCY_B]?.symbol
            }`}
          </Box>
          <Box display="flex" alignItems="center" mt={4}>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={20}
            />
          </Box>
        </Box>
      </>
    ) : (
      <>
        <Box
          paddingLeft="20px"
          paddingRight="20px"
          paddingTop="25px"
          display="flex"
          style={{
            background:
              "linear-gradient(106.27deg, #3A60AA 16.3%, #91669C 87.94%)",
          }}
          justifyContent="space-between"
          pb="20px"
        >
          <Box
            color={theme.palette.secondary.contrastText}
            fontSize="20px"
            textAlign="left"
            fontWeight="Bold"
            fontFamily="Sandro"
          >
            You will receive
          </Box>
          <Box>
            <i
              onClick={handleDismissConfirmation}
              style={{
                color: theme.palette.secondary.contrastText,
                textAlign: "right",
                fontSize: "25px",
                cursor: "pointer",
              }}
              className="fal fa-times"
            ></i>
          </Box>
        </Box>
        <Box
          display="flex"
          p={3}
          pb={0}
          fontFamily="Sandro"
          fontSize={matches ? "18px" : "25px"}
          fontWeight="Bold"
          color={theme.palette.common.black}
        >
          {liquidityMinted?.toSignificant(6)}
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={20}
          />
        </Box>
        <Box
          fontFamily="Sandro"
          fontSize={matches ? "18px" : "22px"}
          fontWeight="Bold"
          my={2}
          color={theme.palette.common.black}
        >
          {`${currencies[Field.CURRENCY_A]?.symbol}/${
            currencies[Field.CURRENCY_B]?.symbol
          } Pool Tokens`}
        </Box>
        <Box
          mb={3}
          p={1}
          fontFamily="Sandro"
          fontSize={matches ? "12px" : "15px"}
          fontWeight="Light"
          color={theme.palette.common.black}
        >
          {`Output is estimated. If the price changes by more than ${
            allowedSlippage / 100
          }% your transaction will revert.`}
        </Box>
      </>
    );
    const modalBottom = (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    );
    const pendingText = `Supplying ${parsedAmounts[
      Field.CURRENCY_A
    ]?.toSignificant(6)} ${
      currencies[Field.CURRENCY_A]?.symbol
    } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${
      currencies[Field.CURRENCY_B]?.symbol
    }`;

    return (
      <>
        <Container maxWidth="sm">
          <Box width="100%" display="flex" justifyContent="center" pb={5}>
            <Box
              width="100%"
              borderRadius="16px"
              pt={2}
              pb={1}
              px={matches ? 2 : 0}
              bgcolor="rgba(0,0,0,0.8)"
            >
              <LiquidityTopNav adding />
              <Box className={classes.ExchangeBox}>
                <TransectionModal
                  isOpen={showConfirm}
                  onDismiss={handleDismissConfirmation}
                  attemptingTxn={attemptingTxn}
                  hash={txHash}
                  modalBottom={modalBottom}
                  modalHeader={modalHeader}
                  pendingText={pendingText}
                />

                {/* {noLiquidity ? (
                  <Box
                    borderRadius="10px"
                    p={3}
                    border="1px solid"
                    borderColor={theme.palette.secondary.main}
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="Light"
                    fontFamily="Sandro"
                    color={theme.palette.common.black}
                  >
                    You are the first liquidity provider.
                    <br />
                    <br />
                    The ratio of tokens you add will set the price of this pool.
                    <br />
                    <br />
                    Once you are happy with the rate click supply to review.
                  </Box>
                ) : null} */}
                <Box width="100%">
                  <CurrencyInput
                    value={formattedAmounts[Field.CURRENCY_A]}
                    onUserInput={onFieldAInput}
                    onMax={() => {
                      onFieldAInput(
                        maxAmounts[Field.CURRENCY_A]?.toExact() ?? ""
                      );
                    }}
                    onCurrencySelect={handleCurrencyASelect}
                    showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                    currency={currencies[Field.CURRENCY_A]}
                    id="add-liquidity-input-tokena"
                    showCommonBases={false}
                  />
                </Box>
                <Box my={1}>
                  <Box className={classes.Middline}>
                    <Box
                      width="40px"
                      height="40px"
                      position="absolute"
                      top="-1rem"
                      left="1rem"
                      display="flex"
                    >
                      <Box
                        onClick={() => {
                          onSwitchTokens();
                        }}
                        className={classes.circleBox}
                      >
                        <AddIcon style={{ color: "#ffffff" }} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box width="100%">
                  <CurrencyInput
                    value={formattedAmounts[Field.CURRENCY_B]}
                    onUserInput={onFieldBInput}
                    onCurrencySelect={handleCurrencyBSelect}
                    onMax={() => {
                      onFieldBInput(
                        maxAmounts[Field.CURRENCY_B]?.toExact() ?? ""
                      );
                    }}
                    showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                    currency={currencies[Field.CURRENCY_B]}
                    id="add-liquidity-input-tokenb"
                    showCommonBases={false}
                  />
                </Box>
                <Box mt={3} textAlign="center">
                  {!account ? (
                    <CustomizedButton
                      color="rgb(55, 50, 77)"
                      variant="contained"
                      fun={() => setopenConnectModal(true)}
                      fullWidth
                    >
                      Unlock Wallet
                    </CustomizedButton>
                  ) : (
                    <Box>
                      {(approvalA === ApprovalState.NOT_APPROVED ||
                        approvalA === ApprovalState.PENDING ||
                        approvalB === ApprovalState.NOT_APPROVED ||
                        approvalB === ApprovalState.PENDING) &&
                        isValid && (
                          <Box mb={1}>
                            {approvalA !== ApprovalState.APPROVED && (
                              <CustomizedButton
                                fun={approveACallback}
                                disabled={approvalA === ApprovalState.PENDING}
                              >
                                {approvalA === ApprovalState.PENDING ? (
                                  <Box>
                                    Approving{" "}
                                    {currencies[Field.CURRENCY_A]?.symbol}
                                  </Box>
                                ) : (
                                  `Approve ${
                                    currencies[Field.CURRENCY_A]?.symbol
                                  }`
                                )}
                              </CustomizedButton>
                            )}
                            {approvalB !== ApprovalState.APPROVED && (
                              <CustomizedButton
                                fun={approveBCallback}
                                disabled={approvalB === ApprovalState.PENDING}
                              >
                                {approvalB === ApprovalState.PENDING ? (
                                  <Box>
                                    Approving{" "}
                                    {currencies[Field.CURRENCY_B]?.symbol}
                                  </Box>
                                ) : (
                                  `Approve ${
                                    currencies[Field.CURRENCY_B]?.symbol
                                  }`
                                )}
                              </CustomizedButton>
                            )}
                          </Box>
                        )}
                      <CustomizedButton
                        fun={() => {
                          if (expertMode) {
                            onAdd();
                          } else {
                            setShowConfirm(true);
                          }
                        }}
                        disabled={
                          !isValid ||
                          approvalA !== ApprovalState.APPROVED ||
                          approvalB !== ApprovalState.APPROVED
                        }
                      >
                        {error ?? "Supply"}
                      </CustomizedButton>
                    </Box>
                  )}
                </Box>
              </Box>
              {currencies[Field.CURRENCY_A] &&
                currencies[Field.CURRENCY_B] &&
                pairState !== PairState.INVALID && (
                  <Box mt={4} px={{ xs: 2, sm: 4 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={3}>
                        <Box
                          fontFamily="MacPaw"
                          fontSize="14px"
                          color="#ffffff"
                          textAlign="left"
                        >
                          {noLiquidity
                            ? "Initial prices and pool share"
                            : "Prices and pool share"}
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <Box display="flex" flexDirection="column">
                          <Box
                            fontSize="12px"
                            textAlign="center"
                            fontWeight="lighter"
                            fontFamily="MacPaw"
                            color="#ffffff"
                          >
                            {currencies[Field.CURRENCY_B]?.symbol} per{" "}
                            {currencies[Field.CURRENCY_A]?.symbol}
                          </Box>
                          <Box
                            mt={1}
                            fontSize="15px"
                            textAlign="center"
                            fontWeight="Bold"
                            fontFamily="MacPaw"
                            color="#ffffff"
                          >
                            {price?.toSignificant(6) ?? "-"}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <Box display="flex" flexDirection="column">
                          <Box
                            fontSize="12px"
                            textAlign="center"
                            fontFamily="MacPaw"
                            fontWeight="lighter"
                            color="#ffffff"
                          >
                            {currencies[Field.CURRENCY_A]?.symbol} per{" "}
                            {currencies[Field.CURRENCY_B]?.symbol}
                          </Box>
                          <Box
                            fontSize="15px"
                            textAlign="center"
                            fontWeight="Bold"
                            fontFamily="MacPaw"
                            color="#ffffff"
                            mt={1}
                          >
                            {price?.invert()?.toSignificant(6) ?? "-"}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <Box display="flex" flexDirection="column">
                          <Box
                            fontSize="12px"
                            textAlign="center"
                            fontFamily="MacPaw"
                            fontWeight="lighter"
                            color="#ffffff"
                          >
                            Share of Pool
                          </Box>
                          <Box
                            fontSize="15px"
                            textAlign="center"
                            fontWeight="Bold"
                            fontFamily="MacPaw"
                            color="#ffffff"
                            mt={1}
                          >
                            {noLiquidity && price
                              ? "100"
                              : (poolTokenPercentage?.lessThan(ONE_BIPS)
                                  ? "<0.01"
                                  : poolTokenPercentage?.toFixed(2)) ?? "0"}
                            %
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              {pair && !noLiquidity && pairState !== PairState.INVALID ? (
                <LPTokensBox showUnwrapped={oneCurrencyIsWBNB} pair={pair} />
              ) : null}
            </Box>
          </Box>
        </Container>
      </>
    );
  }
);
